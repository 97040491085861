import React from "react";
import { Input, InputRadio } from "../../../components";
import { Controller, useFormContext } from "react-hook-form";
import { FATHER, GUARDIAN, MOTHER } from "./Parent";

const ParentForm = ({
  onCancel,
  onValidate,
  canSelectMother = true,
  canSelectFather = true,
}) => {
  const {
    control,
    trigger,
    formState: { errors },
    getValues,
    setValue,
  } = useFormContext();

  const handleValidate = async () => {
    const fields = [
      "parentType",
      "parentFirstName",
      "parentLastName",
      "parentPhoneNumber",
      "ParentEmail",
      "ParentProfession",
    ];
    const result = await trigger(fields);
    if (result) {
      onValidate &&
        onValidate({
          parentType: getValues("parentType"),
          parentFirstName: getValues("parentFirstName"),
          parentLastName: getValues("parentLastName"),
          parentPhoneNumber: getValues("parentPhoneNumber"),
          parentEmail: getValues("parentEmail"),
          parentProfession: getValues("parentProfession"),
        });
      setValue("parentType", "");
      setValue("parentFirstName", "");
      setValue("parentLastName", "");
      setValue("parentPhoneNumber", "");
      setValue("parentEmail", "");
      setValue("parentProfession", "");
    }
  };
  console.log(errors.phoneNumber);
  const parentTypes = [
    canSelectFather ? { label: "Père", value: FATHER } : false,
    canSelectMother ? { label: "Mère", value: MOTHER } : false,
    { label: "Tuteur", value: GUARDIAN },
  ].filter((p) => !!p);
  return (
    <div>
      <Controller
        name="parentType"
        control={control}
        rules={{ required: true }}
        render={({ field: { onChange, value } }) => (
          <InputRadio
            hasError={!!errors.parentType}
            value={value}
            onChange={onChange}
            options={parentTypes}
          />
        )}
      />
      <Controller
        name="parentFirstName"
        control={control}
        rules={{ required: true }}
        render={({ field: { onChange, value } }) => (
          <Input
            value={value}
            label="Nom"
            type="text"
            onChange={(val) => onChange(val.toUpperCase())}
            hasError={!!errors.parentFirstName}
          />
        )}
      />
      <Controller
        name="parentLastName"
        control={control}
        rules={{ required: true }}
        render={({ field: { onChange, value } }) => (
          <Input
            label="Prénom(s)"
            type="text"
            onChange={onChange}
            value={value}
            hasError={!!errors.parentLastName}
          />
        )}
      />

      <Controller
        name="parentPhoneNumber"
        control={control}
        rules={{
          required: true,
          pattern: {
            value:
              /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im,
            message: "Numéro de téléphone invalide, ex. +261 32 09 765 01",
          },
        }}
        render={({ field: { onChange, value } }) => (
          <Input
            label="Numéro téléphone"
            type="text"
            onChange={onChange}
            value={value}
            hasError={!!errors.parentPhoneNumber}
            message={
              !!errors.parentPhoneNumber && errors.parentPhoneNumber.message
            }
          />
        )}
      />
      <Controller
        name="parentEmail"
        control={control}
        rules={{
          required: true,
          pattern: {
            value:
              /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
            message: "Email invalid | rakotomalala@exemple.mg",
          },
        }}
        render={({ field: { onChange, value } }) => (
          <Input
            label="Mail"
            type="text"
            onChange={onChange}
            value={value}
            hasError={!!errors.parentEmail}
            message={!!errors.parentEmail && errors.parentEmail.message}
          />
        )}
      />
      <Controller
        name="parentProfession"
        control={control}
        render={({ field: { onChange, value } }) => (
          <Input
            label="Proffession"
            type="text"
            onChange={onChange}
            value={value}
            hasError={!!errors.parentProfession}
          />
        )}
      />
      <div className="d-flex justify-content-end">
        <button
          className="btn btn-primary"
          type="button"
          onClick={handleValidate}
        >
          Ajouter
        </button>
        <button className="btn btn-default" type="button" onClick={onCancel}>
          Annuler
        </button>
      </div>
    </div>
  );
};

export default ParentForm;
