import { unsecured as axios } from "../../../axios";

class Countries {
  static async findAll() {
    return await axios.get("/list/countries").then((res) => {
      return res.data.map((country) => ({
        name: country.name_fr,
        id: country.id,
        code: country.alpha2,
      }));
    });
  }
}

export { Countries };
