import React, { Component } from "react";
import * as Icon from "react-feather";

class Password extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showPassword: "password",
      showConfirmation: "password",
    };

    this.togglePassword = this.togglePassword.bind(this);
    this.toggleConfirmation = this.toggleConfirmation.bind(this);
    this.changeConfirmation = this.changeConfirmation.bind(this);
  }

  changeConfirmation(event) {
    this.setState({ confirmation: event.target.value });
  }

  togglePassword() {
    this.setState({
      showPassword: this.state.showPassword == "password" ? "text" : "password",
    });
  }

  toggleConfirmation() {
    this.setState({
      showConfirmation:
        this.state.showConfirmation == "password" ? "text" : "password",
    });
  }

  render() {
    return (
      <div>
        {/* ----- Password -----*/}
        <div class="form-group mb-4">
          <div class="d-flex align-items-center justify-content-between">
            <div>
              <label class="form-control-label">Mot de passe*</label>
            </div>
            <div class="mb-2">
              <a
                class="small text-muted text-underline--dashed border-primary"
                role="button"
                onClick={this.togglePassword}
              >
                Voir le mot de passe
              </a>
            </div>
          </div>
          <div class="input-group">
            <input
              type={this.state.showPassword}
              class={"form-control".concat(
                this.props.errorPassword ? " is-invalid" : ""
              )}
              name="password"
              onChange={this.props.onChange}
              value={this.props.valuePassword}
              placeholder="********"
            />
          </div>
          {this.props.errorPassword ? (
            <div class="text-danger">
              <Icon.XOctagon size="1em" />
              &nbsp;{this.props.errorPassword}
            </div>
          ) : null}
        </div>

        {/* ----- Password Confirmation -----*/}
        <div class="form-group mb-4">
          <div class="d-flex align-items-center justify-content-between">
            <div>
              <label class="form-control-label">
                Confirmation du mot de passe*
              </label>
            </div>
            <div class="mb-2">
              <a
                class="small text-muted text-underline--dashed border-primary"
                role="button"
                onClick={this.toggleConfirmation}
              >
                Voir le mot de passe
              </a>
            </div>
          </div>
          <div class="input-group">
            <input
              type={this.state.showConfirmation}
              class={"form-control".concat(
                this.props.errorConfirm ? " is-invalid" : ""
              )}
              name="confirm_password"
              onChange={this.props.onChange}
              value={this.props.valueConfirm}
              placeholder="********"
            />
          </div>
          {this.props.errorConfirm ? (
            <div class="text-danger">
              <Icon.XOctagon size="1em" />
              &nbsp;{this.props.errorConfirm}
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

export default Password;
