import React from "react"

const StepSaveInfo = () => {


    return (
        <small>
        Si vous souhaitez poursuivre votre inscription plus tard, merci d’accéder à votre dossier à travers le mail que vous avez reçu de
        <a href="mailto:information@iscam.mg"> information@iscam.mg </a> <strong>en validant cette étape</strong>.
        Merci de vérifier dans le <em>Spam</em> si vous ne voyez pas le mail dans votre boite de réception.
    </small>
    )
}

export default StepSaveInfo