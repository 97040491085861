import React, { useRef } from "react";
import * as Icon from "react-feather";

const InputFile = ({ label, onChange, value, accept, hasError, message }) => {
  const inputFile = useRef();
  const [file, setFile] = React.useState();

  const handleOnChange = async (e) => {
    const url = await toBase64url(e.target.files[0]);
    setFile(e.target.files[0]);

    onChange &&
      onChange({
        base64Url: url,
        mimeType: e.target.files[0].type,
        fileName: e.target.files[0].name,
      });
  };

  return (
    <div className="mb-4 d-flex align-items-center" style={{ height: 80 }}>
      <div className="flex-grow-1 d-flex flex-column align-items-center">
        {file && (
          <>
            <Icon.FileText size={80} />
            <span>{file.name}</span>
          </>
        )}
        {hasError && <span className="text-danger">{message}</span>}
      </div>
      <div>
        <button
          className="btn btn-warning btn-sm"
          onClick={() => inputFile.current.click()}
          type="button"
          style={{ height: 40 }}
        >
          {label}
        </button>
      </div>
      <input
        type="file"
        className="d-none"
        ref={inputFile}
        accept={accept}
        onChange={handleOnChange}
      />
    </div>
  );
};

const toBase64url = async (file) => {
  try {
    const base64Url = await new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
    return base64Url;
  } catch (error) {
    return undefined;
  }
};

export { toBase64url };

export default InputFile;
