import React, { Component } from "react";
import * as Icon from "react-feather";
import _ from "lodash";
import API from "../Api.js";

class SchoolForm extends Component {
  constructor(props) {
    super(props);

    this.model = props.model;
    this.collection = props.collection;
    this.index = props.index;

    if (this.model) {
      this.state = this.model;
    } else {
      this.state = {
        name: "",
        place: "",
        grade: "",
        year: "",
        errors: "",
        loading: false,
      };
    }

    this.renderError = this.renderError.bind(this);
    this.hasError = this.hasError.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onsubmit = this.onsubmit.bind(this);
  }

  renderError(e) {
    if (this.hasError(e)) {
      return (
        <div class="text-danger">
          <Icon.XOctagon size="1em" />
          &nbsp;{this.state.errors[e]}
        </div>
      );
    }
  }

  hasError(e) {
    var keys = Object.keys(this.state.errors);
    return keys.includes(e);
  }

  onChange(event) {
    var data = this.state;
    data[event.target.name] = event.target.value;
    this.setState({ ...data });
  }

  onsubmit() {
    this.setState({ loading: true });
    if (this.model) {
      var data = {
        name: this.state.name,
        place: this.state.place,
        grade: this.state.grade,
        year: this.state.year,
      };
      API.post(`/applicant/school/${this.model.id}`, data)
        .then(
          function (response) {
            this.setState({ loading: false });
            this.collection.saveSchool(this.state, this.index);
            this.collection.cancelAddSchool();
          }.bind(this)
        )
        .catch(
          function (error) {
            this.setState({ errors: error.response.data["0"], loading: false });
          }.bind(this)
        );
    } else {
      this.setState({ loading: true });
      var data = {
        name: this.state.name,
        place: this.state.place,
        grade: this.state.grade,
        year: this.state.year,
      };
      API.post("/applicant/school", data)
        .then(
          function (response) {
            this.setState({ loading: false });
            var schoolData = {
              id: response.data["0"],
              name: this.state.name,
              place: this.state.place,
              grade: this.state.grade,
              year: this.state.year,
              errors: "",
            };
            this.collection.saveSchool(schoolData, this.index);
            this.collection.cancelAddSchool();
          }.bind(this)
        )
        .catch(
          function (error) {
            this.setState({ errors: error.response.data["0"], loading: false });
          }.bind(this)
        );
    }
  }

  render() {
    return (
      <div class="poi-edit mb-3">
        <h5 class="h5 mt-5">
          Edition d'une école{" "}
          <button
            class="btn btn-xs btn-warning float-right"
            onClick={this.collection.cancelAddSchool}
          >
            <Icon.X size="1em" />
            &nbsp;Annuler
          </button>
        </h5>

        <div class="form-group">
          <label class="form-control-label">Nom de l'école*</label>
          <div class="input-group">
            <input
              type="text"
              class="form-control"
              id="input-name"
              name="name"
              onChange={this.onChange}
              value={this.state.name}
              placeholder="ex. LFT"
            />
          </div>
          {this.renderError("data.name")}
        </div>
        <div class="form-group">
          <label class="form-control-label">Lieu*</label>
          <div class="input-group">
            <input
              type="text"
              class="form-control"
              id="input-name"
              name="place"
              onChange={this.onChange}
              value={this.state.place}
              placeholder="ex. Antananarivo"
            />
          </div>
          {this.renderError("data.place")}
        </div>
        <div class="form-group">
          <label class="form-control-label">Classe*</label>
          <div class="input-group">
            <input
              type="text"
              class="form-control"
              id="input-name"
              name="grade"
              onChange={this.onChange}
              value={this.state.grade}
              placeholder="ex. Terminale"
            />
          </div>
          {this.renderError("data.grade")}
        </div>
        <div class="form-group">
          <label class="form-control-label">Année*</label>
          <div class="input-group">
            <input
              type="text"
              class="form-control"
              id="input-name"
              name="year"
              onChange={this.onChange}
              value={this.state.year}
              placeholder="ex. 2012"
            />
          </div>
          {this.renderError("data.year")}
        </div>
        {this.state.loading ? (
          <button type="button" class="btn btn-block btn-primary" disabled>
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
          </button>
        ) : (
          <button class="btn btn-primary btn-block" onClick={this.onsubmit}>
            Enregistrer
          </button>
        )}
      </div>
    );
  }
}

export default SchoolForm;
