import { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { getStepData } from "../queries/stepData"

export const useInscriptionFrom = () => {
    const [editing, setEditing] = useState(false)
    const [currentEmail, setCurrentEmail] = useState()
    const queryParameters = new URLSearchParams(window.location.search)
    const methods = useForm()
    
    useEffect( () => {
        const tokenId = queryParameters.get('tokenId')
        if( tokenId )
        {
            getStepData(tokenId)
            .then(data => {
                if(Object.keys(data).length > 0) {
                    methods.reset({
                        ...data,
                        gender: parseInt(data.gender),
                        needRent: parseInt(data.needRent)
                    })
                    setEditing(true)
                    setCurrentEmail(data.email)
                }
            })
        }

    }, [] )

    return {methods, isEditing: editing, currentEmail}
}