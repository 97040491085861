import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import {
  Error404,
  Header,
  HeaderInterview,
  Footer,
} from "./components/Components";
import { Landing, Interviews } from "./commercial/Commercial";
import { Register } from "./platform/Platform";
import interviewDatas from "./commercial/InterviewDatas.json";
import Interview from "./components/Interview";
import interviewImages from "./commercial/InterviewImages";
import ReviewStep from "./platform/register-steps/ReviewStep";
import Inscription from "./pages/inscription/Inscription";
import "./assets/scss/quick-website.scss";

function App() {
  function findGetParameter(parameterName) {
    var result = null,
      tmp = [];

    window.location.search
      .substr(1)
      .split("&")
      .forEach(function (item) {
        tmp = item.split("=");
        if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
      });

    return result;
  }

  if (findGetParameter("apiKey")) {
    window.apiKey = findGetParameter("apiKey");
  }

  return (
    <Router>
      <Switch>
        <Route exact path="/inscription" component={Inscription} />
        <Route
          exact
          path="/register"
          component={(routerProps) => (
            <Register
              token={findGetParameter("token")}
              history={routerProps.history}
            />
          )}
        ></Route>
        <Route
          path="/register/review"
          component={(routerProps) => (
            <ReviewStep
              step={
                routerProps.history.location.pathname.split(
                  "/register/review/"
                )[1]
              }
              history={routerProps.history}
            />
          )}
        />
        <Route
          path="/register/:step(1|2|3|4|5)"
          component={(routerProps) => (
            <Register
              step={routerProps.match.params.step}
              history={routerProps.history}
            />
          )}
        />
        {interviewDatas.map(({ key, data }) => (
          <Route path={`/interviews/${key}`}>
            <HeaderInterview />
            <Interview
              id={key}
              key={key}
              data={data}
              imagePortrait={interviewImages[key]}
            />
            <Footer />
          </Route>
        ))}
        <Route path="/interviews">
          <Header />
          <Interviews />
          <Footer />
        </Route>
        <Route
          exact
          path="/"
          component={({ history }) => (
            <>
              <Header history={history} />
              <Landing history={history} />
              <Footer />
            </>
          )}
        />
        <Route path="*" component={() => <Error404 />} />
      </Switch>
    </Router>
  );
}

export default App;
