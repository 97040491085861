import React, { Component } from "react";
import _ from "lodash";
import * as Icon from "react-feather";
import Api from "../../Api";
import Loading from "../../components/Loading";
import { data } from "jquery";

class Step6_4 extends Component {
  state = {
    loadingFetch: false,
    banks: [],
    bank: "",
    error: null,
  };

  constructor(props) {
    super(props);

    if (this.props.token) {
      Api.defaults.headers.common["X-AUTH-TOKEN"] = this.props.token;
      localStorage.setItem(
        "X-AUTH-TOKEN_new",
        JSON.stringify(this.props.token)
      );
    }

    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    if (_.has(localStorage, "X-AUTH-TOKEN_new")) {
      Api.defaults.headers.common["X-AUTH-TOKEN"] = JSON.parse(
        localStorage.getItem("X-AUTH-TOKEN_new")
      );
      this.getBankList();
    }
  }

  getBankList() {
    this.setState({ loadingFetch: true });
    Api.get("/list/banks").then((response) => {
      const datas = response.data[0];
      const banks = [];
      for (let id in datas) {
        if (datas.hasOwnProperty(id)) banks.push({ ...datas[id], id });
      }
      this.setState({ banks, loadingFetch: false });
    });
  }

  onSubmit() {
    this.props.history.push("/register/review/5");
  }

  render() {
    return (
      <div className="register-right-pane container-fluid d-flex flex-column">
        <div className="row align-items-center justify-content-center justify-content-lg-end min-vh-100">
          <div className="scrollable-content col-sm-7 col-lg-6 col-xl-6 py-6 py-lg-0 position-absolute h-100 top-0 right-0">
            <div className="row justify-content-center">
              <div className="col-11 col-lg-10 col-xl-10">
                {this.state.loadingFetch && <Loading />}
                <div className="mt-5 mb-4">
                  <h6 class="h3 mb-1">VOTRE FACTURATION</h6>
                  <p>
                    Pour rendre effective votre demande d’inscription, nous vous
                    prions de procéder au paiement de la première facture
                    correspondante à votre cursus. <br />
                    Le paiement doit obligatoirement se faire par versement en
                    espèces du montant dû sur l’un des comptes bancaires de
                    l’ISCAM ci-dessous, le bordereau de versement à la banque
                    faisant foi.
                  </p>
                </div>
                <div className="form-group mb-4">
                  <label class="form-control-label">
                    Choix du compte de versement de la première tranche des
                    frais de scolarité
                  </label>
                  <div class="input-group">
                    <select
                      class="form-control"
                      name="type"
                      value={this.state.bank}
                      onChange={(event) =>
                        this.setState({ bank: event.target.value })
                      }
                    >
                      <option value="" disabled selected>
                        Veuillez choisir
                      </option>
                      {this.state.banks.map(({ id, label, account }, index) => (
                        <option
                          value={id}
                          key={id || index}
                        >{`${label}, compte n°${account}`}</option>
                      ))}
                    </select>
                  </div>
                  {this.state.error && (
                    <div class="text-danger">
                      <Icon.XOctagon size="1em" />
                      &nbsp;Ce champ ne doit pas être vide
                    </div>
                  )}
                </div>
                <button
                  className={"btn btn-block btn-warning"}
                  disabled={this.state.loadingFetch}
                  onClick={() =>
                    this.state.bank
                      ? Api.post(
                          `/applicant/invoice`,
                          {
                            bank: this.state.bank,
                          },
                          {
                            responseType: "blob",
                          }
                        ).then((response) => {
                          const url = window.URL.createObjectURL(
                            new Blob([response.data])
                          );
                          const link = document.createElement("a");
                          link.href = url;
                          link.setAttribute("download", "installment.pdf");
                          document.body.appendChild(link);
                          link.click();
                        }) && this.setState({ error: null })
                      : this.setState({ error: true })
                  }
                >
                  <Icon.Download size="1em" />
                  &nbsp;Télécharger votre facture
                </button>
                <span className="clearfix"></span>
                <button
                  type="button"
                  class="btn btn-block btn-primary mt-4"
                  onClick={this.onSubmit}
                >
                  Suivant
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Step6_4;
