import React, { Component } from "react";
import * as Icon from "react-feather";

import RegisterStep1Background from "../assets/img/backgrounds/register-step-1.jpg";
import "./Register.scss";

import { OldRegisterStep } from "./old-register-steps/OldRegisterSteps";
import { CheckDevice } from "../components/Components";
import { withRouter } from "react-router-dom";

import API from "../Api";

class OldRegister extends Component {
  constructor(props) {
    super(props);

    this.goBack = this.goBack.bind(this);
  }

  componentDidMount() {
    if (this.props.token) {
      API.defaults.headers.common["X-AUTH-TOKEN"] = this.props.token;
      this.applicantProfile();
    }
  }

  goBack() {
    if (this.props.step == "1") this.props.history.push("/");
    else this.props.history.push(`/old/register/${this.props.step - 1}`);
  }

  applicantProfile() {
    API.get("/applicant/profile").then((response) => {
      this.setState({ profile: response.data["0"] });
      localStorage.setItem(
        "X-AUTH-TOKEN_old",
        JSON.stringify(this.props.token)
      );
      this.props.history.push(`/old/register/${response.data["0"].step}`);
    });
  }

  render() {
    const isMobile = CheckDevice();

    if (isMobile)
      return (
        <div
          style={{
            width: "100vw",
            height: "100vh",
            padding: 10,
            textAlign: "center",
            fontSize: "18px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <svg></svg>
          <span>
            Afin de pouvoir procéder à l'inscription, il vous sera nécessaire
            d'envoyer des documents. Merci de vous connecter à partir d'un
            ordinateur pour poursuivre.
          </span>
        </div>
      );
    return (
      <div>
        <a
          class="btn btn-white btn-icon-only rounded-circle position-absolute zindex-101 left-4 top-4 d-none d-lg-inline-flex"
          data-toggle="tooltip"
          data-placement="right"
          title="Retourner à l'étape précédente"
          onClick={this.goBack}
        >
          <span class="btn-inner--icon">
            <Icon.ArrowLeft size="1em" />
          </span>
        </a>
        <section>
          <OldRegisterStep token={this.props.token} step={this.props.step} />
        </section>
      </div>
    );
  }
}

export default withRouter(OldRegister);
