class Documents {
  static findAll() {
    return new Promise((resolve) => {
      const documents = require("./../../../assets/mocks/documents.json");
      resolve(documents);
    });
  }
}

export { Documents };
