import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import _ from "lodash";
import * as Icon from "react-feather";
import $ from "jquery";

import API from "../../Api";

class Step1 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      redirect: null,
      loading: false,
      showPassword: "password",
      errors: [],
      data: {
        student_id: "",
        password: "",
      },
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.togglePassword = this.togglePassword.bind(this);
    this.renderError = this.renderError.bind(this);
    this.hasError = this.hasError.bind(this);

    if (this.props.token) {
      API.defaults.headers.common["X-AUTH-TOKEN"] = this.props.token;
    }
  }

  componentDidMount() {
    $('[data-toggle="tooltipLogin"]').tooltip();
    $('[data-toggle="tooltipPassword"]').tooltip();
  }

  onChange(event) {
    var data = this.state.data;

    data[event.target.name] = event.target.value;

    this.setState({ data: data });
  }

  onSubmit() {
    this.setState({ loading: true });
    API.post("/applicant/login", this.state.data)
      .then(
        function (response) {
          const dataResponse = response.data[0];
          this.setState({ loading: false });
          var data = this.state.data;

          this.setState({ data: data });
          this.props.registerStep.setState({
            step1: this.state.data,
          });
          localStorage.setItem("old_register_id", dataResponse.old_register_id);
          if (dataResponse.token) {
            localStorage.setItem(
              "X-AUTH-TOKEN_old",
              JSON.stringify(dataResponse.token)
            );
          } else {
            localStorage.removeItem("X-AUTH-TOKEN_old");
          }
          this.setState({ redirect: "/old/register/2" });
        }.bind(this)
      )
      .catch(
        function (error) {
          this.setState({ errors: error.response.data[0], loading: false });
        }.bind(this)
      );
  }

  togglePassword() {
    this.setState({
      showPassword: this.state.showPassword == "password" ? "text" : "password",
    });
  }

  renderError(e) {
    if (this.hasError(e)) {
      return (
        <div class="text-danger">
          <Icon.XOctagon size="1em" />
          &nbsp;{this.state.errors[e]}
        </div>
      );
    }
  }

  hasError(e) {
    var keys = Object.keys(this.state.errors);
    return keys.includes(e);
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }

    return (
      <div class="register-right-pane container-fluid d-flex flex-column">
        <div class="row align-items-center justify-content-center justify-content-lg-end min-vh-100">
          <div class="scrollable-content col-sm-7 col-lg-6 col-xl-6 py-6 py-lg-0 position-absolute h-100 top-0 right-0">
            <div class="row justify-content-center">
              <div class="col-11 col-lg-10 col-xl-10">
                <div>
                  <div class="mb-5 mt-5">
                    <h6 class="h3 mb-1">Ré-inscription Concours ISCAM</h6>
                    <p class="text-muted mb-0">Informations élémentaires</p>
                  </div>
                  <span class="clearfix"></span>
                  <form>
                    {this.hasError("student_id") ? (
                      <div class="alert alert-danger" role="alert">
                        {this.state.errors["student_id"]}
                      </div>
                    ) : null}
                    <div class="form-group">
                      <label class="form-control-label">
                        Identifiant étudiant*
                      </label>
                      <div
                        class="input-group"
                        data-toggle="tooltipLogin"
                        data-placement="top"
                        title="L'identifiant étudiant est disponible auprès de l'ISCAM.
                        En cas d'oubli, merci d'envoyer un email à l'adresse exemple@exemple.com"
                      >
                        <input
                          type="text"
                          class={"form-control".concat(
                            this.hasError("children[student_id].data")
                              ? " is-invalid"
                              : ""
                          )}
                          name="student_id"
                          onChange={this.onChange}
                          value={this.state.student_id}
                          placeholder="ex. exemple@exemple.fr"
                        />
                      </div>
                      {this.renderError("children[student_id].data")}
                    </div>

                    <div class="form-group mb-4">
                      <div class="d-flex align-items-center justify-content-between">
                        <div>
                          <label class="form-control-label">
                            Mot de passe*
                          </label>
                        </div>
                        <div class="mb-2">
                          <a
                            class="small text-muted text-underline--dashed border-primary"
                            role="button"
                            onClick={this.togglePassword}
                          >
                            Voir le mot de passe
                          </a>
                        </div>
                      </div>
                      <div
                        class="input-group"
                        data-toggle="tooltipPassword"
                        data-placement="top"
                        title="Le mot de passe est disponible auprès de l'ISCAM.
                        En cas d'oubli, merci d'envoyer un email à l'adresse exemple@exemple.com"
                      >
                        <input
                          type={this.state.showPassword}
                          class={"form-control".concat(
                            this.hasError("children[password].data")
                              ? " is-invalid"
                              : ""
                          )}
                          name="password"
                          onChange={this.onChange}
                          value={this.state.password}
                          placeholder="********"
                        />
                      </div>
                      {this.renderError("children[password].data")}
                    </div>

                    <div class="mt-4 mb-4">
                      {this.state.loading ? (
                        <button
                          type="button"
                          class="btn btn-block btn-primary"
                          disabled
                        >
                          <span
                            class="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        </button>
                      ) : (
                        <button
                          type="button"
                          onClick={this.onSubmit}
                          class="btn btn-block btn-primary"
                        >
                          Valider
                        </button>
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Step1;
