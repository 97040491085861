import React, {useContext} from 'react';
import {Controller, useFormContext} from 'react-hook-form';
import {Input, InputRadio} from '../../../components';
import Select from '../../../components/Select';
import {EditingContext} from '../Inscription';
import StepSaveInfo from '../StepSaveInfo';
import {useCountryList} from '../hooks/useCountryList';
import {useHowDidUKnowUs} from '../hooks/useHowDidUKnowUs';
import {useInterestList} from '../hooks/useInterestList';
import {emailIsUniq} from '../queries/emailIsUniq';
import stepBackground from './../../../assets/img/backgrounds/register-step-1.jpg';
import StepContainer from './stepContainer';

const Identity = () => {
  const {
    control,
    formState: {errors},
  } = useFormContext();

  const {countryListSelect} = useCountryList();
  const {sourceList} = useHowDidUKnowUs();
  const {interestList} = useInterestList();

  const {isEditing} = useContext(EditingContext);

  return (
    <StepContainer
      title="Etape 1 sur 2"
      desciption="Veuillez remplir vos informations personnelles. Celles-ci sont la base de votre dossier académique."
      image={stepBackground}>
      <div className="d-flex flex-column flex-fill">
        <div className="px-5 pb-4 flex-grow-1">
          <div className="mb-5 mt-5">
            <h6 className="h3 mb-1">Inscription Concours ISCAM</h6>
            <p className="text-muted mb-0">Informations élémentaires</p>
          </div>

          <Controller
            name="gender"
            control={control}
            rules={{required: true}}
            render={({field: {onChange, value}}) => (
              <InputRadio
                hasError={!!errors.gender}
                value={value}
                name="gender"
                onChange={onChange}
                options={[
                  {label: 'Masculin', value: 1},
                  {label: 'Féminin', value: 2},
                ]}
              />
            )}
          />

          <Controller
            control={control}
            name="lastName"
            rules={{
              required: true,
            }}
            render={({field: {onChange, value}}) => (
              <Input
                type="text"
                onChange={val => onChange(val.toUpperCase())}
                value={value}
                required
                hasError={!!errors.lastName}
                label="Nom"
              />
            )}
          />

          <Controller
            control={control}
            name="firstName"
            rules={{
              required: true,
            }}
            render={({field: {onChange, value}}) => (
              <Input
                type="text"
                onChange={onChange}
                value={value}
                required
                hasError={!!errors.firstName}
                label="Prénom(s)"
              />
            )}
          />

          <Controller
            control={control}
            name="usageName"
            rules={{
              required: true,
            }}
            render={({field: {onChange, value}}) => (
              <Input
                type="text"
                onChange={onChange}
                value={value}
                required
                hasError={!!errors.usageName}
                label="Prémom(s) d'usage"
              />
            )}
          />
          <div className="row">
            <div className=" col-4">
              <Controller
                control={control}
                name="birthDate"
                rules={{
                  required: true,
                }}
                render={({field: {onChange, value}}) => (
                  <Input
                    type="date"
                    onChange={onChange}
                    value={value}
                    required
                    hasError={!!errors.birthDate}
                    label="Date de naissance"
                  />
                )}
              />
            </div>

            <div className="col-8">
              <Controller
                control={control}
                name="birthPlace"
                rules={{
                  required: true,
                }}
                render={({field: {onChange, value}}) => (
                  <Input
                    type="text"
                    onChange={onChange}
                    value={value}
                    required
                    hasError={!!errors.birthPlace}
                    label="Lieu de naissance"
                  />
                )}
              />
            </div>
          </div>

          <div className="row">
            <div className="form-group col-6">
              <label htmlFor="country">
                Pay d'origine <span>*</span>
              </label>
              <Controller
                control={control}
                name="country"
                rules={{required: true}}
                render={({field: {onChange, value}}) => (
                  <Select
                    options={countryListSelect()}
                    onChange={onChange}
                    value={value}
                    placeholder=""
                    hasError={!!errors.country}
                  />
                )}
              />
            </div>
            <div className="form-group col-6">
              <Controller
                control={control}
                name="nationality"
                rules={{required: true}}
                render={({field: {onChange, value}}) => (
                  <Input
                    type="text"
                    onChange={onChange}
                    value={value}
                    required
                    hasError={!!errors.nationality}
                    label="Nationalité"
                    placeholder="Malagasy"
                  />
                )}
              />
            </div>
          </div>

          <Controller
            control={control}
            name="address"
            rules={{
              required: true,
            }}
            render={({field: {onChange, value}}) => (
              <Input
                type="text"
                onChange={onChange}
                value={value}
                required
                hasError={!!errors.address}
                label="Adresse exacte à Antananarivo"
                placeholder="ex. IVN 68A Ankadifotsy, 101 Antananarivo"
              />
            )}
          />
          <Controller
            control={control}
            name="phoneNumber"
            rules={{
              required: true,
              pattern: {
                value:
                  /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im,
                message: 'Numéro de téléphone invalide, ex. +261 32 09 765 01',
              },
            }}
            render={({field: {onChange, value}}) => (
              <Input
                type="text"
                onChange={onChange}
                value={value}
                required
                hasError={!!errors.phoneNumber}
                message={!!errors.phoneNumber && errors.phoneNumber.message}
                label="Téléphone"
              />
            )}
          />
          <Controller
            control={control}
            name="email"
            rules={{
              required: true,
              pattern: {
                value:
                  /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
                message: 'Email invalid | rakotomalala@exemple.mg',
              },
              validate: {
                checkUrl: async value => {
                  const uniq = await emailIsUniq(value);
                  return (
                    uniq ||
                    isEditing ||
                    "Cette adresse email est déjà utilisée | Si vous assurez n'avoir pu finir l'inscription, merci de consulter votre boîte email pour continuer"
                  );
                },
              },
            }}
            render={({field: {onChange, value}}) => (
              <Input
                type="text"
                onChange={onChange}
                value={value}
                required
                hasError={!!errors.email}
                message={!!errors.email && errors.email.message}
                label="Mail"
              />
            )}
          />
          <Controller
            control={control}
            name="establishment"
            render={({field: {onChange, value}}) => (
              <Input
                type="text"
                onChange={onChange}
                value={value}
                hasError={!!errors.establishment}
                label="Etablissement fréquenté"
              />
            )}
          />

          <div className="form-group">
            <label htmlFor="howDidUKnowUs">
              Comment avez-vous connu l'ISCAM <span>*</span>
            </label>
            <Controller
              control={control}
              name="howDidUKnowUs"
              rules={{required: true}}
              render={({field: {onChange, value}}) => (
                <Select
                  options={sourceList()}
                  isMulti
                  onChange={onChange}
                  value={value}
                  placeholder=""
                  hasError={!!errors.howDidUKnowUs}
                />
              )}
            />
          </div>

          <div className="form-group">
            <label htmlFor="interest">Centre d'intérêt</label>
            <Controller
              control={control}
              name="interest"
              render={({field: {onChange, value}}) => (
                <Select
                  options={interestList()}
                  onChange={onChange}
                  value={value}
                  placeholder=""
                  hasError={!!errors.interest}
                />
              )}
            />
          </div>
          <Controller
            name="needRent"
            control={control}
            rules={{required: true}}
            render={({field: {onChange, value}}) => (
              <InputRadio
                hasError={!!errors.needRent}
                value={value}
                onChange={onChange}
                options={[
                  {label: 'Oui', value: 1},
                  {label: 'Non', value: 2},
                ]}
                label="Avez vous besoin d'un logement ?"
                name="needRent"
              />
            )}
          />
        </div>
        <div className="py-2">
          <StepSaveInfo />
        </div>
      </div>
    </StepContainer>
  );
};

export default Identity;
