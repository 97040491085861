import React, { Component } from 'react';

class Footer extends Component {
  render() {
    return (
      <footer class="position-relative" id="footer-main">
          <div class="footer pt-lg-7 footer-dark bg-dark">
              <div class="shape-container shape-line shape-position-top shape-orientation-inverse">
                  <svg width="2560px" height="100px" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" preserveAspectRatio="none" x="0px" y="0px" viewBox="0 0 2560 100" style={{enableBackground: 'new 0 0 2560 100'}} xmlSpace="preserve" class="">
                      <polygon points="2560 0 2560 100 0 100"></polygon>
                  </svg>
              </div>
              <div class="container pt-4">
                  <div class="row justify-content-center">
                      <div class="col-lg-12">
                          <div class="row align-items-center">
                              <div class="col-lg-7">
                                  <h3 class="text-secondary mb-2">EXCELLENCE - RESPECT - INTÉGRITÉ</h3>
                              </div>
                          </div>
                      </div>
                  </div>
                  <hr class="divider divider-fade divider-dark my-5" />
                  <div class="row align-items-center justify-content-md-between pb-4">
                      <div class="col-md-6">
                          <div class="copyright text-sm font-weight-bold text-center text-md-left">
                              &copy; 2020 <a href="http://www.iscam.mg" class="font-weight-bold" target="_blank">ISCAM</a> tous droits réservés.
                          </div>
                      </div>
                      <div class="col-md-6">
                          <ul class="nav justify-content-center justify-content-md-end mt-3 mt-md-0">
                          </ul>
                      </div>
                  </div>
              </div>
          </div>
      </footer>
    );
  }
}

export default Footer;
