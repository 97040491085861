import React, { Component } from "react";
import { Redirect } from "react-router";
import * as Icon from "react-feather";
import API from "../../Api";
import _ from "lodash";

import { Loading, InputFile, EndStep } from "../../components/Components";
import pdfIcon from "../../assets/img/icons/pdf-icon.png";
import $ from "jquery";
import Api from "../../Api";

class Step4 extends Component {
  constructor(props) {
    super(props);

    /*if (this.props.registerStep.state.step3 === null) {
      this.state = { redirect: "/register/3" };
      return;
    }*/

    if (this.props.token) {
      API.defaults.headers.common["X-AUTH-TOKEN"] = this.props.token;
      localStorage.setItem(
        "X-AUTH-TOKEN_new",
        JSON.stringify(this.props.token)
      );
    }

    this.state = {
      loading: false,
      loadingFetch: false,
      redirect: "",
      finished: false,
      errors: {},
      data: {
        documents: [
          {
            type: "",
            number: "",
            delivery_date: "",
            delivery_place: "",
            expiry_date: "",
            document: "",
            file_name: "",
            mime_type: "",
            loading: false,
          },
          {
            type: 140,
            document: "",
            file_name: "",
            mime_type: "",
            loading: false,
          },
          {
            type: 150,
            document: "",
            file_name: "",
            mime_type: "",
            loading: false,
          },
          {
            type: 170,
            document: "",
            file_name: "",
            mime_type: "",
            loading: false,
          },
          {
            type: 190,
            document: "",
            file_name: "",
            mime_type: "",
            loading: false,
          },
          {
            type: 130,
            document: "",
            file_name: "",
            mime_type: "",
            loading: false,
          },
          {
            type: 130,
            document: "",
            file_name: "",
            mime_type: "",
            loading: false,
          },
          {
            type: 130,
            document: "",
            file_name: "",
            mime_type: "",
            loading: false,
          },
          {
            type: 160,
            document: "",
            file_name: "",
            mime_type: "",
            loading: false,
          },
        ],
      },
    };

    this.componentRef = {
      type: null,
      "documents[0]": null,
      number: null,
      delivery_date: null,
      delivery_place: null,
      expiry_date: null,
      "documents[1]": null,
      "documents[2]": null,
      "documents[3]": null,
      "documents[4]": null,
      "documents[5]": null,
      "documents[6]": null,
      "documents[7]": null,
      "documents[8]": null,
    };

    this.findElementToScroll = this.findElementToScroll.bind(this);
    this.onChangeText = this.onChangeText.bind(this);
    this.onChangeFile = this.onChangeFile.bind(this);
    this.onChangeGrade = this.onChangeGrade.bind(this);
    this.hasError = this.hasError.bind(this);
    this.renderError = this.renderError.bind(this);
    this.renderEndStep = this.renderEndStep.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.getData = this.getData.bind(this);
  }

  componentDidMount() {
    if (_.has(localStorage, "X-AUTH-TOKEN_new")) {
      API.defaults.headers.common["X-AUTH-TOKEN"] = JSON.parse(
        localStorage.getItem("X-AUTH-TOKEN_new")
      );
      this.getData();
      this.applicantProfile();
    }
  }

  applicantProfile() {
    API.get("/applicant/profile").then(async (response) => {
      const examSession = response.data[0].exam_session_id;
      this.setState({ loadingFetch: true });

      if (examSession) {
        const result = await API.get(`/entrance-exam-session/${examSession}`);
        const {
          data: { deadline },
        } = result;
        if (
          !deadline ||
          this.compareDateToNow(this.getDateFromFormattedString(deadline)) <= 0
        ) {
          $("#examSessionDeadlinePassed").modal();
          this.setState({ examSessionDeadlinePassed: true });
        } else {
          this.setState({ examSessionDeadlinePassed: false });
        }
      }
      this.setState({ loadingFetch: false });
    });
  }

  getData() {
    this.setState({ loadingFetch: true });
    API.get("/register/step-4").then((response) => {
      this.setState({ loadingFetch: false });
      response = response.data["0"];
      if (Array.isArray(response) && response.length) {
        var docs = this.state.data.documents;

        for (let i = 0; i < docs.length; i++) {
          for (let j = 0; j < response.length; j++) {
            if (
              response[j].type === 100 ||
              response[j].type === 110 ||
              response[j].type === 120
            ) {
              docs[0] = {
                ...response[j],
                loading: true,
                document: "",
              };
              this.setState({});
              Api.get(
                `${process.env.REACT_APP_API_URL}/admin/document/${
                  response[0].id
                }?token=${JSON.parse(
                  localStorage.getItem("X-AUTH-TOKEN_new")
                )}`,
                {
                  responseType: "blob",
                }
              )
                .then((response) => {
                  const reader = new FileReader();
                  reader.onloadend = () => {
                    docs[0].document = btoa(reader.result);
                    docs[0].loading = false;
                    this.setState({});
                  };
                  reader.readAsBinaryString(response.data);
                })
                .catch((error) => {
                  docs[0].loading = false;
                  docs[0].error = String(error);
                  this.setState({});
                });
            } else if (docs[i].type === response[j].type) {
              docs[i] = {
                ...response[j],
                loading: true,
                document: "",
              };
              this.setState({});
              Api.get(
                `${process.env.REACT_APP_API_URL}/admin/document/${response[j].id}?token=${JSON.parse(localStorage.getItem('X-AUTH-TOKEN_new'))}`,
                {
                  responseType: "blob",
                }
              )
                .then((response) => {
                  const reader = new FileReader();
                  reader.onloadend = () => {
                    docs[i].document = btoa(reader.result);
                    docs[i].loading = false;
                    this.setState({});
                  };
                  reader.readAsBinaryString(response.data);
                })
                .catch((error) => {
                  docs[0].loading = false;
                  docs[0].e0rror = String(error);
                  this.setState({});
                });
            }
          }
        }

        const documents = [...docs];

        this.setState({ data: { documents } });
      }
    });
  }

  getDateFromFormattedString(value) {
    const parts = value.split(" ");
    const date = parts[0],
      time = parts[1];

    const dateParts = date.split("/"),
      timeParts = time.split(":");

    return {
      year: Number(dateParts[2]),
      month: Number(dateParts[1]),
      date: Number(dateParts[0]),
      hours: Number(timeParts[0]),
      minutes: Number(timeParts[1]),
      seconds: Number(timeParts[2]),
    };
  }

  compareDateToNow({ year, month, date, hours, minutes, seconds }) {
    const now = new Date(),
      d = new Date(year, month - 1, date, hours, minutes, seconds);

    return d.getTime() - now.getTime();
  }

  onChangeText(event) {
    const docs = this.state.data.documents;
    docs[0][event.target.name] = event.target.value;
    if (event.target.name === "type") {
      docs[0]["number"] = "";
      docs[0]["delivery_date"] = "";
      docs[0]["delivery_place"] = "";
      docs[0]["expiry_date"] = "";
      docs[0]["document"] = "";
      docs[0]["file_name"] = "";
      docs[0]["mime_type"] = "";
    }
    var data = this.state.data;
    data.documents = [...docs];
    this.setState({ data: data });
  }

  onChangeGrade(event) {
    const documents = this.state.data.documents;
    const id = Number(event.target.id);

    if (event.nativeEvent.error) {
      documents[4 + id].document = "";
      documents[4 + id].file_name = "";
      return this.setState({ data: { documents } });
    }

    event.persist();
    const reader = new FileReader();

    reader.onload = () => {
      const b64 = window.btoa(reader.result);
      const id = Number(event.target.id);
      documents[4 + id].document = b64;
      documents[4 + id].file_name = event.target.files[0].name;
      documents[4 + id].mime_type = event.target.files[0].type;

      this.setState({ data: { documents } });
    };
    reader.readAsBinaryString(event.target.files[0]);
  }

  onChangeFile(event) {
    var documents = this.state.data.documents;

    if (event.nativeEvent.error) {
      documents.forEach((doc) => {
        if (doc.type == event.target.name) {
          doc.document = "";
          doc.file_name = "";
          doc.mime_type = "";
        }
      });
      return this.setState({ data: { documents } });
    }

    event.persist();
    var reader = new FileReader();
    let b64 = "";

    reader.onload = () => {
      b64 = window.btoa(reader.result);
      documents.forEach((doc) => {
        if (doc.type == event.target.name) {
          doc.document = b64;
          doc.file_name = event.target.files[0].name;
          doc.mime_type = event.target.files[0].type;
        }
      });
      this.setState({ data: { documents } });
    };
    reader.readAsBinaryString(event.target.files[0]);
  }

  renderError(e) {
    if (this.hasError(e)) {
      return (
        <div class="text-danger">
          <Icon.XOctagon size="1em" />
          &nbsp;{this.state.errors[e]}
        </div>
      );
    }
  }

  hasError(e) {
    if (!_.isEmpty(this.state.errors)) {
      var keys = Object.keys(this.state.errors);
      return keys.includes(e);
    }
  }

  onSubmit() {
    this.setState({ loading: true });

    var documents = [...this.state.data.documents]
      .filter((e) => e.document.length)
      .map(({ id, loading, error, ...doc }) => ({ ...doc }));

    var data = {
      documents,
    };

    API.post("/register/step-4", data)
      .then(
        function (response) {
          this.setState({ loading: false, finished: true });
        }.bind(this)
      )
      .catch(
        function (error) {
          this.setState({ errors: error.response.data["0"], loading: false });
          var errors = this.getNameErrors(error.response.data[0]);
          this.findElementToScroll(errors);
        }.bind(this)
      );
  }

  findElementToScroll(errors) {
    for (var element in this.componentRef) {
      if (errors.includes(element)) {
        this.scrollToNode(this.componentRef[`${String(element)}`]);
        break;
      }
    }
  }

  getNameErrors(errors) {
    var keyNames = [];
    var keys = Object.keys(errors);
    keys.forEach((key) => {
      var name = key.split(".");
      if (name[name.length - 1] === "document") {
        keyNames.push(name[name.length - 2]);
      } else {
        keyNames.push(name[name.length - 1]);
      }
    });
    return keyNames;
  }

  scrollToNode(node) {
    node.scrollIntoView({ block: "start", behavior: "smooth" });
  }

  renderEndStep() {
    if (this.state.finished) {
      return <EndStep />;
    } else {
      return null;
    }
  }

  renderIdentity() {
    switch (Number(this.state.data.documents[0]["type"])) {
      case 100:
        return (
          <div class="pl-5">
            <div
              ref={(node) => (this.componentRef["documents[0]"] = node)}
              class="form-group"
            >
              <label class="form-control-label">CIN*</label>
              <div class="input-group">
                <InputFile
                  key={1}
                  name="100"
                  disabled={this.state.data.documents[0].loading}
                  file_name={this.state.data.documents[0].file_name}
                  onChange={this.onChangeFile}
                  accept="image/*, .pdf"
                />
              </div>
              {!this.state.data.documents[0].loading &&
              this.state.data.documents[0].document ? (
                <img
                  className="img-fluid rounded"
                  style={{ width: 75, height: 75, objectFit: "cover" }}
                  src={
                    this.state.data.documents[0].mime_type.split("/")[1] ===
                    "pdf"
                      ? pdfIcon
                      : `data:image/png;base64,${this.state.data.documents[0].document}`
                  }
                  alt=""
                />
              ) : this.state.data.documents[0].loading ? (
                <div
                  className="d-flex align-items-center justify-content-center"
                  style={{ width: 75, height: 75 }}
                >
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  />
                </div>
              ) : null}
              {this.renderError("data.documents[0].document")}
            </div>
            <div
              ref={(node) => (this.componentRef["number"] = node)}
              class="form-group"
            >
              <label class="form-control-label">N° CIN*</label>
              <div class="input-group">
                <input
                  type="text"
                  class="form-control form-control-prepend"
                  name="number"
                  value={this.state.data.documents[0].number}
                  onChange={this.onChangeText}
                  placeholder="ex. 117124205128"
                />
              </div>
              {this.renderError("data.documents[0].number")}
            </div>
            <div
              ref={(node) => (this.componentRef["delivery_date"] = node)}
              class="form-group"
            >
              <label class="form-control-label">Délivré le*</label>
              <div class="input-group">
                <input
                  type="text"
                  class="form-control form-control-prepend"
                  name="delivery_date"
                  value={this.state.data.documents[0].delivery_date}
                  onChange={this.onChangeText}
                  placeholder="ex. 06/12/2008"
                />
              </div>
              {this.renderError("data.documents[0].delivery_date")}
            </div>
            <div
              ref={(node) => (this.componentRef["delivery_place"] = node)}
              class="form-group"
            >
              <label class="form-control-label">Délivré à*</label>
              <div class="input-group">
                <input
                  type="text"
                  class="form-control form-control-prepend"
                  name="delivery_place"
                  value={this.state.data.documents[0].delivery_place}
                  onChange={this.onChangeText}
                  placeholder="ex. Tana IV"
                />
              </div>
              {this.renderError("data.documents[0].delivery_place")}
            </div>
          </div>
        );
      case 110:
        return (
          <div class="pl-5">
            <div
              ref={(node) => (this.componentRef["documents[0]"] = node)}
              class="form-group"
            >
              <label class="form-control-label">Acte de naissance*</label>
              <div class="input-group">
                <InputFile
                  key={2}
                  name="110"
                  disabled={this.state.data.documents[0].loading}
                  file_name={this.state.data.documents[0].file_name}
                  onChange={this.onChangeFile}
                  accept="image/*, .pdf"
                />
              </div>
              {!this.state.data.documents[0].loading &&
              this.state.data.documents[0].document ? (
                <img
                  className="img-fluid rounded"
                  style={{ width: 75, height: 75, objectFit: "cover" }}
                  src={
                    this.state.data.documents[0].mime_type.split("/")[1] ===
                    "pdf"
                      ? pdfIcon
                      : `data:image/png;base64,${this.state.data.documents[0].document}`
                  }
                  alt=""
                />
              ) : this.state.data.documents[0].loading ? (
                <div
                  className="d-flex align-items-center justify-content-center"
                  style={{ width: 75, height: 75 }}
                >
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  />
                </div>
              ) : null}
              {this.renderError("data.documents[0].document")}
            </div>
          </div>
        );
      case 120:
        return (
          <div class="pl-5">
            <div
              ref={(node) => (this.componentRef["documents[0]"] = node)}
              class="form-group"
            >
              <label class="form-control-label">Passeport*</label>
              <div class="input-group">
                <InputFile
                  key={3}
                  name="120"
                  disabled={this.state.data.documents[0].loading}
                  file_name={this.state.data.documents[0].file_name}
                  onChange={this.onChangeFile}
                  accept="image/*, .pdf"
                />
              </div>
              {!this.state.data.documents[0].loading &&
              this.state.data.documents[0].document ? (
                <img
                  className="img-fluid rounded"
                  style={{ width: 75, height: 75, objectFit: "cover" }}
                  src={
                    this.state.data.documents[0].mime_type.split("/")[1] ===
                    "pdf"
                      ? pdfIcon
                      : `data:image/png;base64,${this.state.data.documents[0].document}`
                  }
                  alt=""
                />
              ) : this.state.data.documents[0].loading ? (
                <div
                  className="d-flex align-items-center justify-content-center"
                  style={{ width: 75, height: 75 }}
                >
                  <img
                    src={`${process.env.REACT_APP_API_URL}/admin/document/${
                      this.state.data.documents[0].id
                    }?token=${JSON.parse(
                      localStorage.getItem("X-AUTH-TOKEN_new")
                    )}`}
                    style={{ display: "none" }}
                    alt=""
                    onLoad={() => {
                      const { documents } = this.state.data;
                      documents[0].loading = false;
                      this.setState({ data: { documents } });
                    }}
                  />
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  />
                </div>
              ) : null}
              {this.renderError("data.documents[0].document")}
            </div>
            <div
              ref={(node) => (this.componentRef["number"] = node)}
              class="form-group"
            >
              <label class="form-control-label">N° Passeport*</label>
              <div class="input-group">
                <input
                  type="text"
                  class="form-control form-control-prepend"
                  name="number"
                  value={this.state.data.documents[0].number}
                  onChange={this.onChangeText}
                  placeholder="ex. 117124205128"
                />
              </div>

              {this.renderError("data.documents[0].number")}
            </div>
            <div
              ref={(node) => (this.componentRef["delivery_date"] = node)}
              class="form-group"
            >
              <label class="form-control-label">Délivré le*</label>
              <div class="input-group">
                <input
                  type="text"
                  class="form-control form-control-prepend"
                  name="delivery_date"
                  value={this.state.data.documents[0].delivery_date}
                  onChange={this.onChangeText}
                  placeholder="ex. 06/12/2008"
                />
              </div>
              {this.renderError("data.documents[0].delivery_date")}
            </div>
            <div
              ref={(node) => (this.componentRef["delivery_place"] = node)}
              class="form-group"
            >
              <label class="form-control-label">Délivré à*</label>
              <div class="input-group">
                <input
                  type="text"
                  class="form-control form-control-prepend"
                  name="delivery_place"
                  value={this.state.data.documents[0].delivery_place}
                  onChange={this.onChangeText}
                  placeholder="ex. Tana IV"
                />
              </div>
              {this.renderError("data.documents[0].delivery_place")}
            </div>
            <div
              ref={(node) => (this.componentRef["expiry_date"] = node)}
              class="form-group"
            >
              <label class="form-control-label">Expiré le*</label>
              <div class="input-group">
                <input
                  type="text"
                  class="form-control form-control-prepend"
                  name="expiry_date"
                  value={this.state.data.documents[0].expiry_date}
                  onChange={this.onChangeText}
                  placeholder="ex. 15/02/2012"
                />
              </div>
              {this.renderError("data.documents[0].expiry_date")}
            </div>
          </div>
        );

      default:
        return null;
    }
  }
  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }

    return (
      <>
        <div
          class="modal fade"
          id="examSessionDeadlinePassed"
          tabindex="-1"
          aria-labelledby="examSessionDeadlinePassed"
          aria-hidden="true"
        >
          <div class="modal-dialog">
            <div class="modal-content border border-danger">
              <div class="modal-header bg-danger">
                <h5 class="modal-title" id="examSessionDeadlinePassed">
                  <span class="text-white">Erreur</span>
                </h5>

                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                Vous ne pouvez plus sélectionner cette session car le délai de
                réception des documents de candidature a été dépassé
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-secondary btn-sm"
                  data-dismiss="modal"
                >
                  Fermer
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="register-right-pane container-fluid d-flex flex-column">
          <div class="row align-items-center justify-content-center justify-content-lg-end min-vh-100">
            <div class="scrollable-content col-sm-7 col-lg-6 col-xl-6 py-6 py-lg-0 position-absolute h-100 top-0 right-0">
              <div class="row justify-content-center">
                <div class="col-11 col-lg-10 col-xl-10">
                  <div>
                    {this.state.loadingFetch && <Loading />}
                    {this.renderEndStep()}
                    {!this.state.finished ? (
                      <>
                        <div class="mt-5 mb-5">
                          <h6 class="h3 mb-1">Inscription Concours ISCAM</h6>
                          <p class="text-muted mb-0">
                            Documents justificatifs nécessaires
                          </p>
                        </div>
                        <span class="clearfix"></span>
                        <p>
                          Nous avons bien pris en compte les informations que
                          vous avez saisi lors des étapes précédentes.
                          <br />
                          Merci de préparer les documents suivants afin de
                          finaliser votre inscription :
                        </p>
                        <ul>
                          <li>CIN ou Acte de naissance ou Passeport</li>
                          <li>CV</li>
                          <li>Photo d'identité</li>
                          <li>Diplome</li>
                          <li>
                            Bordereau de versement à la banque du droit de
                            concours
                          </li>
                          {/* entrance grade = 1:L1 or 2:L2 or 3:L3 or 4:M1 or 5:M2 */}
                          {this.props.grade <= 5 ? (
                            <li>Notes des 3 dérnières années</li>
                          ) : null}
                          {/* entrance grade = 6:DES or 7:DESA or 8:DEPA */}
                          {this.props.grade >= 6 ? (
                            <li>
                              Attestation d'emploi ou Certificat de travail
                            </li>
                          ) : null}
                        </ul>

                        <form>
                          <div
                            ref={(node) => (this.componentRef.type = node)}
                            class="form-group"
                          >
                            <label class="form-control-label">
                              Type de document*
                            </label>
                            <div class="input-group">
                              <select
                                class="form-control"
                                name="type"
                                value={this.state.data.documents[0].type}
                                onChange={this.onChangeText}
                              >
                                <option value="" disabled selected>
                                  Veuillez choisir
                                </option>
                                <option value="100">CIN</option>
                                <option value="110">Acte de naissance</option>
                                <option value="120">Passeport</option>
                              </select>
                            </div>
                            {this.renderError("data.documents[0].type") ||
                              this.renderError("data.cin_birth_certificate")}
                          </div>
                          {this.renderIdentity()}
                          <hr />
                          <div
                            ref={(node) =>
                              (this.componentRef["documents[1]"] = node)
                            }
                            class="form-group"
                          >
                            <label class="form-control-label">CV*</label>
                            <div class="input-group">
                              <InputFile
                                name="140"
                                file_name={
                                  this.state.data.documents[1].file_name
                                }
                                disabled={this.state.data.documents[1].loading}
                                onChange={this.onChangeFile}
                                accept="image/*, .pdf"
                              />
                            </div>
                            {!this.state.data.documents[1].loading &&
                            this.state.data.documents[1].document ? (
                              <img
                                className="img-fluid rounded"
                                style={{
                                  width: 75,
                                  height: 75,
                                  objectFit: "cover",
                                }}
                                src={
                                  this.state.data.documents[1].mime_type.split(
                                    "/"
                                  )[1] === "pdf"
                                    ? pdfIcon
                                    : `data:image/png;base64,${this.state.data.documents[1].document}`
                                }
                                alt=""
                              />
                            ) : this.state.data.documents[1].loading ? (
                              <div
                                className="d-flex align-items-center justify-content-center"
                                style={{ width: 75, height: 75 }}
                              >
                                <span
                                  className="spinner-border spinner-border-sm"
                                  role="status"
                                  aria-hidden="true"
                                />
                              </div>
                            ) : null}
                            {this.renderError("data.documents[1].document") ||
                              this.renderError("data.cv")}
                          </div>
                          <hr />
                          <div
                            ref={(node) =>
                              (this.componentRef["documents[2]"] = node)
                            }
                            class="form-group"
                          >
                            <label class="form-control-label">
                              Photo d'identité*
                            </label>
                            <div class="input-group">
                              <InputFile
                                name="150"
                                file_name={
                                  this.state.data.documents[2].file_name
                                }
                                disabled={this.state.data.documents[2].loading}
                                onChange={this.onChangeFile}
                                accept="image/*, .pdf"
                              />
                            </div>
                            {!this.state.data.documents[2].loading &&
                            this.state.data.documents[2].document ? (
                              <img
                                className="img-fluid rounded"
                                style={{
                                  width: 75,
                                  height: 75,
                                  objectFit: "cover",
                                }}
                                src={
                                  this.state.data.documents[2].mime_type.split(
                                    "/"
                                  )[1] === "pdf"
                                    ? pdfIcon
                                    : `data:image/png;base64,${this.state.data.documents[2].document}`
                                }
                                alt=""
                              />
                            ) : this.state.data.documents[2].loading ? (
                              <div
                                className="d-flex align-items-center justify-content-center"
                                style={{ width: 75, height: 75 }}
                              >
                                <span
                                  className="spinner-border spinner-border-sm"
                                  role="status"
                                  aria-hidden="true"
                                />
                              </div>
                            ) : null}
                            {this.renderError("data.documents[2].document") ||
                              this.renderError("data.id_photo")}
                          </div>
                          <hr />
                          <div
                            ref={(node) =>
                              (this.componentRef["documents[3]"] = node)
                            }
                            class="form-group"
                          >
                            <label class="form-control-label">Diplome</label>
                            <br />
                            <label class="form-control-label font-italic">
                              Si vous disposez de plusieurs diplômes, merci de
                              les mettre dans un seul fichier
                            </label>
                            <div class="input-group">
                              <InputFile
                                name="170"
                                file_name={
                                  this.state.data.documents[3].file_name
                                }
                                disabled={this.state.data.documents[3].loading}
                                onChange={this.onChangeFile}
                                accept="image/*, .pdf"
                              />
                            </div>
                            {!this.state.data.documents[3].loading &&
                            this.state.data.documents[3].document ? (
                              <img
                                className="img-fluid rounded"
                                style={{
                                  width: 75,
                                  height: 75,
                                  objectFit: "cover",
                                }}
                                src={
                                  this.state.data.documents[3].mime_type.split(
                                    "/"
                                  )[1] === "pdf"
                                    ? pdfIcon
                                    : `data:image/png;base64,${this.state.data.documents[3].document}`
                                }
                                alt=""
                              />
                            ) : this.state.data.documents[3].loading ? (
                              <div
                                className="d-flex align-items-center justify-content-center"
                                style={{ width: 75, height: 75 }}
                              >
                                <span
                                  className="spinner-border spinner-border-sm"
                                  role="status"
                                  aria-hidden="true"
                                />
                              </div>
                            ) : null}
                            {this.renderError("data.documents[3].document") ||
                              this.renderError("data.diploma")}
                          </div>
                          <hr />
                          <div
                            ref={(node) =>
                              (this.componentRef["documents[4]"] = node)
                            }
                            class="form-group"
                          >
                            <label class="form-control-label">
                              Bordereau de versement à la banque du droit de
                              concours*
                            </label>
                            <div class="input-group">
                              <InputFile
                                name="190"
                                file_name={
                                  this.state.data.documents[4].file_name
                                }
                                disabled={this.state.data.documents[4].loading}
                                onChange={this.onChangeFile}
                                accept="image/*, .pdf"
                              />
                            </div>
                            {!this.state.data.documents[4].loading &&
                            this.state.data.documents[4].document ? (
                              <img
                                className="img-fluid rounded"
                                style={{
                                  width: 75,
                                  height: 75,
                                  objectFit: "cover",
                                }}
                                src={
                                  this.state.data.documents[4].mime_type.split(
                                    "/"
                                  )[1] === "pdf"
                                    ? pdfIcon
                                    : `data:image/png;base64,${this.state.data.documents[4].document}`
                                }
                                alt=""
                              />
                            ) : this.state.data.documents[4].loading ? (
                              <div
                                className="d-flex align-items-center justify-content-center"
                                style={{ width: 75, height: 75 }}
                              >
                                <span
                                  className="spinner-border spinner-border-sm"
                                  role="status"
                                  aria-hidden="true"
                                />
                              </div>
                            ) : null}
                            {this.renderError("data.documents[4].document") ||
                              this.renderError("data.payment")}
                          </div>
                          <hr />
                          {/* entrance grade = (1:L1 or 2:L2 or 3:M1 or 4:M1 or 5:M2) */}
                          {this.props.grade <= 5 ? (
                            <div
                              ref={(node) =>
                                (this.componentRef["documents[5]"] = node)
                              }
                              class="form-group"
                            >
                              <label class="form-control-label">
                                Notes des 3 dernières années*
                              </label>
                              <br />
                              {this.renderError("data.grades")}
                              <label class="form-control-label font-italic">
                                Si vous avez plusieurs relevés de notes par
                                année scolaire, merci de les mettre dans un seul
                                fichier
                              </label>
                              <div class="input-group">
                                <InputFile
                                  id="1"
                                  name="130"
                                  file_name={
                                    this.state.data.documents[5].file_name
                                  }
                                  disabled={
                                    this.state.data.documents[5].loading
                                  }
                                  onChange={this.onChangeGrade}
                                  accept="image/*, .pdf"
                                />
                              </div>
                              {!this.state.data.documents[5].loading &&
                              this.state.data.documents[5].document ? (
                                <img
                                  className="img-fluid rounded"
                                  style={{
                                    width: 75,
                                    height: 75,
                                    objectFit: "cover",
                                    marginBottom: 4,
                                  }}
                                  src={
                                    this.state.data.documents[5].mime_type.split(
                                      "/"
                                    )[1] === "pdf"
                                      ? pdfIcon
                                      : `data:image/png;base64,${this.state.data.documents[5].document}`
                                  }
                                  alt=""
                                />
                              ) : this.state.data.documents[5].loading ? (
                                <div
                                  className="d-flex align-items-center justify-content-center"
                                  style={{ width: 75, height: 75 }}
                                >
                                  <span
                                    className="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                  />
                                </div>
                              ) : null}
                              {this.renderError("data.documents[5].document")}
                              <div
                                ref={(node) =>
                                  (this.componentRef["documents[6]"] = node)
                                }
                                class="input-group"
                              >
                                <InputFile
                                  id="2"
                                  name="130"
                                  file_name={
                                    this.state.data.documents[6].file_name
                                  }
                                  disabled={
                                    this.state.data.documents[6].loading
                                  }
                                  onChange={this.onChangeGrade}
                                  accept="image/*, .pdf"
                                />
                              </div>
                              {!this.state.data.documents[6].loading &&
                              this.state.data.documents[6].document ? (
                                <img
                                  className="img-fluid rounded"
                                  style={{
                                    width: 75,
                                    height: 75,
                                    objectFit: "cover",
                                    marginBottom: 4,
                                  }}
                                  src={
                                    this.state.data.documents[6].mime_type.split(
                                      "/"
                                    )[1] === "pdf"
                                      ? pdfIcon
                                      : `data:image/png;base64,${this.state.data.documents[6].document}`
                                  }
                                  alt=""
                                />
                              ) : this.state.data.documents[6].loading ? (
                                <div
                                  className="d-flex align-items-center justify-content-center"
                                  style={{ width: 75, height: 75 }}
                                >
                                  <span
                                    className="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                  />
                                </div>
                              ) : null}
                              {this.renderError("data.documents[6].document")}
                              <div
                                ref={(node) =>
                                  (this.componentRef["documents[7]"] = node)
                                }
                                class="input-group"
                              >
                                <InputFile
                                  id="3"
                                  name="130"
                                  file_name={
                                    this.state.data.documents[7].file_name
                                  }
                                  disabled={
                                    this.state.data.documents[7].loading
                                  }
                                  onChange={this.onChangeGrade}
                                  accept="image/*, .pdf"
                                />
                              </div>
                              {!this.state.data.documents[7].loading &&
                              this.state.data.documents[7].document ? (
                                <img
                                  className="img-fluid rounded"
                                  style={{
                                    width: 75,
                                    height: 75,
                                    objectFit: "cover",
                                  }}
                                  src={
                                    this.state.data.documents[7].mime_type.split(
                                      "/"
                                    )[1] === "pdf"
                                      ? pdfIcon
                                      : `data:image/png;base64,${this.state.data.documents[7].document}`
                                  }
                                  alt=""
                                />
                              ) : this.state.data.documents[7].loading ? (
                                <div
                                  className="d-flex align-items-center justify-content-center"
                                  style={{ width: 75, height: 75 }}
                                >
                                  <span
                                    className="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                  />
                                </div>
                              ) : null}
                              {this.renderError("data.documents[7].document")}
                              <hr />
                            </div>
                          ) : null}
                          {/* entrance grade = 6:DES or 7:DESA or 8:DEPA */}
                          {this.props.grade >= 6 ? (
                            <div
                              ref={(node) =>
                                (this.componentRef["documents[5]"] = node)
                              }
                              class="form-group"
                            >
                              <label class="form-control-label">
                                Attestation d'emploi ou Certificat de travail*
                              </label>
                              <div class="input-group">
                                <InputFile
                                  name="160"
                                  file_name={
                                    this.state.data.documents[5].file_name
                                  }
                                  disabled={
                                    this.state.data.documents[5].loading
                                  }
                                  onChange={this.onChangeFile}
                                  accept="image/*, .pdf"
                                />
                              </div>
                              {!this.state.data.documents[5].loading &&
                              this.state.data.documents[5].document ? (
                                <img
                                  className="img-fluid rounded"
                                  style={{
                                    width: 75,
                                    height: 75,
                                    objectFit: "cover",
                                  }}
                                  src={
                                    this.state.data.documents[5].mime_type.split(
                                      "/"
                                    )[1] === "pdf"
                                      ? pdfIcon
                                      : `data:image/png;base64,${this.state.data.documents[5].document}`
                                  }
                                  alt=""
                                />
                              ) : this.state.data.documents[5].loading ? (
                                <div
                                  className="d-flex align-items-center justify-content-center"
                                  style={{ width: 75, height: 75 }}
                                >
                                  <span
                                    className="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                  />
                                </div>
                              ) : null}
                              {this.renderError("data.documents[5].document") ||
                                this.renderError("data.work_certificate")}
                            </div>
                          ) : null}

                          <div class="mt-4 mb-4">
                            {this.state.loading ? (
                              <button
                                type="button"
                                class="btn btn-block btn-primary"
                                disabled
                              >
                                <span
                                  class="spinner-border spinner-border-sm"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                              </button>
                            ) : (
                              <button
                                type="button"
                                onClick={this.onSubmit}
                                class="btn btn-block btn-primary"
                                disabled={this.state.examSessionDeadlinePassed}
                              >
                                Valider
                              </button>
                            )}
                          </div>
                          <p>
                            Si vous souhaitez poursuivre votre inscription plus
                            tard, merci d’accéder à votre dossier à travers le
                            mail que vous avez reçu de{" "}
                            <a href="mailto:information@iscam.mg">
                              information@iscam.mg
                            </a>{" "}
                            en validant cette étape.
                            <br />
                            Merci de vérifier dans le Spam si vous ne voyez pas
                            le mail dans votre boite de réception.
                          </p>
                        </form>
                      </>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Step4;
