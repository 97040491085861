import React, { Component } from "react";
import { Redirect } from "react-router";
import * as Icon from "react-feather";
import $ from "jquery";
import _ from "lodash";
import API from "../../Api";

import { PersonOfInterestEditor } from "../../components/Components";

class Step3 extends Component {
  constructor(props) {
    super(props);

    /*if (props.registerStep.state.step1 === null) {
      this.state = { redirect: "/register/1" };
      return;
    }*/
    let token;
    if (this.props.token) {
      this.token = this.props.token;
    }
  }

  render() {
    return <PersonOfInterestEditor token={this.token} collection={this} />;
  }
}

export default Step3;
