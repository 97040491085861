import { unsecured as axios } from "../../../axios";

class Interest {
  static async findAll() {
    return await axios.get("/list/interests").then((res) => {
      return res.data;
    });
  }
}

export { Interest };
