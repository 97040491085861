import React, { Component } from "react";
import _ from "lodash";

import { PersonOfInterestEditor } from "../../components/Components";

class Step6_1_2 extends Component {
  constructor(props) {
    super(props);

    /*if (props.registerStep.state.step1 === null) {
      this.state = { redirect: "/register/1" };
      return;
    }*/
    if (this.props.token) {
      this.token = this.props.token;
    }
  }

  render() {
    return (
      <PersonOfInterestEditor
        reviewStep={true}
        token={this.token}
        collection={this}
      />
    );
  }
}

export default Step6_1_2;
