import React, { useState } from "react";
import ParentForm from "./ParentForm";
import ParentCard from "./ParentCard";
import { random } from "../../../helper";

export const FATHER = 1;
export const MOTHER = 2;
export const GUARDIAN = 3;

const Parent = ({ value = [], onChange, hasError = false, message = "" }) => {
  const [showForm, setShowForm] = useState(false);
  const [parents, setParents] = useState(value);

  const handleValidate = (data) => {
    data["_id"] = random();
    setParents((parents) => [...parents, data]);
    setShowForm(false);
    onChange && onChange([...parents, data]);
  };

  const handleRemove = (_id) => {
    onChange && onChange(parents.filter((parent) => parent._id !== _id));
    setParents((parents) => parents.filter((parent) => parent._id !== _id));
  };

  return (
    <div className="mb-5">
      <div className="mb-5 mt-5">
        <p className="text-muted mb-0">Information sur les Parents</p>
      </div>
      {hasError && (
        <p style={{ color: "red", fontWeight: "bold" }}>{message}</p>
      )}
      {parents.map((parent, index) => (
        <ParentCard key={index} {...parent} onRemove={handleRemove} />
      ))}
      {!showForm && (
        <button
          className="btn btn-block btn-primary"
          type="button"
          onClick={() => setShowForm(true)}
        >
          Ajouter un parent
        </button>
      )}
      {showForm && (
        <div className="card p-3 rounded-0">
          <ParentForm
            canSelectMother={
              parents.filter((parent) => MOTHER === parseInt(parent.parentType))
                .length === 0
            }
            canSelectFather={
              parents.filter((parent) => FATHER === parseInt(parent.parentType))
                .length === 0
            }
            onCancel={() => setShowForm(false)}
            onValidate={handleValidate}
          />
        </div>
      )}
    </div>
  );
};

export default Parent;
