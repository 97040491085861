import React from "react";
import { useLevelDocuments } from "./hooks";

const RequiredDocuments = () => {
  const { documents } = useLevelDocuments();

  return (
    <div className="mt-4">
      <h5 className="h6 mb-0 text-uppercase mb-4" id="required-documents">
        Documents à fournir
      </h5>
      {documents.map((document, index) => (
        <div id={`accordion-${index}`} class="accordion accordion-spaced">
          <div class="card" key={index}>
            <div
              class="card-header py-2"
              id={`#heading-${index}`}
              data-toggle="collapse"
              role="button"
              data-target={`#collapse-${index}`}
              aria-expanded="false"
              aria-controls={`collapse-${index}`}
            >
              <h6 class="mb-0">{document.level}</h6>
            </div>
            <div
              id={`collapse-${index}`}
              class="collapse"
              aria-labelledby={`#collapse-${index}`}
              data-parent={`#accordion-${index}`}
            >
              <div class="card-body">
                <ul>
                  {document.documents.map((doc) => (
                    <li>{doc}</li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      ))}
      <p className="banktext">
        *RN : Relevé de notes ou Bulletin Versement d'espèces à la banque pour
        le droit d'inscription au concours :
        <ul style={{ listStyleType: "none" }} className="ml-1">
          <li>BFV N° 00008 00003 05003009300 78,</li>
          <li>BNI N° 00005 00001 01532130100 24,</li>
          <li>BOA N° 00009 05020 13734570009 69,</li>
          <li>Accès Banque N° 00011 00028 24100337861 51.</li>
        </ul>
      </p>
    </div>
  );
};

export default RequiredDocuments;
