import React, { Component } from "react";
import { Redirect } from "react-router";
import * as Icon from "react-feather";
import API from "../../Api";
import _ from "lodash";

import { InputFile, EndStep, Loading } from "../../components/Components";
import pdfIcon from "../../assets/img/icons/pdf-icon.png";
import wordIcon from "../../assets/img/icons/word-icon.png";
import { getMomentLocaleData } from "fullcalendar";
import $ from "jquery";

class Step5 extends Component {
  constructor(props) {
    super(props);
    this.buttonModal = React.createRef();
    this.errorModal = React.createRef();
    /*if (this.props.registerStep.state.step3 === null) {
      this.state = { redirect: "/register/3" };
      return;
    }*/

    if (this.props.token) {
      API.defaults.headers.common["X-AUTH-TOKEN"] = this.props.token;
      localStorage.setItem(
        "X-AUTH-TOKEN_new",
        JSON.stringify(this.props.token)
      );
    }

    this.state = {
      loading: false,
      loadingFetch: false,
      redirect: "",
      errors: [],
      success: false,
      confirmation: false,
      profile: {
        firstname: "",
        lastname: "",
        email: "",
        status: "",
        step: "",
        assignment_id: "",
        exam_session_id: "",
      },
      assignment: {
        id: "",
        name: "",
        description: "",
      },
      entrance_exam_session: {
        name: "",
        start_date: "",
        end_date: "",
      },
      data: {
        documents: [
          {
            type: 240,
            document: "",
            file_name: "",
            mime_type: "",
          },
        ],
      },
    };

    this.componentRef = {
      "documents[0]": null,
    };

    this.HandleClickUnderstood = this.HandleClickUnderstood.bind(this);
    this.findElementToScroll = this.findElementToScroll.bind(this);
    this.onChangeFile = this.onChangeFile.bind(this);
    this.hasError = this.hasError.bind(this);
    this.renderError = this.renderError.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.renderEndStep = this.renderEndStep.bind(this);
    this.getData = this.getData.bind(this);
    this.onChecked = this.onChecked.bind(this);
  }

  componentDidMount() {
    if (_.has(localStorage, "X-AUTH-TOKEN_new")) {
      API.defaults.headers.common["X-AUTH-TOKEN"] = JSON.parse(
        localStorage.getItem("X-AUTH-TOKEN_new")
      );
      //this.getData();
      this.applicantProfile();
    }
  }

  getData() {
    this.setState({ loadingFetch: true });
    API.get("/register/step-5").then(
      function (response) {
        this.setState({ loadingFetch: false });
        var response = response.data["0"];
        if (Array.isArray(response) && response.length) {
          var docs = this.state.data.documents;

          docs[0].type = response[0].type;
          docs[0].document = response[0].document;
          docs[0].file_name = response[0].file_name;
          docs[0].mime_type = response[0].mime_type;

          var data = this.state.data;
          data.documents = [...docs];

          this.setState({ data: data });
        }
      }.bind(this)
    );
  }

  async applicantProfile() {
    try {
      this.setState({ loadingFetch: true });
      const {
        data: { 0: profile },
      } = await API.get("/applicant/profile");
      this.setState({ profile });

      const { data: assignment } = await API.get(
        `/assignment/${profile.assignment_id}`
      );
      this.setState({ assignment });

      const { data: entrance_exam_session } = await API.get(
        `/entrance-exam-session/${profile.exam_session_id}`
      );
      const { start_date } = entrance_exam_session;
      if (
        !start_date ||
        this.compareDateToNow(this.getDateFromFormattedString(start_date)) >= 0
      ) {
        $("#examSessionNotActive").modal();
        this.setState({ examSessionNotActive: true });
      } else {
        this.setState({ examSessionNotActive: false });
      }
      this.setState({ entrance_exam_session });
      this.setState({ loadingFetch: false });
    } catch (error) {}
  }

  getDateFromFormattedString(value) {
    const parts = value.split(" ");
    const date = parts[0],
      time = parts[1];

    const dateParts = date.split("/"),
      timeParts = time.split(":");

    return {
      year: Number(dateParts[2]),
      month: Number(dateParts[1]),
      date: Number(dateParts[0]),
      hours: Number(timeParts[0]),
      minutes: Number(timeParts[1]),
      seconds: Number(timeParts[2]),
    };
  }

  compareDateToNow({ year, month, date, hours, minutes, seconds }) {
    const now = new Date(),
      d = new Date(year, month - 1, date, hours, minutes, seconds);

    return d.getTime() - now.getTime();
  }

  onChangeFile(event, file) {
    var documents = this.state.data.documents;

    if (event.nativeEvent.error) {
      documents.forEach((doc) => {
        if (doc.type == event.target.name) {
          doc.document = "";
          doc.file_name = "";
          doc.mime_type = "";
        }
      });
      return this.setState({ data: { documents } });
    }

    event.persist();
    var reader = new FileReader();
    let b64 = "";

    reader.onload = () => {
      b64 = window.btoa(reader.result);
      var docs = this.state.data.documents;
      docs.forEach((doc) => {
        if (doc.type == event.target.name) {
          doc.document = b64;
          doc.file_name = event.target.files[0].name;
          doc.mime_type = event.target.files[0].type;
        }
      });
      var data = this.state.data;
      data.documents = [...docs];
      this.setState({ data: data });
    };
    reader.readAsBinaryString(event.target.files[0]);
  }

  onChecked(event) {
    this.setState({ confirmation: event.target.checked });
  }

  HandleClickUnderstood() {
    this.setState({ redirect: "/" });
  }

  renderError(e) {
    if (this.hasError(e)) {
      return (
        <div class="text-danger">
          <Icon.XOctagon size="1em" />
          &nbsp;{this.state.errors[e]}
        </div>
      );
    }
  }

  hasError(e) {
    if (!_.isEmpty(this.state.errors)) {
      var keys = Object.keys(this.state.errors);
      return keys.includes(e);
    }
  }

  onSubmit() {
    this.setState({ loading: true });
    API.post("/register/step-5", this.state.data.documents[0])
      .then(
        function (response) {
          this.setState({ loading: false });
          this.buttonModal.current.click();
        }.bind(this)
      )
      .catch(
        function (error) {
          this.setState({ errors: error.response.data["0"], loading: false });
          var errors = this.getNameErrors(error.response.data[0]);
          this.findElementToScroll(errors);
          if (this.hasError("data") || this.hasError("data.type")) {
            this.errorModal.current.click();
          }
        }.bind(this)
      );
  }

  findElementToScroll(errors) {
    for (var element in this.componentRef) {
      if (errors.includes(element)) {
        this.scrollToNode(this.componentRef[`${String(element)}`]);
        break;
      }
    }
  }

  getNameErrors(errors) {
    var keyNames = [];
    var keys = Object.keys(errors);
    keys.forEach((key) => {
      var name = key.split(".");
      if (name[name.length - 1] === "document") {
        keyNames.push(name[name.length - 2]);
      } else {
        keyNames.push(name[name.length - 1]);
      }
    });
    return keyNames;
  }

  scrollToNode(node) {
    node.scrollIntoView({ block: "start", behavior: "smooth" });
  }

  renderEndStep() {
    if (this.state.finished) {
      return <EndStep />;
    } else {
      return null;
    }
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }

    return (
      <>
        <div
          class="modal fade"
          id="examSessionNotActive"
          tabindex="-1"
          aria-labelledby="examSessionNotActive"
          aria-hidden="true"
        >
          <div class="modal-dialog">
            <div class="modal-content border border-danger">
              <div class="modal-header bg-danger">
                <h5 class="modal-title" id="examSessionNotActive">
                  <span class="text-white">Session pas encore active</span>
                </h5>

                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                Veuillez s'il vous plaît patienter jusqu'à la date de début de
                concours pour remettre votre devoir.
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-secondary btn-sm"
                  data-dismiss="modal"
                >
                  Fermer
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="register-right-pane container-fluid d-flex flex-column">
          <div class="row align-items-center justify-content-center justify-content-lg-end min-vh-100">
            <div class="scrollable-content col-sm-7 col-lg-6 col-xl-6 py-6 py-lg-0 position-absolute h-100 top-0 right-0">
              <div class="row justify-content-center">
                <div class="col-11 col-lg-10 col-xl-10">
                  <div>
                    {this.state.loadingFetch && <Loading />}
                    <div
                      class="modal fade"
                      id="staticBackdrop"
                      data-backdrop="static"
                      data-keyboard="false"
                      tabindex="-1"
                    >
                      <div class="modal-dialog">
                        <div class="modal-content">
                          <div class="modal-header">
                            <h4 class="modal-title" id="staticBackdropLabel">
                              Félicitations !!
                            </h4>
                          </div>
                          <div class="modal-body">
                            Vous avez terminé votre devoir. Nous avons bien reçu
                            votre document et avons envoyé un e-mail à votre
                            adresse renseignée lors de l’inscription (
                            <b>{this.state.profile.email}</b>).
                          </div>
                          <div class="modal-footer">
                            <button
                              type="button"
                              class="btn btn-primary"
                              data-dismiss="modal"
                              onClick={this.HandleClickUnderstood}
                            >
                              J'ai compris
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      class="modal fade"
                      id="errorModal"
                      tabindex="-1"
                      aria-labelledby="errorModalLabel"
                      aria-hidden="true"
                    >
                      <div class="modal-dialog">
                        <div class="modal-content border border-danger">
                          <div class="modal-header bg-danger">
                            <h5 class="modal-title" id="errorModalLabel">
                              <span class="text-white">Erreur</span>
                            </h5>

                            <button
                              type="button"
                              class="close"
                              data-dismiss="modal"
                              aria-label="Close"
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          <div class="modal-body">
                            {this.state.errors["data"]}
                            {this.state.errors["data.type"]}
                          </div>
                          <div class="modal-footer">
                            <button
                              type="button"
                              class="btn btn-secondary btn-sm"
                              data-dismiss="modal"
                            >
                              Fermer
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="mt-5 mb-3">
                      <h6 class="h3 mb-1">Concours ISCAM</h6>
                      <p>
                        Session du
                        <b> {this.state.entrance_exam_session.name} </b>
                      </p>
                    </div>
                    <span class="clearfix"></span>
                    <ul>
                      <li>
                        Début de l'épreuve :{" "}
                        <b>
                          {
                            this.state.entrance_exam_session.start_date.split(
                              " "
                            )[0]
                          }
                        </b>{" "}
                        à
                        <b>
                          {" "}
                          {
                            this.state.entrance_exam_session.start_date.split(
                              " "
                            )[1]
                          }
                        </b>
                      </li>
                      <li>
                        Fin de l'épreuve :{" "}
                        <b>
                          {
                            this.state.entrance_exam_session.end_date.split(
                              " "
                            )[0]
                          }
                        </b>{" "}
                        à{" "}
                        <b>
                          {
                            this.state.entrance_exam_session.end_date.split(
                              " "
                            )[1]
                          }
                        </b>
                      </li>
                    </ul>

                    <hr />
                    <div class="card shadow rounded">
                      <div class="card-body">
                        <h5 class="card-title">{this.state.assignment.name}</h5>
                        <p class="card-text">
                          {this.state.assignment.description}
                        </p>
                      </div>
                    </div>
                    <hr />

                    <form>
                      <div
                        ref={(node) =>
                          (this.componentRef["documents[0]"] = node)
                        }
                        class="form-group"
                      >
                        <label class="form-control-label">
                          Document à rendre*
                        </label>
                        <div class="input-group">
                          <InputFile
                            name="240"
                            fileName={this.state.data.documents[0].file_name}
                            onChange={this.onChangeFile}
                            accept=".pdf, .doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                          />
                        </div>
                        {this.state.data.documents[0].document ? (
                          <img
                            class="img-fluid rounded"
                            style={{
                              width: 75,
                              height: 75,
                              objectFit: "cover",
                            }}
                            src={
                              this.state.data.documents[0].mime_type.split(
                                "/"
                              )[1] === "pdf"
                                ? pdfIcon
                                : wordIcon
                            }
                          />
                        ) : null}
                        {this.renderError("data.document")}
                      </div>

                      <div class="custom-control custom-checkbox mr-lg-2 mt-3">
                        <input
                          id="checkBox"
                          class="form-check-input "
                          type="checkbox"
                          onChange={this.onChecked}
                          checked={this.state.confirmation}
                        />
                        <label class="form-check-label" for="checkBox">
                          J’ai pris connaissance que cet envoi de fichier est
                          définitif et ne pourra être modifié ultérieurement.
                        </label>
                      </div>

                      <div class="mt-4 mb-4">
                        {this.state.loading ? (
                          <button
                            type="button"
                            class="btn btn-block btn-primary"
                            disabled
                          >
                            <span
                              class="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          </button>
                        ) : (
                          <button
                            type="button"
                            onClick={this.onSubmit}
                            class="btn btn-block btn-primary"
                            disabled={
                              !this.state.confirmation ||
                              this.state.examSessionNotActive
                            }
                          >
                            Valider
                          </button>
                        )}
                        <button
                          ref={this.buttonModal}
                          type="button"
                          class="btn btn-primary d-none"
                          data-toggle="modal"
                          data-target="#staticBackdrop"
                        >
                          Launch static backdrop modal
                        </button>
                        <button
                          ref={this.errorModal}
                          type="button"
                          class="btn btn-primary d-none"
                          data-toggle="modal"
                          data-target="#errorModal"
                        >
                          Launch demo modal
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Step5;
