import React, { Component } from "react";

class Loading extends Component {
  render() {
    return (
      <div
        class="d-flex flex-column justify-content-center align-items-center"
        style={{
          position: "fixed",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 100000,
          backgroundColor: "rgba(0, 0, 0, .6)",
        }}
      >
        <div class="d-flex flex-column justify-content-center align-items-center">
          <div class="spinner-border text-light" role="status">
            <span class="sr-only">Loading...</span>
          </div>
          <p class="mt-2 text-light font-weight-lighter">
            Récupération des données
          </p>
        </div>
      </div>
    );
  }
}

export default Loading;
