import React, { Component } from "react";
import * as Icon from "react-feather";
import partnerGrenoble from "../assets/img/partnerships/grenoble-school.svg";
import partnerHeci from "../assets/img/partnerships/heci-school.svg";
import partnerEscem from "../assets/img/partnerships/escem-school.svg";
import partner3A from "../assets/img/partnerships/3A-school.svg";
import partnerUns from "../assets/img/partnerships/uns-school.svg";
import partnerUqam from "../assets/img/partnerships/uqam-school.svg";
import partnerEsca from "../assets/img/partnerships/esca-school.svg";
import partnerEssec from "../assets/img/partnerships/essec-school.svg";
import partnerBrawijaya from "../assets/img/partnerships/brawijaya-school.svg";
import intervieweeRindra from "../assets/img/interviews/rindra-square.jpg";
import intervieweeAshley from "../assets/img/interviews/ashley-square.jpg";
import imageInternational from "../assets/img/landing/digital-school.png";
import pdfInfoGeneral from "../assets/pdf/ISCAM_Plaquette_Presentation.pdf";
import { RequiredDocuments } from "../pages/home";

class Landing extends Component {
  render() {
    return (
      <div>
        <section className="slice slice-lg bg-primary">
          <div data-offset-top="#navbar-main">
            <div className="container d-flex align-items-center">
              <div className="col px-0">
                <div className="row row-grid align-items-center">
                  <div className="col-lg-6 text-center text-lg-left align-self-start">
                    <h1 className="display-4 text-white my-4">
                      Un programme de haut niveau
                    </h1>
                    <p className="lead text-white text-justify">
                      Nous offrons des programmes de qualité qui ont permis la
                      mise en place de plusieurs politiques d’échanges
                      d’étudiants accompagnés d’interventions régulières de
                      professionnels d’entreprises et d’expériences uniques.
                    </p>
                    <div className="row mt-5">
                      <div className="col-xl-5 col-lg-5 col-sm-5">
                        <a
                          href="#digital"
                          className="btn btn-white btn-lg btn-icon"
                        >
                          <span className="btn-inner--text">
                            En savoir plus
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-xl-5 col-sm-8 ml-auto mr-auto mr-lg-0">
                    <div className="card rounded-bottom-right">
                      <div className="card-body">
                        <div className="d-flex align-items-center mb-4">
                          <div>
                            <h5 className="h6 mb-0">
                              INSCRIPTION AU CONCOURS{" "}
                            </h5>
                          </div>
                        </div>
                        <p>
                          L'inscription au concours se déroule en 2 étapes :
                        </p>
                        <ul className="list">
                          <li>
                            Renseignez les informations personnelles demandées
                          </li>
                          <li>
                            Téléversez sur la plateforme les éléments de votre
                            dossier
                          </li>
                        </ul>
                        <p className="text-justify">
                          Le concours se déroulera en trois étapes. Vous serez
                          évalué sur votre dossier académique, puis sur
                          approbation passerez l'épreuve écrite. Sur résultat
                          concluant, vous recevrez une convocation pour un
                          entretien oral.
                        </p>
                        <div
                          data-toggle="tooltip"
                          data-placement="bottom"
                          title=""
                        >
                          <RequiredDocuments />
                          <a
                            className="btn btn-primary btn-icon text-white"
                            onClick={() => {
                              this.props.history.push("/inscription");
                            }}
                          >
                            <Icon.User size="1em" />
                            &nbsp;&nbsp;S'inscrire au concours
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="shape-container shape-position-bottom shape-line">
            <svg
              width="2560px"
              height="100px"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              preserveAspectRatio="none"
              x="0px"
              y="0px"
              viewBox="0 0 2560 100"
              style={{ enableBackground: "new 0 0 2560 100" }}
              xmlSpace="preserve"
              className=""
            >
              <polygon points="2560 0 2560 100 0 100"></polygon>
            </svg>
          </div>
        </section>
        <section className="slice pt-0">
          <div id="digital" className="container">
            <div className="section-process-step">
              <div className="container">
                <div className="row row-grid align-items-center justify-content-between">
                  <div className="col-xl-5 col-lg-6">
                    <div className="pr-md-4">
                      <span className="badge badge-success badge-pill">
                        Ecole reconnue
                      </span>
                      <h3 className="h2 mt-4 lh-190">
                        Un rayonnement national et international
                      </h3>
                      <div className="mt-5">
                        <p>
                          La confiance des professionnels dans les recrutements.{" "}
                          <br />
                          Plus de 70% des diplômés recrutés dans les 3 mois
                          après l’obtention du diplôme; les Iscamiens sont les
                          premiers à être recrutés par les entreprises selon
                          l’étude de FTHM en 2017.
                        </p>
                      </div>
                      <a
                        href={pdfInfoGeneral}
                        target="_blank"
                        className="btn btn-success mt-5 d-none"
                      >
                        Télécharger la plaquette
                      </a>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="card border-0 shadow-lg">
                      <figure>
                        <img
                          alt="Un rayonnement international"
                          src={imageInternational}
                          className="card-img"
                        />
                      </figure>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="section-process-step">
              <div className="container">
                <div className="row row-grid align-items-center justify-content-between">
                  <div className="col-xl-5 col-lg-6 order-lg-2">
                    <div className="pr-md-4">
                      <span className="badge badge-primary badge-pill">
                        Témoignages
                      </span>
                      <h3 className="h2 mt-4">
                        Retrouvez les témoignages des ISCAMiens
                      </h3>
                      <p className="lead my-4 lh-190">
                        ISCAM est une famille. L'école se veut proche de ses
                        étudiants, anciens ou actuels. Chaque personne est
                        unique.
                      </p>
                      <a
                        href="/interviews"
                        className="link-underline-primary mt-4"
                      >
                        Voir les interviews
                      </a>
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 order-lg-1">
                    <div className="row mx-n2">
                      <div className="col-sm-6 mt-sm-6 px-sm-2">
                        <a href="/interviews/rindra">
                          <div className="card mb-3">
                            <div className="h-100">
                              <img
                                className="card-img-top"
                                src={intervieweeRindra}
                                alt="Rindra Razafindrazaka"
                              />
                            </div>
                            <div className="card-body p-3">
                              <div className="d-flex align-items-center">
                                <div className="pl-3">
                                  <span className="h6 text-sm mb-0">
                                    Rindra Razafindrazaka
                                    <br />
                                    Fondateur Hamac
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </a>
                      </div>
                      <div className="col-sm-6 mt-sm-6 px-sm-2">
                        <a href="/interviews/ashley">
                          <div className="card mb-3">
                            <div className="h-100">
                              <img
                                className="card-img-top"
                                src={intervieweeAshley}
                                alt="Card image cap"
                              />
                            </div>
                            <div className="card-body p-3">
                              <div className="d-flex align-items-center">
                                <div className="pl-3">
                                  <span className="h6 text-sm mb-0">
                                    Ashley Mamodaly
                                    <br />
                                    Étudiante ISCAM
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="cursus" className="section-process-step">
              <div className="container">
                <div className="row row-grid align-items-center justify-content-between">
                  <div className=" col-lg-6">
                    <div className="pr-md-4">
                      <span className="badge badge-warning badge-pill">
                        Cursus
                      </span>
                      <h3 className="h2 mt-4">Découvrez nos cursus</h3>
                    </div>
                  </div>
                </div>
              </div>
              <div className="jumbotron jumbotron-licence rounded-diagonal-right bg-dark border-0 rounded-lg py-5 mt-5">
                <div className="card-body px-5">
                  <div className="row align-items-center">
                    <div className="">
                      <h4 className="text-white">Licence</h4>
                      <p className="text-white text-justify">
                        Le programme de Licence est dédié aux étudiants ayant
                        obtenu leur Baccalauréat et souhaitant se spécialiser à
                        un domaine spécifique. Les Diplômés en Licence peuvent
                        devenir des cadres intermédiaires directement
                        opérationnels. Ils devront être capables d’occuper des
                        postes avec des responsabilités. Leur spécialisation et
                        parcours permettront d’apporter une valeur ajoutée à
                        l’entreprise une fois sur terrain.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="jumbotron jumbotron-master rounded-diagonal-right bg-dark border-0 rounded-lg py-5 mt-5">
                <div className="card-body px-5">
                  <div className="row align-items-center">
                    <div className="">
                      <h4 className="text-white">Master</h4>
                      <p className="text-white text-justify">
                        Le programme Master est dédié aux personnes ayant obtenu
                        un diplôme équivalent à la Licence (BAC+3) et souhaitant
                        poursuivre leur cursus universitaire. Dépendant de la
                        spécialisation, les Diplômés en Master pourront être des
                        cadres supérieurs au sein leur entreprise, créateurs et
                        développeur d’entreprises ou responsable de projets de
                        recherche et de développement.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="jumbotron jumbotron-sbm rounded-diagonal-right bg-dark border-0 rounded-lg py-5 mt-5">
                <div className="card-body px-5">
                  <div className="row align-items-center">
                    <div className="">
                      <h4 className="text-white">
                        Sustainable Business Management
                      </h4>
                      <p className="text-white text-justify">
                        Ce parcours va doter les nouveaux diplômés et les
                        professionnels d’une expertise leur permettant
                        d’accompagner les changements dans les organisations et
                        environnements devant allier exigences de production de
                        biens/services et objectifs de
                        responsabilité/soutenabilité. Ce programme est en
                        partenariat avec l’IAE Réunion et l'Université des
                        Mascareignes Maurice
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          id="partnerships"
          className="slice slice-lg bg-section-secondary"
        >
          <div className="container">
            <div className="row mb-6">
              <div className="col-md-6">
                <span className="badge badge-primary badge-pill">
                  Partenaires
                </span>
                <h5 className="h5 lh-180 mt-4 mb-xl-6">
                  Ecole à dimension internationale, découvrez nos partenaires.
                  Un échange à l'étranger ? Pourquoi pas vous !
                </h5>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row justify-content-xl-end">
              <div className="col-xl-2 col-lg-4 col-sm-6">
                <a href="https://www.grenoble-em.com/" target="_blank">
                  <div className="card hover-shadow-lg hover-translate-y-n3">
                    <div className="pt-4 h-100 w-50 mx-auto">
                      <img
                        alt="Grenoble Ecole de Management"
                        src={partnerGrenoble}
                        className="img-fluid partner-logo"
                      />
                    </div>
                    <div className="card-body pb-4 text-center">
                      <h5 style={{ wordBreak: "normal" }} className="mb-1">
                        Grenoble Ecole de Management
                      </h5>
                      <p className="text-muted text-sm mb-0">
                        Grenoble, France
                      </p>
                    </div>
                  </div>
                </a>
              </div>
              <div className="col-xl-2 col-lg-4 col-sm-6"></div>
              <div className="col-xl-2 col-lg-4 col-sm-6">
                <a href="https://www.lasalle-emci.com/" target="_blank">
                  <div className="card hover-shadow-lg hover-translate-y-n3">
                    <div className="pt-4 h-100 w-50 mx-auto">
                      <img
                        alt="HECI"
                        src={partnerHeci}
                        className="img-fluid partner-logo"
                      />
                    </div>
                    <div className="card-body pb-4 text-center">
                      <h5 className="mb-1">HECI</h5>
                      <p className="text-muted text-sm mb-0">
                        Saint-Etienne, France
                      </p>
                    </div>
                  </div>
                </a>
              </div>
              <div className="col-xl-2 col-lg-4 col-sm-6">
                <a href="https://www.escem.fr/" target="_blank">
                  <div className="card hover-shadow-lg hover-translate-y-n3 mt-xl-n6">
                    <div className="pt-4 h-100 w-50 mx-auto">
                      <img
                        alt="ESCEM"
                        src={partnerEscem}
                        className="img-fluid partner-logo"
                      />
                    </div>
                    <div className="card-body pb-4 text-center">
                      <h5 className="mb-1">ESCEM</h5>
                      <p className="text-muted text-sm mb-0">Tours, France</p>
                    </div>
                  </div>
                </a>
              </div>
              <div className="col-xl-2 col-lg-4 col-sm-6"></div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row justify-content-xl-end">
              <div className="col-xl-2 col-lg-4 col-sm-6">
                <a href="https://uns.ac.id/en" target="_blank">
                  <div className="card hover-shadow-lg hover-translate-y-n3 mt-xl-n10">
                    <div className="pt-4 h-100 w-50 mx-auto">
                      <img
                        alt="Sebelas Maret University"
                        src={partnerUns}
                        className="img-fluid partner-logo"
                      />
                    </div>
                    <div className="card-body pb-4 text-center">
                      <h5 className="mb-1">Sebelas Maret University</h5>
                      <p className="text-muted text-sm mb-0">
                        Surakarta, Indonésie
                      </p>
                    </div>
                  </div>
                </a>
              </div>
              <div className="col-xl-2 col-lg-4 col-sm-6">
                <a href="https://www.ecole3a.edu/" target="_blank">
                  <div className="card hover-shadow-lg hover-translate-y-n3">
                    <div className="pt-4 h-100 w-50 mx-auto">
                      <img
                        alt="3A Lyon"
                        src={partner3A}
                        className="img-fluid partner-logo"
                      />
                    </div>
                    <div className="card-body pb-4 text-center">
                      <h5 className="mb-1">3A Lyon</h5>
                      <p className="text-muted text-sm mb-0">Lyon, France</p>
                    </div>
                  </div>
                </a>
              </div>
              <div className="col-xl-2 col-lg-4 col-sm-6">
                <a href="https://uqam.ca/" target="_blank">
                  <div className="card hover-shadow-lg hover-translate-y-n3 mt-xl-n6">
                    <div className="pt-4 h-100 w-50 mx-auto">
                      <img
                        alt="UQAM"
                        src={partnerUqam}
                        className="img-fluid partner-logo"
                      />
                    </div>
                    <div className="card-body pb-4 text-center">
                      <h5 className="mb-1">UQAM</h5>
                      <p className="text-muted text-sm mb-0">
                        Montréal, Québec
                      </p>
                    </div>
                  </div>
                </a>
              </div>
              <div className="col-xl-2 col-lg-4 col-sm-6">
                <a href="https://www.esca.ma/" target="_blank">
                  <div className="card hover-shadow-lg hover-translate-y-n3">
                    <div className="pt-4 h-100 w-50 mx-auto">
                      <img
                        alt="ESCA"
                        src={partnerEsca}
                        className="img-fluid partner-logo"
                      />
                    </div>
                    <div className="card-body pb-4 text-center">
                      <h5 className="mb-1">ESCA Casablanca</h5>
                      <p className="text-muted text-sm mb-0">
                        Casablanca, Maroc
                      </p>
                    </div>
                  </div>
                </a>
              </div>
              <div className="col-xl-2 col-lg-4 col-sm-6">
                <a href="https://essec-douala.cm/" target="_blank">
                  <div className="card hover-shadow-lg hover-translate-y-n3 mt-xl-n6">
                    <div className="pt-4 h-100 w-50 mx-auto">
                      <img
                        alt="ESSEC Douala"
                        src={partnerEssec}
                        className="img-fluid partner-logo"
                      />
                    </div>
                    <div className="card-body pb-4 text-center">
                      <h5 className="mb-1">ESSEC Douala</h5>
                      <p className="text-muted text-sm mb-0">
                        Douala, Cameroun
                      </p>
                    </div>
                  </div>
                </a>
              </div>
              <div className="col-xl-2 col-lg-4 col-sm-6">
                <a href="https://ub.ac.id/" target="_blank">
                  <div className="card hover-shadow-lg hover-translate-y-n3 mt-xl-n10">
                    <div className="pt-4 h-100 w-50 mx-auto">
                      <img
                        alt="Université de Brawijaya"
                        src={partnerBrawijaya}
                        className="img-fluid partner-logo"
                      />
                    </div>
                    <div className="card-body pb-4 text-center">
                      <h5 className="mb-1">Université de Brawijaya</h5>
                      <p className="text-muted text-sm mb-0">
                        Brawijaya, Indonésie
                      </p>
                    </div>
                  </div>
                </a>
              </div>

              <div className="col-xl-2 col-lg-4 col-sm-6"></div>
            </div>
          </div>
          <div className="shape-container shape-line shape-position-bottom">
            <svg
              width="2560px"
              height="100px"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              preserveAspectRatio="none"
              x="0px"
              y="0px"
              viewBox="0 0 2560 100"
              style={{ enableBackground: "new 0 0 2560 100" }}
              xmlSpace="preserve"
              className=""
            >
              <polygon points="2560 0 2560 100 0 100"></polygon>
            </svg>
          </div>
        </section>
        <section id="faq" className="slice slice-lg">
          <div className="container">
            <div className="row mb-5 justify-content-center text-center">
              <div className="col-lg-8 col-md-10">
                <h2 className=" mt-4">Questions & Réponses Fréquentes</h2>
                <div className="mt-2">
                  <p className="lead lh-180">
                    Consultez notre FAQ pour obtenir les réponses à vos
                    questions.
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-6">
                <div id="accordion-1" className="accordion accordion-spaced">
                  <div className="card">
                    <div
                      className="card-header py-4"
                      id="heading-1-1"
                      data-toggle="collapse"
                      role="button"
                      data-target="#collapse-1-1"
                      aria-expanded="false"
                      aria-controls="collapse-1-1"
                    >
                      <h6 className="mb-0">
                        <Icon.Compass size="1em" className="mr-3" />
                        Quelles sont les parcours proposées à l'ISCAM ?
                      </h6>
                    </div>
                    <div
                      id="collapse-1-1"
                      className="collapse"
                      aria-labelledby="heading-1-1"
                      data-parent="#accordion-1"
                    >
                      <div className="card-body">
                        <p className="text-justify">
                          Nous proposons plusieurs spécialisations en Formation
                          Initiale (FI) et Formation Continue Diplômante (FCD).
                          La FI concerne les bacheliers souhaitant poursuivre
                          leurs études en Licence ou en Master. Le parcours FCD
                          concerne les professionnels souhaitant développer
                          leurs capacités et évoluer dans leur domaine
                          d’expertise.
                          <br />
                          Pour plus d’informations, veuillez consulter{" "}
                          <a href="http://www.iscam.mg/programmes">
                            la page explicative sur le site de l'ISCAM.
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div
                      className="card-header py-4"
                      id="heading-1-2"
                      data-toggle="collapse"
                      role="button"
                      data-target="#collapse-1-2"
                      aria-expanded="false"
                      aria-controls="collapse-1-2"
                    >
                      <h6 className="mb-0">
                        <Icon.Key size="1em" className="mr-3" />
                        Quelle est la procédure d’inscription au concours ?
                      </h6>
                    </div>
                    <div
                      id="collapse-1-2"
                      className="collapse"
                      aria-labelledby="heading-1-2"
                      data-parent="#accordion-1"
                    >
                      <div className="card-body">
                        <p className="text-justify">
                          L’inscription au concours se fait sur le site
                          inscription.iscam.mg. Vous y renseignerez les
                          informations et documents demandés. Plusieurs sessions
                          de concours seront organisées afin de recruter les
                          nouveaux entrants.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div
                      className="card-header py-4"
                      id="heading-1-3"
                      data-toggle="collapse"
                      role="button"
                      data-target="#collapse-1-3"
                      aria-expanded="false"
                      aria-controls="collapse-1-3"
                    >
                      <h6 className="mb-0">
                        <Icon.Folder size="1em" className="mr-3" />
                        Quels sont les documents requis à l’inscription au
                        concours ?
                      </h6>
                    </div>
                    <div
                      id="collapse-1-3"
                      className="collapse"
                      aria-labelledby="heading-1-3"
                      data-parent="#accordion-1"
                    >
                      <div className="card-body">
                        <p className="text-justify">
                          Les documents qui constituent un dossier de
                          candidature diffèrent du niveau souhaité Veuillez les
                          consulter <a href="#required-documents">ici</a>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div
                      className="card-header py-4"
                      id="heading-1-4"
                      data-toggle="collapse"
                      role="button"
                      data-target="#collapse-1-4"
                      aria-expanded="false"
                      aria-controls="collapse-1-4"
                    >
                      <h6 className="mb-0">
                        <Icon.HelpCircle size="1em" className="mr-3" />
                        Comment se déroule le concours ?
                      </h6>
                    </div>
                    <div
                      id="collapse-1-4"
                      className="collapse"
                      aria-labelledby="heading-1-4"
                      data-parent="#accordion-2"
                    >
                      <div className="card-body">
                        <p className="text-justify">
                          Le concours se déroulera en trois étapes distinctes,
                          la réussite de chaque épreuve sera déterminante pour
                          l’admission à l’ISCAM. Vous serez évalué sur
                          l’excellence de votre dossier académique. Le dossier
                          concluant, vous passerez une épreuve écrite et un
                          entretien oral.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-6">
                <div id="accordion-2" className="accordion accordion-spaced">
                  <div className="card">
                    <div
                      className="card-header py-4"
                      id="heading-2-1"
                      data-toggle="collapse"
                      role="button"
                      data-target="#collapse-2-1"
                      aria-expanded="false"
                      aria-controls="collapse-2-1"
                    >
                      <h6 className="mb-0">
                        <Icon.Calendar size="1em" className="mr-3" />
                        Quelles sont les prochaines dates de concours ?
                      </h6>
                    </div>
                    <div
                      id="collapse-2-1"
                      className="collapse"
                      aria-labelledby="heading-2-1"
                      data-parent="#accordion-1"
                    >
                      <div className="card-body">
                        <p className="text-justify">
                          Les inscriptions au concours sont ouvertes pour les
                          personnes souhaitant intégrer l'ISCAM en Formation
                          Initiale (L1, L2, M1, M2 MAPI) et en Formation
                          Continue Diplômante (DES, DESA, DEPA). Les concours se
                          dérouleront en plusieurs sessions : tous les jeudis et
                          vendredis à partir du mois de juin.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div
                      className="card-header py-4"
                      id="heading-2-2"
                      data-toggle="collapse"
                      role="button"
                      data-target="#collapse-2-2"
                      aria-expanded="false"
                      aria-controls="collapse-2-2"
                    >
                      <h6 className="mb-0">
                        <Icon.DollarSign size="1em" className="mr-3" />
                        Proposez-vous des bourses d’études, comment les obtenir
                        ?
                      </h6>
                    </div>
                    <div
                      id="collapse-2-2"
                      className="collapse"
                      aria-labelledby="heading-2-2"
                      data-parent="#accordion-2"
                    >
                      <div className="card-body">
                        <p className="text-justify">
                          L’ISCAM propose deux bourses : la bourse d’études pour
                          les étudiants de la L1 jusqu’à la L3 et une bourse
                          d’excellence de la L3 à la M2. Ces dernières sont
                          considérées selon l’excellence du dossier scolaire,
                          les caractéristiques sociales et l’implication dans la
                          vie de l’établissement.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div
                      className="card-header py-4"
                      id="heading-2-3"
                      data-toggle="collapse"
                      role="button"
                      data-target="#collapse-2-3"
                      aria-expanded="false"
                      aria-controls="collapse-2-3"
                    >
                      <h6 className="mb-0">
                        <Icon.Send size="1em" className="mr-3" />
                        Je souhaite partir en échange
                      </h6>
                    </div>
                    <div
                      id="collapse-2-3"
                      className="collapse"
                      aria-labelledby="heading-2-3"
                      data-parent="#accordion-1"
                    >
                      <div className="card-body">
                        <p className="text-justify">
                          Tous les étudiants de l’ISCAM peuvent bénéficier du
                          programme d’échange à l’étranger. Ce dernier est
                          accessible à partir de la 3ème année de Licence
                          jusqu’à la dernière année de Master durant la période
                          de Janvier à Juin pour une durée de 6 mois. Pour
                          consulter la liste de nos partenaires universitaires,{" "}
                          <a href="#partnerships">
                            veuillez consulter la partie partenaires
                          </a>
                          .
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div
                      className="card-header py-4"
                      id="heading-2-4"
                      data-toggle="collapse"
                      role="button"
                      data-target="#collapse-2-4"
                      aria-expanded="false"
                      aria-controls="collapse-2-4"
                    >
                      <h6 className="mb-0">
                        <Icon.Briefcase size="1em" className="mr-3" />
                        Avez-vous des formations dédiées aux professionnels ?
                      </h6>
                    </div>
                    <div
                      id="collapse-2-4"
                      className="collapse"
                      aria-labelledby="heading-2-4"
                      data-parent="#accordion-2"
                    >
                      <div className="card-body">
                        <p className="text-justify">
                          Nous proposons des formations pour les professionnels
                          en cours du soir. Notre parcours de Formation Continue
                          Diplômante concerne les personnes souhaitant
                          développer leurs performances en entreprise et évoluer
                          dans leur domaine d’expertise.
                          <br />
                          Pour plus d’informations concernant la FCD veuillez
                          nous contacter sur{" "}
                          <a href="mailto:information@iscam.mg">
                            information@iscam.mg
                          </a>
                          .
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Landing;
