import React, { Component } from "react";
import * as Icon from "react-feather";

import fastCuriousLogo from "../assets/img/interviews/fast-curious-logo.png";

class Interview extends Component {
  render() {
    const {
      id,
      imagePortrait,
      data: {
        name,
        title,
        shortTitle,
        interviewVideoLink,
        shortDescription,
        quote,
        articles,
      },
    } = this.props;

    return (
      <div>
        <section class="section-half-rounded interview">
          <div class="jumbotron section-inner left-0 rounded-right bg-primary overflow-hidden col-lg-5">
            <img src={imagePortrait} alt={id} class="img-as-bg" />
          </div>
          <div class="container text-center text-lg-left py-5">
            <div class="row">
              <div class="col-lg-7 ml-auto">
                <div class="card bg-dark shadow-lg border-0 mb-0">
                  <div class="px-5 py-6">
                    <span class="badge badge-primary badge-pill">
                      Témoignage
                    </span>
                    <h2 class="h2 lh-180 text-white font-weight-bold mt-3 mb-0">
                      {name}
                    </h2>
                    <p class="h6 text-white mb-0">{title}</p>
                  </div>
                </div>
                <a
                  href={interviewVideoLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  class="fast-curious-preview"
                >
                  <img src={fastCuriousLogo} alt="interview cover" />
                  <div class="fast-curious-overlay">
                    <Icon.Youtube size="4em" />
                    <br />
                    <span>Voir la vidéo</span>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </section>
        <section class="slice interview">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-lg-9">
                <p class="lh-190 text-justify">{shortDescription}</p>
                <blockquote class="blockquote blockquote-card my-5 py-5 px-5 rounded-right bg-soft-primary">
                  <p class="lead">{quote}</p>
                  <footer class="blockquote-footer">
                    {shortTitle},{" "}
                    <cite class="font-weight-600" title="Rindra Razafindrazaka">
                      {name}
                    </cite>
                  </footer>
                </blockquote>
                <article>
                  {articles.map(({ title, contents }, index) => (
                    <React.Fragment key={`article-${index}`}>
                      <h5 className="h4">{title}</h5>
                      <p>
                        {contents.map((content, contentIndex, array) => (
                          <React.Fragment
                            key={`article-${index}-content-${contentIndex}`}
                          >
                            {content}
                            {contentIndex < array.length - 1 && (
                              <>
                                <br />
                                <br />
                              </>
                            )}
                          </React.Fragment>
                        ))}
                      </p>
                    </React.Fragment>
                  ))}
                </article>
                <br />
                <a href="/interviews" class="btn btn-xl btn-warning center">
                  <Icon.MousePointer size="1em" />
                  &nbsp;&nbsp;Lire d'autres témoignages
                </a>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Interview;
