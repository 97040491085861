import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import imgError from "../assets/img/backgrounds/oups-error.jpg";

class Error404 extends Component {
  render() {
    return (
      <div className="d-flex flex-column align-items-center">
        <img src={imgError} alt="0" style={{ width: "30vw" }} />
        <button
          className="btn btn-primary"
          style={{ width: "20vw" }}
          onClick={() => {
            this.props.history.push("/");
          }}
        >
          Aller à la page d'accueil
        </button>
      </div>
    );
  }
}

export default withRouter(Error404);
