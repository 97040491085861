import React from "react";
import Select from "react-select";

const SelectComponent = ({
  onChange,
  value,
  placeholder = "",
  options = [],
  hasError = false,
}) => {
  const findValue = () => {
    const [selection] = options.filter((option) => option.value === value);

    return selection;
  };

  return (
    <div
      style={{
        border: "solid 1px #cccccc",
        borderRadius: 6,
        ...(hasError ? { borderColor: "red" } : {}),
      }}
    >
      <Select
        options={options}
        onChange={(selected) => onChange(selected.value)}
        value={findValue()}
        placeholder={placeholder}
        styles={{
          control: (baseStyles) => ({
            ...baseStyles,
            border: 0,
          }),
        }}
      />
    </div>
  );
};

export default SelectComponent;
