import { unsecured as axios } from "../../../axios";

const emailIsUniq = async (email) => {
  return axios
    .post("/email-is-uniq", { email })
    .then(() => true)
    .catch(() => false);
};

export { emailIsUniq };
