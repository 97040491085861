import React, { Component } from "react";
import * as Icon from "react-feather";
import _ from "lodash";
import Api from "../../Api";
import InputFile from "../../components/InputFile";

class Step6_6 extends Component {
  state = {
    document: {
      type: 270,
      document: "",
      file_name: "",
      mime_type: "",
    },
    loading: false,
    errors: {},
  };

  constructor(props) {
    super(props);

    this.onSubmit = this.onSubmit.bind(this);
    this.onFileChange = this.onFileChange.bind(this);
  }

  onFileChange(event) {
    event.persist();

    var reader = new FileReader();
    let b64 = "";

    reader.onload = () => {
      b64 = window.btoa(reader.result);
      const doc = this.state.document;
      doc.document = b64;
      doc.file_name = event.target.files[0].name;
      doc.mime_type = event.target.files[0].type;
      this.setState({});
    };
    reader.readAsBinaryString(event.target.files[0]);
  }

  onSubmit(event) {
    event.preventDefault();

    const { document } = this.state;

    this.setState({ loading: true });
    Api.post("/register/review/step-rule", document)
      .then(() => {
        this.props.history.push("/register/review/7");
      })
      .catch(
        ({
          response: {
            data: { 0: errors },
          },
        }) => {
          this.setState({ errors, loading: false });
        }
      );
  }

  renderError(e) {
    if (this.hasError(e)) {
      return (
        <div class="text-danger">
          <Icon.XOctagon size="1em" />
          &nbsp;{this.state.errors[e]}
        </div>
      );
    }
  }

  hasError(e) {
    if (!_.isEmpty(this.state.errors)) {
      var keys = Object.keys(this.state.errors);
      return keys.includes(e);
    }
  }

  render() {
    return (
      <div class="register-right-pane container-fluid d-flex flex-column">
        <div class="row align-items-center justify-content-center justify-content-lg-end min-vh-100">
          <div class="scrollable-content col-sm-7 col-lg-6 col-xl-6 py-6 py-lg-0 position-absolute h-100 top-0 right-0">
            <div class="row justify-content-center">
              <div class="col-11 col-lg-10 col-xl-10">
                <div className="mt-5">
                  <h6 class="h3 mb-1">RÈGLEMENTS INTERIEURS</h6>
                  <p>
                    Vos documents d’inscription ont été validés. <br /> <br />
                    Merci de lire attentivement et de signer le règlement
                    intérieur de l’institut.
                  </p>

                  <button
                    className="btn btn-warning btn-block"
                    onClick={() =>
                      Api.get("/download/rule-house", {
                        responseType: "blob",
                      }).then((response) => {
                        const url = window.URL.createObjectURL(
                          new Blob([response.data])
                        );
                        const link = document.createElement("a");
                        link.href = url;
                        link.setAttribute("download", "house_rule.pdf");
                        document.body.appendChild(link);
                        link.click();
                      })
                    }
                  >
                    <Icon.Download size="1em" />
                    Télécharger les règlements intérieurs
                  </button>

                  <div className="mt-4">
                    <div className="form-group">
                      <label className="form-control-label">
                        Merci de bien vouloir imprimer et signer la dernière
                        page du règlement intérieur et de l’uploader ici.
                      </label>
                      <div className="input-group">
                        <InputFile
                          name="house_rule"
                          fileName={this.state.document.file_name}
                          onChange={this.onFileChange}
                          accept=".pdf"
                        />
                      </div>
                      {this.renderError("data.document")}
                    </div>

                    <button
                      className="btn btn-primary btn-block"
                      onClick={!this.state.loading && this.onSubmit}
                    >
                      {this.state.loading ? (
                        <span
                          class="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      ) : (
                        "Valider"
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Step6_6;
