import { unsecured as axios } from "../../../axios";

class StepSave {
  static async saveIdentity(data) {
    return await axios
      .post("/inscription/identity", data)
      .then((res) => res.data.applicantId);
  }
  static async saveSchooling(data, applicantId) {
    return await axios.post("/inscription/schooling", data, {
      params: {
        applicantId,
      },
    });
  }
}

export { StepSave };
