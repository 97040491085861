import React, { Component } from "react";

import logo from "../assets/img/brand/iscam-logo.png";
import * as Icon from "react-feather";
import $ from "jquery";
import bootstrap from "bootstrap";

class Header extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    $('[data-toggle="tooltip"]').tooltip();
  }

  render() {
    return (
      <header id="header-main">
        <nav
          class="navbar navbar-main navbar-expand-lg navbar-dark bg-dark"
          id="navbar-main"
        >
          <div class="container">
            <a class="navbar-brand" href="https://iscam.mg">
              <img alt="Logo ISCAM" src={logo} id="navbar-logo" />
            </a>
            <button
              class="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbar-main-collapse"
              aria-controls="navbar-main-collapse"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class="navbar-toggler-icon"></span>
            </button>
            <div
              class="collapse navbar-collapse navbar-collapse-overlay"
              id="navbar-main-collapse"
            >
              <div class="position-relative">
                <button
                  class="navbar-toggler"
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbar-main-collapse"
                  aria-controls="navbar-main-collapse"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <Icon.X size="1em" />
                </button>
              </div>
              <ul class="navbar-nav ml-lg-auto">
                <li class="nav-item nav-item-spaced d-lg-block">
                  <a class="nav-link" href="/">
                    Accueil
                  </a>
                </li>
                <li class="nav-item nav-item-spaced d-lg-block">
                  <a class="nav-link" href="/interviews">
                    Témoignages
                  </a>
                </li>
                <li class="nav-item nav-item-spaced d-lg-block">
                  <a class="nav-link" href="/#cursus">
                    Cursus
                  </a>
                </li>
                <li class="nav-item nav-item-spaced d-lg-block">
                  <a class="nav-link" href="/#partnerships">
                    Partenaires
                  </a>
                </li>
                <li class="nav-item nav-item-spaced d-lg-block">
                  <a class="nav-link" href="/#faq">
                    Questions fréquentes
                  </a>
                </li>
              </ul>
              <ul class="navbar-nav align-items-lg-center d-none d-lg-flex ml-lg-auto">
                <li class="nav-item" style={{ display: "none" }}>
                  <a
                    class="nav-link"
                    href="../../pages/authentication/login-basic.html"
                  >
                    Se connecter
                  </a>
                </li>
                <li class="nav-item">
                  <div data-toggle="tooltip" data-placement="bottom" title="">
                    <a
                      class="btn btn-sm btn-white btn-icon ml-3"
                      onClick={() => {
                        this.props.history.push("/inscription");
                      }}
                    >
                      <span class="btn-inner--icon">
                        <Icon.CornerDownRight size="1em" />
                      </span>
                      <span class="btn-inner--text">Inscription</span>
                    </a>
                  </div>
                </li>
              </ul>
              <div
                class="d-lg-none px-4 text-center"
                data-toggle="tooltip"
                data-placement="top"
                title=""
              >
                <button
                  class="btn btn-block btn-sm btn-primary"
                  onClick={() => {
                    this.props.history.push("/register/1");
                  }}
                >
                  S'inscrire
                </button>
              </div>
            </div>
          </div>
        </nav>
      </header>
    );
  }
}

export default Header;
