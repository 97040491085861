import React, { Component } from "react";
import { Redirect } from "react-router";
import { withRouter } from "react-router-dom";
import * as Icon from "react-feather";
import $ from "jquery";
import _ from "lodash";
import API from "../Api";

import {
  Person,
  EditPerson,
  PersonForm,
  Loading,
} from "../components/Components";

class PersonsOfInterest extends Component {
  constructor(props) {
    super(props);

    if (this.props.token) {
      const { pathname } = this.props.location;
      const basePath = pathname.split("/");
      var x_auth_token = "";
      if (basePath[1] === "old") {
        x_auth_token = "X-AUTH-TOKEN_old";
      } else {
        x_auth_token = "X-AUTH-TOKEN_new";
      }
      API.defaults.headers.common["X-AUTH-TOKEN"] = this.props.token;
      localStorage.setItem(x_auth_token, JSON.stringify(this.props.token));
    }

    this.state = {
      loadingFetch: false,
      pois: [],
      poiOptions: [
        { id: 20, value: "Père" },
        { id: 30, value: "Mère" },
        { id: 40, value: "Tuteur" },
      ],
      poiEdit: false,
      redirect: "",
    };

    this.loadPoiOption = this.loadPoiOption.bind(this);
    this.addPoi = this.addPoi.bind(this);
    this.renderPersonsOfInterest = this.renderPersonsOfInterest.bind(this);
    this.renderEditPerson = this.renderEditPerson.bind(this);
    this.cancelAddPoi = this.cancelAddPoi.bind(this);
    this.deletePoiOptions = this.deletePoiOptions.bind(this);
    this.deletePoi = this.deletePoi.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.getData = this.getData.bind(this);
  }

  componentDidMount() {
    const { pathname } = this.props.location;
    const basePath = pathname.split("/");
    var x_auth_token = "";
    if (basePath[1] === "old") {
      x_auth_token = "X-AUTH-TOKEN_old";
    } else {
      x_auth_token = "X-AUTH-TOKEN_new";
    }

    if (_.has(localStorage, x_auth_token)) {
      API.defaults.headers.common["X-AUTH-TOKEN"] = JSON.parse(
        localStorage.getItem(x_auth_token)
      );
      this.loadPoiOption();
      this.getData();
    }
  }

  getData() {
    const { pathname } = this.props.location;
    const basePath = pathname.split("/");
    const step = Number(basePath.pop());
    var url = `register/step-${step}`;

    this.setState({ loadingFetch: true });
    API.get(url).then(
      function (response) {
        this.setState({ loadingFetch: false });
        var pois = [];

        if (Array.isArray(response.data["0"])) {
          response.data["0"].forEach(function (value) {
            pois.push({
              id: value.id,
              type: String(value.type),
              first_name: value.firstname,
              last_name: value.lastname,
              usage_name: value.usage_name,
              phone: value.phone,
              primary_email: value.primary_email,
              job_type: value.job_type.id,
              job_title: value.job_title.id,
              job_details: value.job_details,
              company: value.company,
              company_activity: value.company_activity.id,
              company_phone: value.company_phone,
              company_address: value.company_address,
            });
          });

          this.setState({ pois: pois });
        }
      }.bind(this)
    );
  }

  loadPoiOption() {
    var poiOptions = this.state.poiOptions;
    var newPoiOptions = [];
    for (var i = 0; i < 3; i++) {
      if (
        _.has(localStorage, `Poi_${i}`) &&
        !_.isEmpty(JSON.parse(localStorage.getItem(`Poi_${i}`)))
      ) {
        var poi = JSON.parse(localStorage.getItem(`Poi_${i}`));
        if (poi.type == 20) {
          [...newPoiOptions] = poiOptions.filter(
            (poiOption) => poiOption.id !== 20
          );
        }
        if (poi.type == 30) {
          [...newPoiOptions] = poiOptions.filter(
            (poiOption) => poiOption.id !== 30
          );
        }
        if (poi.type == 40) {
          [...newPoiOptions] = poiOptions.filter(
            (poiOption) => poiOption.id !== 40
          );
        }
      }
      poiOptions = newPoiOptions;
    }
    this.setState({ test: "2" });
  }

  deletePoiOptions(poiType) {
    var poiOptions = this.state.poiOptions;
    var newPoiOptions = poiOptions.filter((poi) => poi.id !== poiType);

    this.setState({ poiOptions: newPoiOptions });
  }

  addPoi() {
    this.setState({ poiEdit: true });
  }

  cancelAddPoi() {
    this.setState({ poiEdit: false });
  }

  savePoi(poi) {
    var pois = this.state.pois;
    pois.push(poi);
    this.setState({ pois: pois });
  }

  deletePoi(index) {
    API.delete("/person/" + this.state.pois[index].id).then(
      function (response) {
        var state = this.state.pois;
        state.splice(index, 1);
        this.setState({ pois: state });
      }.bind(this)
    );
  }

  renderEditPerson() {
    if (this.state.poiEdit) {
      return <PersonForm collection={this} />;
    } else {
      return null;
    }
  }

  renderPersonsOfInterest() {
    if (this.state.pois.length < 1 && this.state.poiEdit == false) {
      return (
        <div class="empty-poi">
          Vous devez ajouter au moins un parent ou tuteur pour pouvoir
          continuer.
        </div>
      );
    }

    var pois = [];

    this.state.pois.forEach(function (item, index) {
      pois.push(
        <Person
          reviewStep={this.props.reviewStep}
          modificationEnabled={this.state.modificationEnabled}
          collection={this}
          model={item}
          key={index}
          index={index}
        />
      );
    }, this);

    return pois;
  }

  onSubmit() {
    if (this.state.pois.length) {
      this.props.collection.props.registerStep.setState({
        step2: this.state.pois,
      });
      const { pathname } = this.props.location;
      const basePath = pathname.split("/");
      const step = Number(basePath.pop());
      this.setState({ redirect: `${basePath.join("/")}/${step + 1}` });
    }
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }

    return (
      <div class="register-right-pane container-fluid d-flex flex-column">
        <div class="row align-items-center justify-content-center justify-content-lg-end min-vh-100">
          <div class="scrollable-content col-sm-7 col-lg-6 col-xl-6 py-6 py-lg-0 position-absolute h-100 top-0 right-0">
            <div class="row justify-content-center">
              <div class="col-11 col-lg-10 col-xl-10">
                <div>
                  {this.props.reviewStep ? (
                    <div class="mt-5 mb-3">
                      <h6 class="h3 mb-1">
                        MISE A JOUR ET VÉRIFICATION DE VOS DONNÉES PERSONNELLES
                      </h6>
                      <p>
                        Merci de bien vouloir re-vérifier l'exactitude de vos
                        données personnelles.
                      </p>
                    </div>
                  ) : (
                    <div class="mt-5 mb-5">
                      {this.state.loadingFetch && <Loading />}
                      <h6 class="h3 mb-1">Inscription Concours ISCAM</h6>
                      <p class="text-muted mb-0">Informations de filiation</p>
                    </div>
                  )}
                  {this.props.reviewStep && (
                    <button
                      class="btn btn-md btn-primary float-right mb-3"
                      onClick={() =>
                        this.setState({ modificationEnabled: true })
                      }
                    >
                      Modifier
                    </button>
                  )}
                  <div class="clearfix"></div>
                  <div class="poi-container">
                    {this.state.poiEdit ? null : (
                      <div>
                        <h5
                          class={"h5".concat(
                            this.state.pois.length == "0" ? " disabled" : ""
                          )}
                        >
                          Parents ajoutés
                        </h5>
                        {this.renderPersonsOfInterest()}
                      </div>
                    )}
                    {this.renderEditPerson()}
                  </div>
                  <div
                    class={"poi-add text-right mt-5".concat(
                      this.state.poiEdit ? " disabled" : ""
                    )}
                  >
                    <button
                      class={"btn btn-block btn-primary ".concat(
                        this.state.pois.length === 3 ? "disabled" : ""
                      )}
                      onClick={this.addPoi}
                      disabled={
                        this.props.reviewStep && !this.state.modificationEnabled
                      }
                    >
                      <Icon.UserPlus size="1em" />
                      &nbsp;Ajouter un parent
                    </button>
                  </div>
                  <div
                    class={"mt-4 mb-4 ".concat(
                      this.state.poiEdit ? "disabled" : ""
                    )}
                  >
                    {this.state.pois.length ? (
                      <button
                        type="button"
                        onClick={this.onSubmit}
                        class="btn btn-block btn-primary"
                      >
                        Valider
                      </button>
                    ) : (
                      <button
                        type="button"
                        class="btn btn-block btn-primary"
                        disabled
                      >
                        Valider
                      </button>
                    )}
                  </div>
                  <p>
                    Si vous souhaitez poursuivre votre inscription plus tard,
                    merci d’accéder à votre dossier à travers le mail que vous
                    avez reçu de{" "}
                    <a href="mailto:information@iscam.mg">
                      information@iscam.mg
                    </a>{" "}
                    en validant cette étape.
                    <br />
                    Merci de vérifier dans le Spam si vous ne voyez pas le mail
                    dans votre boite de réception.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(PersonsOfInterest);
