import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import * as Icon from "react-feather";
import _ from "lodash";
import API from "../Api.js";

class PersonForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      errors: "",
      loading: false,
      jobTypeOptions: [],
      jobTitleOptions: [],
      companyActivityOptions: [],
      data: {
        type: "",
        last_name: "",
        first_name: "",
        usage_name: "",
        phone: "",
        primary_email: "",
        company: "",
        company_activity: "",
        company_phone: "",
        company_address: "",
        job_details: "",
        job_type: "",
        job_title: "",
      },
    };

    this.componentRef = {
      type: null,
      last_name: null,
      first_name: null,
      usage_name: null,
      phone: null,
      primary_email: null,
      job_title: null,
      job_type: null,
      job_details: null,
      company: null,
      company_activity: null,
      company_phone: null,
      company_address: null,
    };

    this.findElementToScroll = this.findElementToScroll.bind(this);
    this.populateJobTypes = this.populateJobTypes.bind(this);
    this.populateJobTitles = this.populateJobTitles.bind(this);
    this.populateCompanyActivities = this.populateCompanyActivities.bind(this);
    this.onChange = this.onChange.bind(this);
    this.submitParent = this.submitParent.bind(this);

    this.populateJobTypes();
    this.populateJobTitles();
    this.populateCompanyActivities();
  }

  renderError(e) {
    if (this.hasError(e)) {
      return (
        <div class="text-danger">
          <Icon.XOctagon size="1em" />
          &nbsp;{this.state.errors[e]}
        </div>
      );
    }
  }

  hasError(e) {
    var keys = Object.keys(this.state.errors);
    return keys.includes(e);
  }

  findElementToScroll(errors) {
    for (var element in this.componentRef) {
      if (errors.includes(element)) {
        this.scrollToNode(this.componentRef[element]);
        break;
      }
    }
  }

  getNameErrors(errors) {
    var keyNames = [];
    var keys = Object.keys(errors);
    keys.forEach((key) => {
      var name = key.split(".");
      keyNames.push(name[name.length - 1]);
    });
    return keyNames;
  }

  scrollToNode(node) {
    node.scrollIntoView({ block: "start", behavior: "smooth" });
  }

  populateJobTypes() {
    API.get("/list/job_types").then(
      function (response) {
        var jobTypeOptions = [];

        if (Array.isArray(response.data)) {
          response.data.forEach(function (value, index) {
            jobTypeOptions.push(
              <option value={value.id}> {value.name} </option>
            );
          });
        }

        this.setState({
          jobTypeOptions: jobTypeOptions,
        });
      }.bind(this)
    );
  }

  populateJobTitles() {
    API.get("/list/job_titles").then(
      function (response) {
        var jobTitleOptions = [];

        if (Array.isArray(response.data)) {
          response.data.forEach(function (value, index) {
            jobTitleOptions.push(
              <option value={value.id}> {value.name} </option>
            );
          });
        }

        this.setState({
          jobTitleOptions: jobTitleOptions,
        });
      }.bind(this)
    );
  }

  populateCompanyActivities() {
    API.get("/list/company_activities").then(
      function (response) {
        var companyActivityOptions = [];

        if (Array.isArray(response.data)) {
          response.data.forEach(function (value, index) {
            companyActivityOptions.push(
              <option value={value.id}> {value.name} </option>
            );
          });
        }

        this.setState({
          companyActivityOptions: companyActivityOptions,
        });
      }.bind(this)
    );
  }

  onChange(event) {
    var data = this.state.data;
    data[event.target.name] = event.target.value;
    this.setState({ data: data });

    if (event.target.name === "job_title") {
      if (
        Number(event.target.value) === 7 ||
        Number(event.target.value) === 8 ||
        Number(event.target.value) === 9
      ) {
        var data = this.state.data;
        data.company = "Néant";
        data.job_details = "Néant";
        this.setState({
          data: data,
        });
      } else {
        var data = this.state.data;
        data.company = "";
        data.job_details = "";
        this.setState({
          data: data,
        });
      }
    }
  }

  submitParent() {
    this.setState({ loading: true });

    const { pathname } = this.props.location;
    const basePath = pathname.split("/");
    const step = Number(basePath.pop());
    var url = "";
    url = `${basePath.join("/")}/step-${step}`;

    API.post(url, this.state.data)
      .then(
        function (response) {
          this.setState({ loading: false });
          var pois = this.props.collection.state.pois;
          var data = this.state.data;
          data.id = response.data[0];
          pois.push(data);
          this.props.collection.deletePoiOptions(data.type);
          this.props.collection.setState({ pois: pois });
          this.props.collection.cancelAddPoi();
        }.bind(this)
      )
      .catch(
        function (error) {
          this.setState({ errors: error.response.data["0"], loading: false });
          var errors = this.getNameErrors(error.response.data[0]);
          this.findElementToScroll(errors);
        }.bind(this)
      );
  }

  render() {
    return (
      <div class="poi-edit mb-3">
        <h5 class="h5 mt-5">
          Nouveau parent
          <button
            class="btn btn-xs btn-warning float-right"
            onClick={this.props.collection.cancelAddPoi}
          >
            <Icon.X size="1em" /> &nbsp; Annuler
          </button>
        </h5>
        <div ref={(node) => (this.componentRef.type = node)} class="form-group">
          <label class="form-control-label"> Relation* </label>
          <div class="input-group">
            <select
              name="type"
              class="form-control"
              value={this.state.data.type}
              onChange={this.onChange}
            >
              <option value="" disabled selected>
                Veuillez choisir
              </option>
              {this.props.collection.state.poiOptions.map((parent) => (
                <option value={parent.id}> {parent.value} </option>
              ))}
            </select>
          </div>
          {this.renderError("data.type")}
        </div>
        <div
          ref={(node) => (this.componentRef.last_name = node)}
          class="form-group"
        >
          <label class="form-control-label"> Nom* </label>
          <div class="input-group">
            <input
              name="last_name"
              className="form-control"
              value={this.state.data.last_name}
              onChange={this.onChange}
              placeholder="ex. Rajaonera"
            />
          </div>
          {this.renderError("data.last_name")}
        </div>
        <div
          ref={(node) => (this.componentRef.first_name = node)}
          class="form-group"
        >
          <label class="form-control-label"> Prénom(s)* </label>
          <div class="input-group">
            <input
              name="first_name"
              className="form-control"
              value={this.state.data.first_name}
              onChange={this.onChange}
              placeholder="ex. Hery Christophe"
            />
          </div>
          {this.renderError("data.first_name")}
        </div>
        <div
          ref={(node) => (this.componentRef.usage_name = node)}
          class="form-group"
        >
          <label class="form-control-label"> Prénom d'usage* </label>
          <div class="input-group">
            <input
              name="usage_name"
              className="form-control"
              value={this.state.data.usage_name}
              onChange={this.onChange}
              placeholder="ex. Hery"
            />
          </div>
          {this.renderError("data.usage_name")}
        </div>
        <div
          ref={(node) => (this.componentRef.phone = node)}
          class="form-group"
        >
          <label class="form-control-label"> Téléphone* </label>
          <div class="input-group">
            <input
              name="phone"
              className="form-control"
              value={this.state.data.phone}
              onChange={this.onChange}
              placeholder="ex. +261 34 05 768 94"
            />
          </div>
          {this.renderError("data.phone")}
        </div>
        <div
          ref={(node) => (this.componentRef.primary_email = node)}
          class="form-group"
        >
          <label class="form-control-label"> E-mail* </label>
          <div class="input-group">
            <input
              name="primary_email"
              className="form-control"
              value={this.state.data.primary_email}
              onChange={this.onChange}
              placeholder="ex. hery@exemple.com"
            />
          </div>
          {this.renderError("data.primary_email")}
        </div>
        <div
          ref={(node) => (this.componentRef.job_title = node)}
          class="form-group"
        >
          <label class="form-control-label">Situation professionnelle*</label>
          <div class="input-group">
            <select
              name="job_title"
              as="select"
              className="form-control"
              value={this.state.data.job_title}
              onChange={this.onChange}
            >
              <option value="" disabled selected>
                Veuillez choisir
              </option>
              {this.state.jobTitleOptions}
            </select>
          </div>
          {this.renderError("data.job_title")}
        </div>
        <div
          ref={(node) => (this.componentRef.job_type = node)}
          class="form-group"
        >
          <label class="form-control-label">Poste dans l'organisation*</label>
          <div class="input-group">
            <select
              name="job_type"
              as="select"
              className="form-control"
              value={this.state.data.job_type}
              onChange={this.onChange}
              disabled={Number(this.state.data.job_title) >= 7}
            >
              {Number(this.state.data.job_title) < 7 ? (
                <option value="" disabled selected>
                  Veuillez choisir
                </option>
              ) : (
                <option>Néant</option>
              )}
              {this.state.jobTypeOptions}
            </select>
          </div>
          {this.renderError("data.job_type")}
        </div>
        <div
          ref={(node) => (this.componentRef.job_details = node)}
          class="form-group"
        >
          <label class="form-control-label">
            Fonction dans l'organisation*
          </label>
          <div class="input-group">
            <input
              name="job_details"
              className="form-control"
              value={this.state.data.job_details}
              onChange={this.onChange}
              placeholder="ex. Directeur des ventes"
              disabled={Number(this.state.data.job_title) >= 7}
            />
          </div>
          {this.renderError("data.job_details")}
        </div>
        <div
          ref={(node) => (this.componentRef.company = node)}
          class="form-group"
        >
          <label class="form-control-label"> Société* </label>
          <div class="input-group">
            <input
              name="company"
              className="form-control"
              value={this.state.data.company}
              onChange={this.onChange}
              placeholder="ex. STAR"
              disabled={Number(this.state.data.job_title) >= 7}
            />
          </div>
          {this.renderError("data.company")}
        </div>
        <div
          ref={(node) => (this.componentRef.company_activity = node)}
          class="form-group"
        >
          <label class="form-control-label"> Domaine d'activité* </label>
          <div class="input-group">
            <select
              name="company_activity"
              className="form-control"
              value={this.state.data.company_activity}
              onChange={this.onChange}
              disabled={Number(this.state.data.job_title) >= 7}
            >
              {Number(this.state.data.job_title) < 7 ? (
                <option value="" disabled selected>
                  Veuillez choisir
                </option>
              ) : (
                <option>Néant</option>
              )}
              {this.state.companyActivityOptions}
            </select>
          </div>
          {this.renderError("data.company_activity")}
        </div>
        <div
          ref={(node) => (this.componentRef.company_phone = node)}
          class="form-group"
        >
          <label class="form-control-label"> Téléphone société* </label>
          <br />
          <label class="form-control-label font-italic">
            Merci de mettre le numéro personnel du parent s’il est Sans emploi
            ou Retraité
          </label>
          <div class="input-group">
            <input
              name="company_phone"
              className="form-control"
              value={this.state.data.company_phone}
              onChange={this.onChange}
              placeholder="ex. +261 34 09 827 99"
            />
          </div>
          {this.renderError("data.company_phone")}
        </div>
        <div
          ref={(node) => (this.componentRef.company_address = node)}
          class="form-group"
        >
          <label class="form-control-label"> Adresse société* </label>
          <br />
          <label class="form-control-label font-italic">
            Merci de mettre l’adresse du domicile du parent s’il est Sans emploi
            ou Retraité
          </label>
          <div class="input-group">
            <input
              name="company_address"
              className="form-control"
              value={this.state.data.company_address}
              onChange={this.onChange}
              placeholder="ex. IVN 68A Ankadifotsy 101 Antananarivo"
            />
          </div>
          {this.renderError("data.company_address")}
        </div>
        {this.state.loading ? (
          <button type="button" class="btn btn-block btn-primary" disabled>
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
          </button>
        ) : (
          <button class="btn btn-primary btn-block" onClick={this.submitParent}>
            Valider
          </button>
        )}
      </div>
    );
  }
}

export default withRouter(PersonForm);
