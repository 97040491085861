import React, { Component } from "react";
import { Redirect } from "react-router-dom";

import RegisterStep1Background from "../../assets/img/backgrounds/register-step-1.jpg";
import RegisterStep2Background from "../../assets/img/backgrounds/register-step-2.jpg";
import RegisterStep3Background from "../../assets/img/backgrounds/register-step-3.jpg";
import RegisterStep4Background from "../../assets/img/backgrounds/register-step-4.jpg";

import { Step1, Step2, Step3, Step4, Step5 } from "./OldRegisterSteps";
import * as Icon from "react-feather";
import API from "../../Api";
import _ from "lodash";

class OldRegisterStep extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step1: null,
      step2: null,
      step3: null,
      step4: null,
      oldRegisterId: null,
    };

    this.renderStep = this.renderStep.bind(this);

    if (!_.has(localStorage, "X-AUTH-TOKEN") && this.props.token) {
      localStorage.setItem("X-AUTH-TOKEN", JSON.stringify(this.props.token));
      API.defaults.headers.common["X-AUTH-TOKEN"] = JSON.parse(
        localStorage.getItem("X-AUTH-TOKEN")
      );
    }
  }

  renderStep(step) {
    switch (step) {
      case "1":
        return <Step1 registerStep={this} token={this.props.token} />;
      case "2":
        return <Step2 registerStep={this} token={this.props.token} />;
      case "3":
        return <Step3 registerStep={this} token={this.props.token} />;
      case "4":
        return <Step4 registerStep={this} token={this.props.token} />;
      case "5":
        return <Step5 registerStep={this} token={this.props.token} />;
      default:
        return <Step1 registerStep={this} />;
    }
  }

  renderBackground(step) {
    switch (step) {
      case "1":
        this.leftPane = {
          background: RegisterStep1Background,
          title: "Étape 1 sur 5",
          description:
            "Veuillez remplir vos informations personnelles. Celles-ci sont la base de votre dossier académique.",
        };
        break;
      case "2":
        this.leftPane = {
          background: RegisterStep2Background,
          title: "Étape 2 sur 5",
          description:
            "Veuillez remplir vos informations personnelles. Celles-ci sont la base de votre dossier académique.",
        };
        break;
      case "3":
        this.leftPane = {
          background: RegisterStep3Background,
          title: "Étape 3 sur 5",
          description:
            "Veuillez remplir vos informations de filiation. Celles-ci sont requises pour compléter votre dossier.",
        };
        break;
      case "4":
        this.leftPane = {
          background: RegisterStep4Background,
          title: "Étape 4 sur 5",
          description:
            "Veuillez remplir vos informations scolaires. Ces informations nous permettront d'évaluer votre dossier et vous orienter vers le bon cursus.",
        };
        break;
      case "5":
        this.leftPane = {
          background: RegisterStep4Background,
          title: "Étape 5 sur 5",
          description:
            "Veuillez nous envoyer tous vos documents justificatifs, scannés, afin de finaliser votre dossier.",
        };
        break;
      default:
        this.leftPane = {
          background: RegisterStep1Background,
          title: "Étape 1 sur 4",
          description:
            "Veuillez remplir vos informations personnelles. Celles-ci sont la base de votre dossier académique.",
        };
    }
  }

  renderLeftPane(step) {
    this.renderBackground(step);

    return (
      <div>
        <div
          class="register-left-pane bg-primary position-absolute h-100 top-0 left-0 zindex-100 col-lg-6 col-xl-6 zindex-100 d-none d-lg-flex flex-column justify-content-end"
          data-bg-size="cover"
          data-bg-position="center"
        >
          <img src={this.leftPane.background} alt="Image" class="img-as-bg" />
          <div class="row position-relative zindex-110">
            <div class="step-title">
              <div class="col-md-8 text-center mx-auto">
                <h5 class="h5 text-white mt-3">{this.leftPane.title}</h5>
                <p class="text-white opacity-8">{this.leftPane.description}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }
    return [
      this.renderLeftPane(this.props.step),
      this.renderStep(this.props.step),
    ];
  }
}

export default OldRegisterStep;
