import React, { Component } from "react";
import * as Icon from "react-feather";

class School extends Component {
  constructor(props) {
    super(props);

    this.collection = props.collection;
    this.index = props.index;

    this.editSchool = this.editSchool.bind(this);
  }

  editSchool() {
    this.collection.editSchool(this.index);
  }

  render() {
    return (
      <tr>
        <td>{this.props.model.name}</td>
        <td>{this.props.model.grade}</td>
        <td>{this.props.model.year}</td>
        <td>
          <button
            onClick={this.editSchool}
            className="btn btn-xs btn-warning"
            disabled={this.props.disabled}
          >
            <Icon.Edit size="1em" />
          </button>
        </td>
      </tr>
    );
  }
}

export default School;
