import React from "react";

const ParentCard = ({
  parentType,
  parentFirstName,
  parentLastName,
  parentEmail,
  parentPhoneNumber,
  _id,
  onRemove,
}) => {
  const getParentTypeLabel = (type) => {
    switch (type) {
      case 1:
        return "Père";
      case 2:
        return "Mère";
      default:
        return "Tuteur";
    }
  };

  return (
    <div className="card p-3 rounded-0">
      <h6 className="border-bottom">{getParentTypeLabel(parentType)}</h6>
      <h7>
        <span className="">{parentFirstName}</span> {parentLastName}
      </h7>
      <p className="text-muted mb-2">
        {parentPhoneNumber} | {parentEmail}
      </p>
      <button
        className="btn btn-danger btn-sm align-self-end"
        onClick={() => onRemove && onRemove(_id)}
        type="button"
      >
        Supprimer
      </button>
    </div>
  );
};

export default ParentCard;
