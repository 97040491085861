import { useState } from "react";
import { StepSave } from "../queries/stepSave";
import { INSCRIPTION_STEP, SCHOOLING_STEP } from "./useFormStep";

const useSubmitInscription = () => {
  const [submiting, setSubmiting] = useState(false);
  const [errors, setErrors] = useState();
  const [applicantId, setApplicantId] = useState(localStorage.getItem('applicantId'));

  const onSubmit = async (data, step, callback) => {
    if (SCHOOLING_STEP === parseInt(data["stepNumber"])) {
      // remove unecessary fields
      [
        "parentFirstName",
        "parentLastName",
        "parentEmail",
        "parentProfession",
        "parentPhoneNumber",
        "parentType",
      ].forEach((field) => delete data[field]);
    }
    setSubmiting(true);

    try {
      console.log(data);
      switch (step) {
        case INSCRIPTION_STEP:
          const id = await StepSave.saveIdentity(data);
          setApplicantId(id);
          localStorage.setItem('applicantId', id)
          break;
        case SCHOOLING_STEP:
        default:
          await StepSave.saveSchooling(data, applicantId);
          break;
      }

      setSubmiting(false);
      callback && callback();
    } catch (err) {
      setSubmiting(false);
      setErrors(err.response.data);
      setTimeout(() => {
        setErrors(undefined);
      }, 5000);
    }
  };

  return { submiting, onSubmit, errors, applicantId };
};

export { useSubmitInscription };

