import React, { useEffect, useState } from "react";
import { Documents } from "../queries";

const useLevelDocuments = () => {
  const [documents, setDocuments] = useState([]);

  useEffect(() => {
    Documents.findAll().then((doc) => setDocuments(doc));
  }, []);

  return { documents };
};

export { useLevelDocuments };
