import React, { useEffect, useState } from "react";
import { EntranceGrade } from "../queries/entranceGrade";

const useEntraceGradeList = () => {
  const [levels, setLevels] = useState([]);

  useEffect(() => {
    EntranceGrade.findAll().then((levels) => setLevels(levels));
  }, []);

  const levelSelect = () => {
    return [
      {
        value: -1,
        label: "Séléctionner un niveau d'entrée",
      },
      ...levels.map((level) => ({
        label: level.name,
        value: level.id,
      })),
    ];
  };

  const isParentNeeded = (levelId) => {
    const [level] = levels.filter((level) => level.id === levelId);

    return level && level.isParentNeeded;
  };

  const getRequiredDocuments = (levelId) => {
    const [level] = levels.filter((level) => level.id === levelId);

    return level && level.documents;
  };

  return { levels, levelSelect, isParentNeeded, getRequiredDocuments };
};

export { useEntraceGradeList };
