import React, { useEffect, useState } from "react";
import { Countries } from "../queries/contries";

const useCountryList = () => {
  const [countries, setContries] = useState([]);

  useEffect(() => {
    Countries.findAll().then((countries) => setContries(countries));
  }, []);

  const countryListSelect = () => {
    return [
      {
        value: -1,
        label: "Séléctionner un pays",
      },
      ...countries.map((country) => ({
        label: country.name,
        value: country.id,
      })),
    ];
  };

  return { countries, countryListSelect };
};

export { useCountryList };
