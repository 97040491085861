import React, { useEffect, useState } from "react";
import { Countries } from "../queries/contries";
import { ExamSession } from "../queries/examSessions";

const useExamSessionList = () => {
  const [sessions, setSessions] = useState([]);

  useEffect(() => {
    ExamSession.findAll().then((sessions) => setSessions(sessions));
  }, []);

  const sessionListSelect = () => {
    return [
      {
        value: -1,
        label: "Séléctionner une session",
      },
      ...sessions.map((session) => ({
        label: session.name,
        value: session.id,
      })),
    ];
  };

  return { sessions, sessionListSelect };
};

export { useExamSessionList };
