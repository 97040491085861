import React, { useEffect, useState } from "react";
import { Countries } from "../queries/contries";
import { Interest } from "../queries/interest";

const useInterestList = () => {
  const [interests, setInterest] = useState([]);

  useEffect(() => {
    Interest.findAll().then((interests) => setInterest(interests));
  }, []);

  const interestList = () => {
    return [
      {
        value: -1,
        label: "Séléctionner un centre d'intérêt",
      },
      ...interests.map((interest) => ({
        label: interest.name,
        value: interest.id,
      })),
    ];
  };

  return { interests, interestList };
};

export { useInterestList };
