import React, { Component } from "react";

import headerBackground from "../assets/img/backgrounds/interviews.jpg";
import interviewImages from "../commercial/InterviewImages";
import interviewDatas from "../commercial/InterviewDatas.json";

class Interviews extends Component {
  render() {
    return (
      <div>
        <section
          class="slice slice-lg py-7 bg-cover bg-size--cover"
          style={{ backgroundImage: "url(" + headerBackground + ")" }}
        >
          <span class="mask bg-dark opacity-8"></span>
          <div
            class="container d-flex align-items-center"
            data-offset-top="#navbar-main"
          >
            <div class="col py-5">
              <div class="row align-items-center justify-content-center">
                <div class="col-md-7 col-lg-7 text-center">
                  <h1 class="display-4 text-white mb-2">Témoignages</h1>
                  <span class="text-white text-sm">
                    Découvrez les témoignages de la famille ISCAM et faites vous
                    une idée de votre potentiel.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="slice pt-5 pb-7 bg-section-secondary">
          <div class="container masonry-container">
            <div class="row masonry">
              {interviewDatas.map(({ key, cardTitle, cardName }) => (
                <div class="masonry-item col-xl-4 col-md-6">
                  <div class="card hover-translate-y-n3 hover-shadow-lg overflow-hidden">
                    <div class="position-relative overflow-hidden">
                      <a href={`/interviews/${key}`} class="d-block">
                        <img
                          alt={key}
                          src={interviewImages[`interviewee_${key}`]}
                          class="card-img-top"
                        />
                      </a>
                    </div>
                    <div class="card-body py-4">
                      <a
                        href={`/interviews/${key}`}
                        class="h5 stretched-link lh-150"
                      >
                        {cardName}
                      </a>
                      <p class="mt-3 mb-0 lh-170">{cardTitle}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Interviews;
