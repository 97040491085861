import React, { Component } from "react";
import Api from "../../Api";
import * as Icon from "react-feather";
import InputFile from "../../components/InputFile";
import pdfIcon from "../../assets/img/icons/pdf-icon.png";
import _ from "lodash";
import Loading from "../../components/Loading";

class Step6_5 extends Component {
  state = {
    documents: [
      {
        type: 200,
        document: "",
        mime_type: "",
        file_name: "",
      },
      {
        type: 210,
        document: "",
        mime_type: "",
        file_name: "",
      },
      {
        type: 250,
        document: "",
        mime_type: "",
        file_name: "",
      },
      {
        type: 260,
        document: "",
        mime_type: "",
        file_name: "",
      },
    ],
    profile: {},
    success: false,
    errors: {},
    loadingFetch: false,
    loading: false,
  };

  constructor(props) {
    super(props);

    if (this.props.token) {
      Api.defaults.headers.common["X-AUTH-TOKEN"] = this.props.token;
      localStorage.setItem(
        "X-AUTH-TOKEN_new",
        JSON.stringify(this.props.token)
      );
    }

    this.onSubmit = this.onSubmit.bind(this);
    this.onFileChange = this.onFileChange.bind(this);
    this.hasError = this.hasError.bind(this);
    this.renderError = this.renderError.bind(this);
    this.renderSuccessScreen = this.renderSuccessScreen.bind(this);
  }

  componentDidMount() {
    if (_.has(localStorage, "X-AUTH-TOKEN_new")) {
      Api.defaults.headers.common["X-AUTH-TOKEN"] = JSON.parse(
        localStorage.getItem("X-AUTH-TOKEN_new")
      );
      this.getApplicantProfile();
    }
  }

  getApplicantProfile() {
    this.setState({ loadingFetch: true });
    return Api.get("/applicant/profile").then(
      async ({ data: { 0: profile } }) => {
        this.setState({
          loadingFetch: false,
          profile,
        });
      }
    );
  }

  onSubmit(event) {
    event.preventDefault();

    const { documents } = this.state;

    this.setState({ loading: true });
    Api.post("/register/review/step-additional", {
      documents: documents.filter((e) => e.document.length),
    })
      .then(() => {
        this.setState({ loading: false, success: true });
      })
      .catch(
        ({
          response: {
            data: { 0: errors },
          },
        }) => {
          this.setState({ loading: false, errors });
        }
      );
  }

  /**
   * @param {React.SyntheticEvent<HTMLInputElement>} event
   * @param {Number} index
   */
  onFileChange(event, index) {
    event.persist();

    var reader = new FileReader();
    let b64 = "";

    reader.onload = () => {
      b64 = window.btoa(reader.result);
      const doc = this.state.documents[index];
      doc.document = b64;
      doc.file_name = event.target.files[0].name;
      doc.mime_type = event.target.files[0].type;
      this.setState({});
    };
    reader.readAsBinaryString(event.target.files[0]);
  }

  renderError(e) {
    if (this.hasError(e)) {
      return (
        <div class="text-danger">
          <Icon.XOctagon size="1em" />
          &nbsp;{this.state.errors[e]}
        </div>
      );
    }
  }

  hasError(e) {
    if (!_.isEmpty(this.state.errors)) {
      var keys = Object.keys(this.state.errors);
      return keys.includes(e);
    }
  }

  renderSuccessScreen() {
    if (this.state.success)
      return (
        <div className="poi-edit mb-5 mt-5">
          <p>
            Votre dossier d’inscription pour l’année académique{" "}
            {this.state.profile.school_year.name} est bien enregistré par notre
            système d’inscription en ligne sous la référence:{" "}
            {this.state.profile.applicant_id}. Il est en cours de validation.
            Vous recevrez une notification dans les prochains jours sur
            l’avancement de votre situation d’inscription définitive.
          </p>
        </div>
      );

    return null;
  }

  render() {
    return (
      <div className="register-right-pane container-fluid d-flex flex-column">
        <div className="row align-items-center justify-content-center justify-content-lg-end min-vh-100">
          <div className="scrollable-content col-sm-7 col-lg-6 col-xl-6 py-6 py-lg-0 position-absolute h-100 top-0 right-0">
            <div className="row justify-content-center">
              <div className="col-11 col-lg-10 col-xl-10">
                {this.state.loadingFetch && <Loading />}

                {this.renderSuccessScreen()}

                {!this.state.success && (
                  <>
                    <div className="mt-5 mb-5">
                      <h6 class="h3 mb-1">VOS COMPLÉMENTS DE DOSSIERS</h6>
                      <p>
                        Merci d'uploader ici vos compléments de dossier
                        d’inscription définitive.
                      </p>
                      <ul>
                        <li>Certificat de résidence de l’étudiant</li>
                        <li>
                          Certificat de résidence du garant ou des parents
                        </li>
                        <li>Fiche d’engagement signée et légalisée</li>
                        <li>
                          Bordereau de versement à la banque de la première
                          tranche des frais de scolarité.
                        </li>
                      </ul>
                    </div>

                    <div className="form-group">
                      <label className="form-control-label">
                        Certificat de résidence
                      </label>
                      <div className="input-group">
                        <InputFile
                          name="file1"
                          fileName={this.state.documents[0].file_name}
                          onChange={(event) => this.onFileChange(event, 0)}
                          accept="image/*, .pdf"
                        />
                      </div>
                      {this.state.documents[0].document && (
                        <img
                          class="img-fluid rounded"
                          alt="icon"
                          style={{
                            width: 75,
                            height: 75,
                            objectFit: "cover",
                          }}
                          src={
                            this.state.documents[0].mime_type.split("/")[1] ===
                            "pdf"
                              ? pdfIcon
                              : "data:image/png;base64," +
                                this.state.documents[0].document
                          }
                        />
                      )}
                      {this.renderError("data.document.residency_cert")}
                    </div>
                    <div className="form-group">
                      <label className="form-control-label">
                        Certificat de résidence du garant/parent
                      </label>
                      <div className="input-group">
                        <InputFile
                          name="file2"
                          fileName={this.state.documents[1].file_name}
                          onChange={(event) => this.onFileChange(event, 1)}
                          accept="image/*, .pdf"
                        />
                      </div>
                      {this.state.documents[1].document && (
                        <img
                          class="img-fluid rounded"
                          alt="icon"
                          style={{
                            width: 75,
                            height: 75,
                            objectFit: "cover",
                          }}
                          src={
                            this.state.documents[1].mime_type.split("/")[1] ===
                            "pdf"
                              ? pdfIcon
                              : "data:image/png;base64," +
                                this.state.documents[1].document
                          }
                        />
                      )}
                      {this.renderError("data.document.guarantor_residency")}
                    </div>
                    <div className="form-group">
                      <label className="form-control-label">
                        Fiche d'engagement
                      </label>
                      <div className="input-group">
                        <InputFile
                          name="file3"
                          fileName={this.state.documents[2].file_name}
                          onChange={(event) => this.onFileChange(event, 2)}
                          accept="image/*, .pdf"
                        />
                      </div>
                      {this.state.documents[2].document && (
                        <img
                          class="img-fluid rounded"
                          alt="icon"
                          style={{
                            width: 75,
                            height: 75,
                            objectFit: "cover",
                          }}
                          src={
                            this.state.documents[2].mime_type.split("/")[1] ===
                            "pdf"
                              ? pdfIcon
                              : "data:image/png;base64," +
                                this.state.documents[2].document
                          }
                        />
                      )}
                      {this.renderError("data.document.commitment")}
                    </div>
                    <div className="form-group">
                      <label className="form-control-label">
                        Bordereau de versement
                      </label>
                      <div className="input-group">
                        <InputFile
                          name="file4"
                          fileName={this.state.documents[3].file_name}
                          onChange={(event) => this.onFileChange(event, 3)}
                          accept="image/*, .pdf"
                        />
                      </div>
                      {this.state.documents[3].document && (
                        <img
                          class="img-fluid rounded"
                          alt="icon"
                          style={{
                            width: 75,
                            height: 75,
                            objectFit: "cover",
                          }}
                          src={
                            this.state.documents[3].mime_type.split("/")[1] ===
                            "pdf"
                              ? pdfIcon
                              : "data:image/png;base64," +
                                this.state.documents[3].document
                          }
                        />
                      )}
                      {this.renderError("data.document.deposit")}
                    </div>
                    <button
                      type="button"
                      class="btn btn-block btn-primary"
                      disabled={this.state.examSessionDeadlinePassed}
                      onClick={this.onSubmit}
                    >
                      {this.state.loading ? (
                        <span
                          class="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        />
                      ) : (
                        "Valider"
                      )}
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Step6_5;
