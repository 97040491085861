import React, { useEffect } from "react";
import Cursus from "../queries/cursus";

const useEntranceCursusList = () => {
  const [cursus, setCursusList] = React.useState([]);

  useEffect(() => {
    Cursus.findAllWithGrade().then((data) => {
      setCursusList(data);
    });
  }, []);

  const getAvailableByGradeId = (gradeId) => {
    return cursus.filter((c) => c.required_grade_ids.includes(gradeId));
  };

  const getListByGradeId = (gradeId) => {
    return [
      {
        value: -1,
        label: "Séléctionner un cursus",
      },
      ...getAvailableByGradeId(gradeId).map((cursus) => ({
        label: cursus.name,
        value: cursus.id,
      })),
    ];
  };

  return { cursus, getAvailableByGradeId, getListByGradeId };
};

export { useEntranceCursusList };
