import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import _ from "lodash";
import * as Icon from "react-feather";
import API from "../../Api";
import { Password, Loading } from "../../components/Components";

class Step2 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      redirect: null,
      loading: false,
      loadingFetch: false,
      showPassword: "password",
      countryOptions: [],
      interestOptions: [],
      applicant: {
        person: {
          last_name: "",
          first_name: "",
          usage_name: "",
          birthdate: "",
          sex: "",
          birth_city: "",
          birth_country: "",
          nationality: "",
          marital_status: "",
          address: "",
          secondary_address: "",
          mobile_phone: "",
          phone: "",
          user: {
            email: "",
            password: "",
            confirm_password: "",
          },
        },
        family_members_at_iscam: "",
        interests: "",
      },
      errors: [],
    };

    this.componentRef = {
      last_name: null,
      first_name: null,
      usage_name: null,
      birthdate: null,
      sex: null,
      birth_city: null,
      birth_country: null,
      nationality: null,
      marital_status: null,
      address: null,
      secondary_adress: null,
      mobile_phone: null,
      phone: null,
      email: null,
      family_members_at_iscam: null,
      interests: null,
    };

    this.change = this.change.bind(this);
    this.changePerson = this.changePerson.bind(this);
    this.changeUser = this.changeUser.bind(this);
    this.submitApplicant = this.submitApplicant.bind(this);
    this.populateCountries = this.populateCountries.bind(this);
    this.populateInterests = this.populateInterests.bind(this);
    this.renderError = this.renderError.bind(this);
    this.hasError = this.hasError.bind(this);
    this.scrollToNode = this.scrollToNode.bind(this);
    this.findElementToScroll = this.findElementToScroll.bind(this);
    this.getData = this.getData.bind(this);

    this.populateCountries();
    this.populateInterests();
  }

  componentDidMount() {
    if (_.has(localStorage, "X-AUTH-TOKEN_old")) {
      if (JSON.parse(localStorage.getItem("X-AUTH-TOKEN_old"))) {
        API.defaults.headers.common["X-AUTH-TOKEN"] = JSON.parse(
          localStorage.getItem("X-AUTH-TOKEN_old")
        );
        this.getData();
      } else {
        delete API.defaults.headers.common["X-AUTH-TOKEN"];
      }
    }
  }

  getData() {
    this.setState({ loadingFetch: true });
    API.get("/old/register/step-2").then(
      function (response) {
        this.setState({ loadingFetch: false });
        var response = response.data["0"];
        var applicant = this.state.applicant;

        applicant.person.last_name = response.person.lastname;
        applicant.person.first_name = response.person.firstname;
        applicant.person.usage_name = response.person.usage_name;
        applicant.person.birthdate = response.person.birthdate;
        applicant.person.sex = String(response.person.sex);
        applicant.person.birth_city = response.person.birth_city;
        applicant.person.birth_country = response.person.birth_country.id;
        applicant.person.nationality = response.person.nationality.id;
        applicant.person.marital_status = response.person.marital_status;
        applicant.person.address = response.person.address;
        applicant.person.secondary_address = response.person.secondary_address;
        applicant.person.mobile_phone = response.person.mobile_phone;
        applicant.person.phone = response.person.phone;
        applicant.person.user.email = response.person.email;
        applicant.family_members_at_iscam = response.family_members_at_iscam;
        applicant.interests = [response.interests[0].id];

        this.setState({ applicant: applicant });
      }.bind(this)
    );
  }

  change(event) {
    var applicant = this.state.applicant;

    if (event.target.name == "interests") {
      applicant[event.target.name] = [event.target.value];
    } else {
      applicant[event.target.name] = event.target.value;
    }

    this.setState({ applicant: applicant });
  }

  changePerson(event) {
    var applicant = this.state.applicant;

    applicant.person[event.target.name] = event.target.value;

    this.setState({ applicant: applicant });
  }

  changeUser(event) {
    var applicant = this.state.applicant;

    applicant.person.user[event.target.name] = event.target.value;

    this.setState({ applicant: applicant });
  }

  submitApplicant() {
    this.setState({ loading: true });
    let path = "/old/register/step-2";
    if (localStorage.getItem("old_register_id")) {
      path += "/" + localStorage.getItem("old_register_id");
    }
    console.log("path : ", path);
    API.post(path, this.state.applicant)
      .then(
        function (response) {
          this.setState({ loading: false });
          var applicant = this.state.applicant;
          applicant.apiKey = response.data["0"];
          localStorage.setItem(
            "X-AUTH-TOKEN_old",
            JSON.stringify(applicant.apiKey)
          );

          this.setState({ applicant: applicant });
          this.props.registerStep.setState({ step2: this.state.applicant });
          this.setState({ redirect: "/old/register/3" });
        }.bind(this)
      )
      .catch(
        function (error) {
          this.setState({ errors: error.response.data[0], loading: false });
          var errors = this.getNameErrors(error.response.data[0]);
          this.findElementToScroll(errors);
        }.bind(this)
      );
  }

  findElementToScroll(errors) {
    for (var element in this.componentRef) {
      if (errors.includes(element)) {
        this.scrollToNode(this.componentRef[element]);
        break;
      }
    }
  }

  getNameErrors(errors) {
    var keyNames = [];
    var keys = Object.keys(errors);
    keys.forEach((key) => {
      var name = key.split(".");
      keyNames.push(name[name.length - 1]);
    });
    return keyNames;
  }

  scrollToNode(node) {
    node.scrollIntoView({ block: "start", behavior: "smooth" });
  }

  populateCountries() {
    API.get("/list/countries").then(
      function (response) {
        var countryOptions = [];

        if (Array.isArray(response.data)) {
          var sortedCountries = response.data.sort((a, b) => {
            if (a.name_fr < b.name_fr) return -1;
            else if (a.name_fr > b.name_fr) return 1;
            return 0;
          });

          sortedCountries.forEach(function (value, index) {
            countryOptions.push(
              <option value={value.id}>{value.name_fr}</option>
            );
          });
        }

        this.setState({ countryOptions: countryOptions });
      }.bind(this)
    );
  }

  populateInterests() {
    API.get("/list/interests").then(
      function (response) {
        var interestOptions = [];

        if (Array.isArray(response.data)) {
          response.data.forEach(function (value, index) {
            interestOptions.push(
              <option value={value.id}>{value.name}</option>
            );
          });
        }

        this.setState({ interestOptions: interestOptions });
      }.bind(this)
    );
  }

  renderError(e) {
    if (this.hasError(e)) {
      return (
        <div class="text-danger">
          <Icon.XOctagon size="1em" />
          &nbsp;{this.state.errors[e]}
        </div>
      );
    }
  }

  hasError(e) {
    var keys = Object.keys(this.state.errors);
    return keys.includes(e);
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }

    return (
      <div class="register-right-pane container-fluid d-flex flex-column">
        <div class="row align-items-center justify-content-center justify-content-lg-end min-vh-100">
          <div class="scrollable-content col-sm-7 col-lg-6 col-xl-6 py-6 py-lg-0 position-absolute h-100 top-0 right-0">
            <div class="row justify-content-center">
              <div class="col-11 col-lg-10 col-xl-10">
                <div>
                  <div class="mb-5 mt-5">
                    {this.state.loadingFetch && <Loading />}
                    <h6 class="h3 mb-1">Ré-inscription Concours ISCAM</h6>
                    <p class="text-muted mb-0">Informations élémentaires</p>
                  </div>
                  <span class="clearfix"></span>
                  <form>
                    <div
                      ref={(node) => (this.componentRef.last_name = node)}
                      class="form-group"
                    >
                      <label class="form-control-label">Nom de famille*</label>
                      <div class="input-group">
                        <input
                          type="text"
                          class={"form-control".concat(
                            this.hasError("data.relationships[0].last_name")
                              ? " is-invalid"
                              : ""
                          )}
                          name="last_name"
                          onChange={this.changePerson}
                          value={this.state.applicant.person.last_name}
                          placeholder="ex. Rakotomalala"
                        />
                      </div>
                      {this.renderError("data.relationships[0].last_name")}
                    </div>
                    <div
                      ref={(node) => (this.componentRef.first_name = node)}
                      class="form-group"
                    >
                      <label class="form-control-label">Prénom(s)*</label>
                      <div class="input-group">
                        <input
                          type="text"
                          class={"form-control".concat(
                            this.hasError("data.relationships[0].first_name")
                              ? " is-invalid"
                              : ""
                          )}
                          name="first_name"
                          onChange={this.changePerson}
                          value={this.state.applicant.person.first_name}
                          placeholder="ex. Johary David"
                        />
                      </div>
                      {this.renderError("data.relationships[0].first_name")}
                    </div>
                    <div
                      ref={(node) => (this.componentRef.usage_name = node)}
                      class="form-group"
                    >
                      <label class="form-control-label">Prénom d'usage*</label>
                      <div class="input-group">
                        <input
                          type="text"
                          class={"form-control".concat(
                            this.hasError("data.relationships[0].usage_name")
                              ? " is-invalid"
                              : ""
                          )}
                          name="usage_name"
                          onChange={this.changePerson}
                          value={this.state.applicant.person.usage_name}
                          placeholder="ex. Johary"
                        />
                      </div>
                      {this.renderError("data.relationships[0].usage_name")}
                    </div>
                    <div
                      ref={(node) => (this.componentRef.birthdate = node)}
                      class="form-group"
                    >
                      <label class="form-control-label">
                        Date de Naissance*
                      </label>
                      <div class="input-group">
                        <input
                          type="text"
                          class={"form-control".concat(
                            this.hasError("data.relationships[0].birthdate")
                              ? " is-invalid"
                              : ""
                          )}
                          name="birthdate"
                          onChange={this.changePerson}
                          value={this.state.applicant.person.birthdate}
                          placeholder="ex. 05/12/2002"
                        />
                      </div>
                      {this.renderError("data.relationships[0].birthdate")}
                    </div>
                    <div
                      ref={(node) => (this.componentRef.sex = node)}
                      class="form-group"
                    >
                      <label class="form-control-label">Genre*</label>
                      <div class="input-group">
                        <select
                          name="sex"
                          onChange={this.changePerson}
                          value={this.state.applicant.person.sex}
                          class={"form-control".concat(
                            this.hasError("data.relationships[0].sex")
                              ? " is-invalid"
                              : ""
                          )}
                        >
                          <option value="" disabled selected>
                            Veuillez choisir
                          </option>
                          <option value="1">Homme</option>
                          <option value="0">Femme</option>
                        </select>
                      </div>
                      {this.renderError("data.relationships[0].sex")}
                    </div>
                    <div
                      ref={(node) => (this.componentRef.birth_city = node)}
                      class="form-group"
                    >
                      <label class="form-control-label">
                        Lieu de Naissance*
                      </label>
                      <div class="input-group">
                        <input
                          type="text"
                          class={"form-control".concat(
                            this.hasError("data.relationships[0].birth_city")
                              ? " is-invalid"
                              : ""
                          )}
                          name="birth_city"
                          onChange={this.changePerson}
                          value={this.state.applicant.person.birth_city}
                          placeholder="ex. Toamasina"
                        />
                      </div>
                      {this.renderError("data.relationships[0].birth_city")}
                    </div>
                    <div
                      ref={(node) => (this.componentRef.birth_country = node)}
                      class="form-group"
                    >
                      <label class="form-control-label">
                        Pays de Naissance*
                      </label>
                      <div class="input-group">
                        <select
                          name="birth_country"
                          onChange={this.changePerson}
                          value={this.state.applicant.person.birth_country}
                          class={"form-control".concat(
                            this.hasError("data.relationships[0].birth_country")
                              ? " is-invalid"
                              : ""
                          )}
                        >
                          <option value="" disabled selected>
                            Veuillez choisir
                          </option>
                          {this.state.countryOptions}
                        </select>
                      </div>
                      {this.renderError("data.relationships[0].birth_country")}
                    </div>
                    <div
                      ref={(node) => (this.componentRef.nationality = node)}
                      class="form-group"
                    >
                      <label class="form-control-label">Nationalité*</label>
                      <div class="input-group">
                        <select
                          name="nationality"
                          onChange={this.changePerson}
                          value={this.state.applicant.person.nationality}
                          class={"form-control".concat(
                            this.hasError("data.relationships[0].nationality")
                              ? " is-invalid"
                              : ""
                          )}
                        >
                          <option value="" disabled selected>
                            Veuillez choisir
                          </option>
                          {this.state.countryOptions}
                        </select>
                      </div>
                      {this.renderError("data.relationships[0].nationality")}
                    </div>
                    <div
                      ref={(node) => (this.componentRef.marital_status = node)}
                      class="form-group"
                    >
                      <label class="form-control-label">
                        Situation de famille*
                      </label>
                      <div class="input-group">
                        <select
                          name="marital_status"
                          onChange={this.changePerson}
                          value={this.state.applicant.person.marital_status}
                          class={"form-control".concat(
                            this.hasError(
                              "data.relationships[0].marital_status"
                            )
                              ? " is-invalid"
                              : ""
                          )}
                        >
                          <option value="" disabled selected>
                            Veuillez choisir
                          </option>
                          <option value="10">Célibataire</option>
                          <option value="20">Marié</option>
                          <option value="30">Divorcé</option>
                          <option value="40">Veuf</option>
                        </select>
                      </div>
                      {this.renderError("data.relationships[0].marital_status")}
                    </div>
                    <div
                      ref={(node) => (this.componentRef.address = node)}
                      class="form-group"
                    >
                      <label class="form-control-label">
                        Adresse principale*
                      </label>
                      <div class="input-group">
                        <input
                          type="text"
                          class={"form-control".concat(
                            this.hasError("data.relationships[0].address")
                              ? " is-invalid"
                              : ""
                          )}
                          name="address"
                          onChange={this.changePerson}
                          value={this.state.applicant.person.address}
                          placeholder="ex. IVN 68A Ankadifotsy, 101 Antananarivo"
                        />
                      </div>
                      {this.renderError("data.relationships[0].address")}
                    </div>
                    <div
                      ref={(node) =>
                        (this.componentRef.secondary_adress = node)
                      }
                      class="form-group"
                    >
                      <label class="form-control-label">
                        Adresse secondaire
                      </label>
                      <div class="input-group">
                        <input
                          type="text"
                          class={"form-control".concat(
                            this.hasError(
                              "data.relationships[0].secondary_adress"
                            )
                              ? " is-invalid"
                              : ""
                          )}
                          name="secondary_address"
                          onChange={this.changePerson}
                          value={this.state.applicant.person.secondary_address}
                          placeholder="ex. IVN 68A Ankadifotsy, 101 Antananarivo"
                        />
                      </div>
                      {this.renderError(
                        "data.relationships[0].secondary_Address"
                      )}
                    </div>
                    <div
                      ref={(node) => (this.componentRef.mobile_phone = node)}
                      class="form-group"
                    >
                      <label class="form-control-label">
                        Téléphone mobile*
                      </label>
                      <div class="input-group">
                        <input
                          type="text"
                          class={"form-control".concat(
                            this.hasError("data.relationships[0].mobile_phone")
                              ? " is-invalid"
                              : ""
                          )}
                          name="mobile_phone"
                          onChange={this.changePerson}
                          value={this.state.applicant.person.mobile_phone}
                          placeholder="ex. +261 34 22 808 10"
                        />
                      </div>
                      {this.renderError("data.relationships[0].mobile_phone")}
                    </div>
                    <div
                      ref={(node) => (this.componentRef.phone = node)}
                      class="form-group"
                    >
                      <label class="form-control-label">
                        Téléphone secondaire
                      </label>
                      <div class="input-group">
                        <input
                          type="text"
                          class={"form-control".concat(
                            this.hasError("data.relationships[0].phone")
                              ? " is-invalid"
                              : ""
                          )}
                          name="phone"
                          onChange={this.changePerson}
                          value={this.state.applicant.person.phone}
                          placeholder="ex. +261 32 09 765 01"
                        />
                      </div>
                      {this.renderError("data.relationships[0].phone")}
                    </div>
                    <div
                      ref={(node) => (this.componentRef.email = node)}
                      class="form-group"
                    >
                      <label class="form-control-label">Adresse e-mail*</label>
                      <div class="input-group">
                        <input
                          type="email"
                          class={"form-control".concat(
                            this.hasError("data.relationships[0].user.email")
                              ? " is-invalid"
                              : ""
                          )}
                          name="email"
                          onChange={this.changeUser}
                          value={this.state.applicant.person.user.email}
                          placeholder="ex. johary.rakotomalala@exemple.mg"
                        />
                      </div>
                      {this.renderError("data.relationships[0].user.email")}
                    </div>
                    <Password
                      valuePassword={this.state.applicant.person.user.password}
                      valueConfirm={
                        this.state.applicant.person.user.confirm_password
                      }
                      onChange={this.changeUser}
                      errorPassword={
                        this.state.errors["data.relationships[0].user.password"]
                          ? this.state.errors[
                              "data.relationships[0].user.password"
                            ]
                          : null
                      }
                      errorConfirm={
                        this.state.errors[
                          "data.relationships[0].user.confirm_password"
                        ]
                          ? this.state.errors[
                              "data.relationships[0].user.confirm_password"
                            ]
                          : null
                      }
                    />
                    <div
                      ref={(node) =>
                        (this.componentRef.family_members_at_iscam = node)
                      }
                      class="form-group"
                    >
                      <label class="form-control-label">
                        Nombre de membres de la famille à l'ISCAM*
                      </label>
                      <div class="input-group">
                        <input
                          type="text"
                          class={"form-control".concat(
                            this.hasError("data.family_members_at_iscam")
                              ? " is-invalid"
                              : ""
                          )}
                          name="family_members_at_iscam"
                          onChange={this.change}
                          value={this.state.applicant.family_members_at_iscam}
                          placeholder="ex. 3"
                        />
                      </div>
                      {this.renderError("data.family_members_at_iscam")}
                    </div>
                    <div
                      ref={(node) => (this.componentRef.interests = node)}
                      class="form-group"
                    >
                      <label class="form-control-label">
                        Centre d'intérêt principal*
                      </label>
                      <div class="input-group">
                        <select
                          name="interests"
                          onChange={this.change}
                          value={this.state.applicant.interests}
                          class={"form-control".concat(
                            this.hasError("data.interests") ? " is-invalid" : ""
                          )}
                        >
                          <option value="" disabled selected>
                            Veuillez choisir
                          </option>
                          {this.state.interestOptions}
                        </select>
                      </div>
                      {this.renderError("data.interests")}
                    </div>
                    <div class="mt-4 mb-4">
                      {this.state.loading ? (
                        <button
                          type="button"
                          class="btn btn-block btn-primary"
                          disabled
                        >
                          <span
                            class="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        </button>
                      ) : (
                        <button
                          type="button"
                          onClick={this.submitApplicant}
                          class="btn btn-block btn-primary"
                        >
                          Valider
                        </button>
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Step2;
