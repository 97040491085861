import React, { Component } from "react";
import _ from "lodash";
import Api from "../../Api";
import Loading from "../../components/Loading";
import * as Icon from "react-feather";

class Step6_2 extends Component {
  state = {
    loadingFetch: false,
    grade_id: "",
    payment_id: "",
    gradeOptions: [],
    paymentOptions: [],
    paymentError: null,
  };

  constructor(props) {
    super(props);

    if (this.props.token) {
      Api.defaults.headers.common["X-AUTH-TOKEN"] = this.props.token;
      localStorage.setItem(
        "X-AUTH-TOKEN_new",
        JSON.stringify(this.props.token)
      );
    }

    this.onSubmit = this.onSubmit.bind(this);
  }

  getGrades() {
    return Api.get("/list/entrance_grades").then(({ data: gradeOptions }) => {
      if (Array.isArray(gradeOptions)) {
        this.setState({
          gradeOptions,
        });
      }
    });
  }

  componentDidMount() {
    if (_.has(localStorage, "X-AUTH-TOKEN_new")) {
      Api.defaults.headers.common["X-AUTH-TOKEN"] = JSON.parse(
        localStorage.getItem("X-AUTH-TOKEN_new")
      );
      this.getApplicantProfile().then(() => this.getPayments());
      this.getGrades();
    }
  }

  getApplicantProfile() {
    this.setState({ loadingFetch: true });
    return Api.get("/applicant/profile").then(
      async ({ data: { 0: profile } }) => {
        this.setState({
          loadingFetch: false,
          grade_id: Number(profile.grade.id),
        });
      }
    );
  }

  getPayments() {
    Api.get(`/list/payments/grade/${this.state.grade_id}`).then(({ data }) => {
      if (Array.isArray(data)) this.setState({ paymentOptions: data });
    });
  }

  onSubmit() {
    const { payment_id, grade_id } = this.state;
    Api.post("/register/review/step-payment", { payment_id, grade_id })
      .then(() => {
        this.props.history.push("/register/review/3");
      })
      .catch(
        ({
          response: {
            data: { 0: errors },
          },
        }) => {
          if (errors)
            this.setState({
              paymentError: errors["children[payment_id].data"],
            });
        }
      );
  }

  buildPaymentOption({ amount, times, start, end }) {
    return `${times} fois = ${times} x ${amount}Ar${
      start ? " de " + start : ""
    }${start && end ? " à " + end : !start && end ? " en " + end : ""}`;
  }

  render() {
    return (
      <div className="register-right-pane container-fluid d-flex flex-column">
        <div className="row align-items-center justify-content-center justify-content-lg-end min-vh-100">
          <div className="scrollable-content col-sm-7 col-lg-6 col-xl-6 py-6 py-lg-0 position-absolute h-100 top-0 right-0">
            <div className="row justify-content-center">
              <div className="col-11 col-lg-10 col-xl-10">
                {this.state.loadingFetch && <Loading />}
                <div className="mt-5 mb-5">
                  <h6 class="h3 mb-1">COMMENT REGLER VOTRE CURSUS</h6>
                  <p>
                    A l’ISCAM, vous disposez de plusieurs modalités de paiements
                    pour régler vos frais de scolarité. Choisissez parmi les
                    types de règlement mis à votre disposition
                  </p>
                </div>
                <div className="form-group">
                  <label className="form-control-label" for="grade">
                    J'intègre le niveau
                  </label>
                  <div className="input-group">
                    <select
                      className="form-control"
                      value={this.state.grade_id}
                      disabled
                    >
                      {this.state.gradeOptions.map(({ id, name }) => (
                        <option value={id}>{name}</option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="form-group">
                  <label className="form-control-label">Type de paiement</label>
                  <div className="input-group">
                    <select
                      className="form-control"
                      value={this.state.payment_id}
                      onChange={(event) =>
                        this.setState({ payment_id: event.target.value })
                      }
                    >
                      <option value="" disabled selected>
                        Veuillez choisir
                      </option>
                      {this.state.paymentOptions.map(({ id, ...data }) => (
                        <option value={id}>
                          {this.buildPaymentOption(data)}
                        </option>
                      ))}
                    </select>
                  </div>
                  {this.state.paymentError && (
                    <div class="text-danger">
                      <Icon.XOctagon size="1em" />
                      &nbsp;{this.state.paymentError}
                    </div>
                  )}
                </div>
                <button
                  type="button"
                  class="btn btn-block btn-primary"
                  disabled={this.state.examSessionDeadlinePassed}
                  onClick={this.onSubmit}
                >
                  {this.state.loading ? (
                    <span
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : (
                    "Valider"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Step6_2;
