import React, { Component } from "react";
import { Redirect } from "react-router";
import * as Icon from "react-feather";
import _ from "lodash";

import {
  Loading,
  School,
  SchoolForm,
  EndStep,
} from "../../components/Components";
import API from "../../Api";
import $ from "jquery";

class Step3 extends Component {
  constructor(props) {
    super(props);

    /*if (props.registerStep.state.step2 === null) {
      this.state = { redirect: "/register/2" };
      return;
    }*/

    if (this.props.token) {
      API.defaults.headers.common["X-AUTH-TOKEN"] = this.props.token;
      localStorage.setItem(
        "X-AUTH-TOKEN_new",
        JSON.stringify(this.props.token)
      );
    }

    this.state = {
      redirect: "",
      loading: false,
      loadingFetch: false,
      finished: false,
      schoolEdit: false,
      special_behavior: false,
      schools: [],
      schoolYearsOptions: [],
      gradeOptions: [],
      cursusesOptions: [],
      examSessionOptions: [],
      baccalaureateAcknowledgementOptions: [],
      baccalaureateTypeOptions: [],
      obtainedDiplomaOptions: [],
      errors: [],
      errorsSchool: [],
      data: {
        application: {
          schoolYears: "",
          grade: "",
          cursuses: "",
          examSession: "",
        },
        obtained_diploma: "",
        diploma_name: "",
        specialization: "",
        type: "",
        baccalaureate_type: "",
        diploma_available: 0,
        baccalaureate_acknowledgement: "",
        baccalaureate_grade: "",
        sat_ebrw_grade: "",
        sat_algebra_grade: "",
        diploma_year: "",
        diploma_place: "",
      },
    };

    this.componentRef = {
      school_year: null,
      entrance_grade: null,
      cursus: null,
      entrance_exam_session: null,
      schools: null,
      obtained_diploma: "",
      diploma_name: "",
      specialization: "",
      type: null,
      baccalaureate_type: null,
      baccalaureate_acknowledgement: null,
      baccalaureate_grade: null,
      sat_ebrw_grade: null,
      sat_algebra_grade: null,
      diploma_year: null,
      diploma_place: null,
    };

    this.findElementToScroll = this.findElementToScroll.bind(this);
    this.loadSchool = this.loadSchool.bind(this);
    this.changeType = this.changeType.bind(this);
    this.addSchool = this.addSchool.bind(this);
    this.cancelAddSchool = this.cancelAddSchool.bind(this);
    this.saveSchool = this.saveSchool.bind(this);
    this.renderEditSchool = this.renderEditSchool.bind(this);
    this.renderSchools = this.renderSchools.bind(this);
    this.changeBaseCursus = this.changeBaseCursus.bind(this);
    this.changeApplication = this.changeApplication.bind(this);
    this.hasError = this.hasError.bind(this);
    this.renderError = this.renderError.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.getData = this.getData.bind(this);
    this.renderEndStep = this.renderEndStep.bind(this);
    this.onChangeDiplomaAvailability = this.onChangeDiplomaAvailability.bind(
      this
    );
  }

  async load() {
    this.setState({ loadingFetch: true });
    await Promise.all([
      this.schoolYears(),
      this.schoolYears(),
      this.grade(),
      this.cursuses(),
      this.examSession(),
      this.baccalaureateSeriesMentions(),
      this.obtainedDiploma(),
    ]);
    this.setState({ loadingFetch: false });

    if (_.has(localStorage, "X-AUTH-TOKEN_new")) {
      API.defaults.headers.common["X-AUTH-TOKEN"] = JSON.parse(
        localStorage.getItem("X-AUTH-TOKEN_new")
      );

      this.getData();
      this.loadSchool();
    }
  }

  componentDidMount() {
    this.load();
  }

  getData() {
    this.setState({ loadingFetch: true });
    return API.get("/register/step-3").then(
      function (response) {
        this.setState({ loadingFetch: false });
        var response = response.data["0"];
        if (!_.isEmpty(response)) {
          var data = this.state.data;
          data.application.schoolYears = response.school_year.id;
          data.application.grade = Number(response.grade.id);
          data.application.cursuses = String(response.cursus.id);
          data.application.examSession = response.exam_session.id;
          if (response.grade.special_behavior) {
            this.setState({ special_behavior: true });
            data.obtained_diploma = Number(response.obtained_diploma);
            data.diploma_name = response.diploma_name;
            data.specialization = response.specialization;
          } else {
            data.diploma_available = response.diploma_available ? 1 : 0;
            data.type = String(response.type);
            if (response.type == 1) {
              data.baccalaureate_type = response.baccalaureate_type;
              data.baccalaureate_acknowledgement =
                response.baccalaureate_acknowledgement;
              data.baccalaureate_grade = response.baccalaureate_grade;
            } else if (response.type == 2) {
              data.sat_ebrw_grade = response.sat_ebrw_grade;
              data.sat_algebra_grade = response.sat_algebra_grade;
              data.sat_algebra_grade = response.sat_algebra_grade;
            }
            data.diploma_year = response.diploma_year;
            data.diploma_place = response.diploma_place;
          }
          this.setState({ data: data });
        }
      }.bind(this)
    );
  }

  loadSchool() {
    return API.get("/applicant/schools").then(
      function (response) {
        var schools = [];

        if (Array.isArray(response.data["0"])) {
          response.data["0"].forEach(function (value) {
            schools.push({
              id: value.id,
              name: value.name,
              place: value.place,
              grade: value.grade,
              year: value.year,
              errors: "",
              loading: false,
            });
          });
        }

        this.setState({ schools: schools });
      }.bind(this)
    );
  }

  changeType(event) {
    var data = this.state.data;
    data.type = event.target.value;

    if (event.target.value === "1") {
      data.sat_ebrw_grade = "";
      data.sat_algebra_grade = "";
      data.diploma_year = "";
      data.diploma_place = "";
      data.diploma_available = 0;
    } else if (event.target.value === "2") {
      data.baccalaureate_type = "";
      data.baccalaureate_acknowledgement = "";
      data.baccalaureate_grade = "";
      data.diploma_year = "";
      data.diploma_place = "";
      data.diploma_available = 0;
    } else {
      data.sat_ebrw_grade = "";
      data.sat_algebra_grade = "";
      data.diploma_year = "";
      data.diploma_place = "";
      data.baccalaureate_type = "";
      data.baccalaureate_acknowledgement = "";
      data.baccalaureate_grade = "";
      data.diploma_year = "";
      data.diploma_place = "";
      data.diploma_available = 0;
    }

    this.setState({ data: data });
  }

  addSchool() {
    this.setState({ schoolEdit: true });
  }

  cancelAddSchool() {
    this.setState({ schoolEdit: false });
  }

  saveSchool(school, index) {
    var schools = this.state.schools;

    if (index != undefined) {
      schools[index] = school;
    } else {
      schools.push(school);
    }

    this.setState({ schools: schools });
  }

  editSchool(index) {
    this.setState({ schoolEdit: index });
  }

  onChangeDiplomaAvailability(event) {
    let data = this.state.data;
    data[event.target.name] = event.target.value;
    this.setState({ data: data });
  }

  changeBaseCursus(event) {
    let data = this.state.data;
    data[event.target.name] = event.target.value;
    this.setState({ data: data });

    if (event.target.name === "obtained_diploma") {
      if (Number(event.target.value) !== 105) {
        data.diploma_name = "";
        this.setState({ data: data });
      }
    }
  }

  async changeApplication(event) {
    let data = this.state.data;
    data.application[event.target.name] = event.target.value;
    this.setState({ data: data });

    if (event.target.name == "grade") {
      if (
        this.state.gradeOptions.find(
          (grade) => Number(grade.id) == Number(event.target.value)
        ).special_behavior
      ) {
        this.setState({ special_behavior: true });
        data.type = "";
        data.baccalaureate_type = "";
        data.diploma_available = 0;
        data.baccalaureate_acknowledgement = "";
        data.baccalaureate_grade = "";
        data.sat_algebra_grade = "";
        data.sat_ebrw_grade = "";
        data.diploma_place = "";
        data.diploma_year = "";
        this.setState({ data: data });
      } else {
        this.setState({ special_behavior: false });
        data.obtained_diploma = "";
        data.diploma_name = "";
        data.specialization = "";
        this.setState({ data: data });
      }
      if (
        this.state.gradeOptions.find(
          (grade) => Number(grade.id) == Number(event.target.value)
        ).exam_session
      ) {
        data.application.examSession = "";
        this.setState({ data: data });
      }
    } else if (event.target.name === "examSession") {
      this.setState({ loadingFetch: true });
      const result = await API.get(
        `/entrance-exam-session/${event.target.value}`
      );
      const {
        data: { deadline },
      } = result;
      if (
        !deadline ||
        this.compareDateToNow(this.getDateFromFormattedString(deadline)) <= 0
      ) {
        $("#examSessionDeadlinePassed").modal();
        this.setState({ examSessionDeadlinePassed: true });
      } else {
        this.setState({ examSessionDeadlinePassed: false });
      }
      this.setState({ loadingFetch: false });
    }
  }

  getDateFromFormattedString(value) {
    const parts = value.split(" ");
    const date = parts[0],
      time = parts[1];

    const dateParts = date.split("/"),
      timeParts = time.split(":");

    return {
      year: Number(dateParts[2]),
      month: Number(dateParts[1]),
      date: Number(dateParts[0]),
      hours: Number(timeParts[0]),
      minutes: Number(timeParts[1]),
      seconds: Number(timeParts[2]),
    };
  }

  compareDateToNow({ year, month, date, hours, minutes, seconds }) {
    const now = new Date(),
      d = new Date(year, month - 1, date, hours, minutes, seconds);

    return d.getTime() - now.getTime();
  }

  schoolYears() {
    return API.get("/list/school_years").then(
      function (response) {
        var schoolYears = [];

        if (Array.isArray(response.data)) {
          response.data.forEach(function (value, index) {
            schoolYears.push({
              id: value.id,
              name: value.name,
            });
          });
        }

        this.setState({ schoolYearsOptions: schoolYears });
      }.bind(this)
    );
  }

  grade() {
    return API.get("/list/entrance_grades").then(
      function (response) {
        var gradeOptions = [];

        if (Array.isArray(response.data)) {
          response.data.forEach(function (value) {
            gradeOptions.push({
              id: value.id,
              name: value.name,
              special_behavior: value.special_behavior,
              exam_session: value.exam_session,
            });
          });
        }

        this.setState({ gradeOptions: gradeOptions });
      }.bind(this)
    );
  }

  cursuses() {
    return API.get("/list/cursuses").then(
      function (response) {
        var cursuses = [];

        if (Array.isArray(response.data)) {
          cursuses = response.data;
        } else if (typeof response.data === "object") {
          for (let key in response.data) {
            cursuses[key] = response.data[key];
          }
        }

        this.setState({ cursusesOptions: cursuses });
      }.bind(this)
    );
  }

  examSession() {
    return API.get("/list/entrance-exam-session").then(
      function (response) {
        var examSession = [];

        if (Array.isArray(response.data)) {
          examSession = [...response.data];
        }

        this.setState({ examSessionOptions: examSession });
      }.bind(this)
    );
  }

  baccalaureateSeriesMentions() {
    return API.get("/list/baccalaureate-series-mentions").then(
      function (response) {
        var baccalaureateSeries = [];
        var baccalaureateMentions = [];

        if (!_.isEmpty(response.data)) {
          baccalaureateSeries = [...response.data.series];
          baccalaureateMentions = [...response.data.mentions];
        }

        this.setState({
          baccalaureateAcknowledgementOptions: baccalaureateMentions,
          baccalaureateTypeOptions: baccalaureateSeries,
        });
      }.bind(this)
    );
  }

  obtainedDiploma() {
    return API.get("/list/diploma").then(
      function (response) {
        var obtainedDiplomaOptions = [];
        if (Array.isArray(response.data)) {
          obtainedDiplomaOptions = [...response.data];

          this.setState({ obtainedDiplomaOptions: obtainedDiplomaOptions });
        }
      }.bind(this)
    );
  }

  renderEndStep() {
    if (this.state.finished) {
      return <EndStep />;
    } else {
      return null;
    }
  }

  onSubmit() {
    this.setState({ loading: true });
    API.post("/register/step-3", this.state.data)
      .then(
        function (response) {
          this.setState({ loading: false });
          if (
            this.state.gradeOptions.find(
              (grade) =>
                Number(grade.id) == Number(this.state.data.application.grade)
            ).special_behavior
          ) {
            this.setState({ loading: false, finished: true });
          } else {
            this.props.registerStep.setState({
              grade: this.state.data.application.grade,
              step3: this.state.data,
            });
            this.setState({ redirect: "/register/4" });
          }
        }.bind(this)
      )
      .catch(
        function (error) {
          this.setState({ errors: error.response.data["0"], loading: false });
          var errors = this.getNameErrors(error.response.data[0]);
          this.findElementToScroll(errors);
        }.bind(this)
      );
  }

  findElementToScroll(errors) {
    for (var element in this.componentRef) {
      if (errors.includes(element)) {
        this.scrollToNode(this.componentRef[`${String(element)}`]);
        break;
      }
    }
  }

  getNameErrors(errors) {
    var keyNames = [];
    var keys = Object.keys(errors);
    keys.forEach((key) => {
      var name = key.split(".");
      keyNames.push(name[name.length - 1]);
    });
    return keyNames;
  }

  scrollToNode(node) {
    if (node) node.scrollIntoView({ block: "start", behavior: "smooth" });
  }

  renderError(e) {
    if (this.hasError(e)) {
      return (
        <div class="text-danger">
          <Icon.XOctagon size="1em" />
          &nbsp;{this.state.errors[e]}
        </div>
      );
    }
  }

  hasError(e) {
    var keys = Object.keys(this.state.errors);
    return keys.includes(e);
  }

  renderEditSchool() {
    if (this.state.schoolEdit === true) {
      return <SchoolForm collection={this} />;
    } else if (typeof this.state.schoolEdit == "number") {
      return (
        <SchoolForm
          collection={this}
          index={this.state.schoolEdit}
          model={this.state.schools[this.state.schoolEdit]}
        />
      );
    } else {
      return null;
    }
  }

  renderSchools() {
    var schools = [];

    this.state.schools.forEach(function (item, index) {
      schools.push(<School collection={this} model={item} index={index} />);
    }, this);

    if (this.state.schools.length == "0") {
      schools.push(
        <tr>
          <td class="text-center" colspan="4">
            Veuillez ajouter 3 années pour continuer&nbsp;&nbsp;
            <button class="btn btn-xs btn-primary" onClick={this.addSchool}>
              <Icon.Plus size="1em" />
              &nbsp;Ajouter
            </button>
          </td>
        </tr>
      );
    } else if (this.state.schools.length == "1") {
      schools.push(
        <tr>
          <td class="text-center" colspan="4">
            Veuillez ajouter 2 années pour continuer&nbsp;&nbsp;
            <button class="btn btn-xs btn-primary" onClick={this.addSchool}>
              <Icon.Plus size="1em" />
              &nbsp;Ajouter
            </button>
          </td>
        </tr>
      );
    } else if (this.state.schools.length == "2") {
      schools.push(
        <tr>
          <td class="text-center" colspan="4">
            Veuillez ajouter une année pour continuer&nbsp;&nbsp;
            <button class="btn btn-xs btn-primary" onClick={this.addSchool}>
              <Icon.Plus size="1em" />
              &nbsp;Ajouter
            </button>
          </td>
        </tr>
      );
    }

    return schools;
  }

  renderDiplomaAvailability() {
    return (
      <div className="form-group">
        <label className="form-control-label">En possession du diplôme*</label>
        <div className="input-group">
          <div className="form-control">
            <div className="form-check-inline">
              <label className="form-check-label">
                <input
                  type="radio"
                  className="form-check-input"
                  name="diploma_available"
                  value="1"
                  onChange={this.onChangeDiplomaAvailability}
                  checked={
                    Number(this.state.data.diploma_available) === 1
                      ? true
                      : false
                  }
                />
                Oui
              </label>
            </div>
            <div className="form-check-inline">
              <label className="form-check-label">
                <input
                  type="radio"
                  className="form-check-input"
                  name="diploma_available"
                  value="0"
                  onChange={this.onChangeDiplomaAvailability}
                  defaultChecked
                  checked={
                    Number(this.state.data.diploma_available) === 0
                      ? true
                      : false
                  }
                />
                Non
              </label>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderSATFields() {
    if (Number(this.state.data.diploma_available) === 1) {
      return (
        <div>
          <div
            ref={(node) => (this.componentRef["sat_ebrw_grade"] = node)}
            className="form-group"
          >
            <label className="form-control-label">Note Anglais*</label>
            <div className="input-group">
              <input
                type="text"
                className="form-control form-control-prepend"
                id="input-name"
                name="sat_ebrw_grade"
                onChange={this.changeBaseCursus}
                value={this.state.data.sat_ebrw_grade}
                placeholder="ex. 12"
              />
            </div>
            {this.renderError("data.sat_ebrw_grade")}
          </div>
          <div
            ref={(node) => (this.componentRef["sat_algebra_grade"] = node)}
            className="form-group"
          >
            <label className="form-control-label">Note Maths*</label>
            <div className="input-group">
              <input
                type="text"
                className="form-control form-control-prepend"
                id="input-name"
                name="sat_algebra_grade"
                onChange={this.changeBaseCursus}
                value={this.state.data.sat_algebra_grade}
                placeholder="ex. 18"
              />
            </div>
            {this.renderError("data.sat_algebra_grade")}
          </div>
          <div
            ref={(node) => (this.componentRef["diploma_year"] = node)}
            className="form-group"
          >
            <label className="form-control-label">Année d'obtention*</label>
            <div className="input-group">
              <input
                type="text"
                className="form-control form-control-prepend"
                id="input-name"
                name="diploma_year"
                onChange={this.changeBaseCursus}
                value={this.state.data.diploma_year}
                placeholder="ex. 2018"
              />
            </div>
            {this.renderError("data.diploma_year")}
          </div>
          <div
            ref={(node) => (this.componentRef["diploma_place"] = node)}
            className="form-group"
          >
            <label className="form-control-label">Lieu d'obtention*</label>
            <div className="input-group">
              <input
                type="text"
                className="form-control form-control-prepend"
                id="input-name"
                name="diploma_place"
                onChange={this.changeBaseCursus}
                value={this.state.data.diploma_place}
                placeholder="ex. Antananarivo"
              />
            </div>
            {this.renderError("data.diploma_place")}
          </div>
        </div>
      );
    } else {
      return "";
    }
  }

  renderBaccalaureateFields() {
    if (Number(this.state.data.diploma_available) === 1) {
      return (
        <div>
          <div
            ref={(node) =>
              (this.componentRef["baccalaureate_acknowledgement"] = node)
            }
            className="form-group"
          >
            <label className="form-control-label">Mention*</label>
            <div className="input-group">
              <select
                className="form-control"
                id="input-name"
                name="baccalaureate_acknowledgement"
                onChange={this.changeBaseCursus}
                value={this.state.data.baccalaureate_acknowledgement}
              >
                <option value="" disabled selected>
                  Veuillez choisir
                </option>
                {this.state.baccalaureateAcknowledgementOptions.length
                  ? this.state.baccalaureateAcknowledgementOptions.map(
                      ({ id, name }) => <option value={id}>{name}</option>
                    )
                  : null}
              </select>
            </div>
            {this.renderError("data.baccalaureate_acknowledgement")}
          </div>
          <div
            ref={(node) => (this.componentRef["baccalaureate_grade"] = node)}
            className="form-group"
          >
            <label className="form-control-label">Moyenne Baccalauréat*</label>
            <div className="input-group">
              <input
                type="text"
                className="form-control form-control-prepend"
                id="input-name"
                name="baccalaureate_grade"
                onChange={this.changeBaseCursus}
                value={this.state.data.baccalaureate_grade}
                placeholder="ex. 15"
              />
            </div>
            {this.renderError("data.baccalaureate_grade")}
          </div>
          <div
            ref={(node) => (this.componentRef["diploma_year"] = node)}
            className="form-group"
          >
            <label className="form-control-label">Année d'obtention*</label>
            <div className="input-group">
              <input
                type="text"
                className="form-control form-control-prepend"
                id="input-name"
                name="diploma_year"
                onChange={this.changeBaseCursus}
                value={this.state.data.diploma_year}
                placeholder="ex. 2015"
              />
            </div>
            {this.renderError("data.diploma_year")}
          </div>
          <div
            ref={(node) => (this.componentRef["diploma_place"] = node)}
            className="form-group"
          >
            <label className="form-control-label">Lieu d'obtention*</label>
            <div className="input-group">
              <input
                type="text"
                className="form-control form-control-prepend"
                id="input-name"
                name="diploma_place"
                onChange={this.changeBaseCursus}
                value={this.state.data.diploma_place}
                placeholder="ex. Antananarivo"
              />
            </div>
            {this.renderError("data.diploma_place")}
          </div>
        </div>
      );
    } else {
      return "";
    }
  }

  renderBaseDiploma() {
    switch (this.state.data.type) {
      case "1":
        return (
          <div>
            <div
              ref={(node) => (this.componentRef["baccalaureate_type"] = node)}
              class="form-group"
            >
              <label class="form-control-label">Série Baccalauréat*</label>
              <div class="input-group">
                <select
                  class="form-control"
                  id="input-name"
                  name="baccalaureate_type"
                  onChange={this.changeBaseCursus}
                  value={this.state.data.baccalaureate_type}
                >
                  <option value="" disabled selected>
                    Veuillez choisir
                  </option>
                  {this.state.baccalaureateTypeOptions.length
                    ? this.state.baccalaureateTypeOptions.map(
                        ({ id, name }) => <option value={id}>{name}</option>
                      )
                    : null}
                </select>
              </div>
              {this.renderError("data.baccalaureate_type")}
            </div>
            {this.renderDiplomaAvailability()}
            {this.renderBaccalaureateFields()}
          </div>
        );
      case "2":
        return (
          <div>
            {this.renderDiplomaAvailability()}
            {this.renderSATFields()}
          </div>
        );
      default:
        return null;
    }
  }

  renderForm() {
    if (
      this.state.schoolEdit == true ||
      typeof this.state.schoolEdit == "number"
    ) {
      return null;
    }
    return (
      <form>
        <div
          ref={(node) => (this.componentRef["school_year"] = node)}
          class="form-group"
        >
          <label class="form-control-label">Année académique*</label>
          <div class="input-group">
            <select
              class="form-control"
              id="input-name"
              name="schoolYears"
              value={this.state.data.application.schoolYears}
              onChange={this.changeApplication}
            >
              <option value="" disabled selected>
                Veuillez choisir
              </option>
              {this.state.schoolYearsOptions.map(({ id, name }) => (
                <option value={id}> {name} </option>
              ))}
            </select>
          </div>
          {this.renderError("data.application.school_year")}
        </div>
        <div
          ref={(node) => (this.componentRef["entrance_grade"] = node)}
          class="form-group"
        >
          <label class="form-control-label">Entrée en*</label>
          <div class="input-group">
            <select
              class="form-control"
              id="input-name"
              name="grade"
              value={this.state.data.application.grade}
              onChange={this.changeApplication}
            >
              <option value="" disabled selected>
                Veuillez choisir
              </option>
              {this.state.gradeOptions.map(({ id, name }) => (
                <option value={id}> {name} </option>
              ))}
            </select>
          </div>
          {this.renderError("data.application.entrance_grade")}
        </div>
        <div
          ref={(node) => (this.componentRef["cursus"] = node)}
          class="form-group"
        >
          <label class="form-control-label">Parcours envisagé*</label>
          <div class="input-group">
            <select
              class="form-control"
              id="input-name"
              name="cursuses"
              value={this.state.data.application.cursuses}
              onChange={this.changeApplication}
            >
              <option value="" disabled selected>
                Veuillez choisir
              </option>
              {this.state.cursusesOptions.length
                ? this.state.cursusesOptions[
                    this.state.data.application.grade == ""
                      ? 0
                      : this.state.data.application.grade
                  ].map(({ id, name }) => <option value={id}>{name}</option>)
                : null}
            </select>
          </div>
          {this.renderError("data.application.cursus")}
        </div>
        {Number(this.state.data.application.grade) === 1 ? (
          <>
            <div
              ref={(node) =>
                (this.componentRef["entrance_exam_session"] = node)
              }
              class="form-group"
            >
              <label class="form-control-label">Session de concours*</label>
              <div class="input-group">
                <select
                  class="form-control"
                  id="input-name"
                  name="examSession"
                  value={this.state.data.application.examSession}
                  onChange={this.changeApplication}
                >
                  <option value="" disabled selected>
                    Veuillez choisir
                  </option>
                  {this.state.examSessionOptions.length
                    ? this.state.examSessionOptions.map(({ id, name }) => (
                        <option value={id}>{name}</option>
                      ))
                    : null}
                </select>
              </div>
              {this.renderError("data.application.entrance_exam_session")}
            </div>
          </>
        ) : null}
        <div>
          <label
            ref={(node) => (this.componentRef["schools"] = node)}
            class="form-control-label"
          >
            Veuillez renseigner vos 3 dernières années scolaires*
          </label>
          <table class="table">
            <thead>
              <th>Ecole</th>
              <th>Classe</th>
              <th>Année</th>
              <th>Modifier</th>
            </thead>
            {this.renderSchools()}
          </table>
          {this.renderError("data.schools")}
        </div>
        {this.state.special_behavior ? (
          <>
            <div
              ref={(node) => (this.componentRef["obtained_diploma"] = node)}
              class="form-group"
            >
              <label class="form-control-label">Diplôme obtenu*</label>
              <div class="input-group">
                <select
                  class="form-control"
                  id="input-name"
                  name="obtained_diploma"
                  value={this.state.data.obtained_diploma}
                  onChange={this.changeBaseCursus}
                >
                  <option value="" disabled selected>
                    Veuillez choisir
                  </option>
                  {this.state.obtainedDiplomaOptions.length
                    ? this.state.obtainedDiplomaOptions.map(({ id, name }) => (
                        <option value={id}>{name}</option>
                      ))
                    : null}
                </select>
              </div>
              {this.renderError("data.obtained_diploma")}
            </div>
            {Number(this.state.data.obtained_diploma) === 105 ? (
              <div
                ref={(node) => (this.componentRef["diploma_name"] = node)}
                className="form-group"
              >
                <label className="form-control-label">Nom du diplôme*</label>
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control form-control-prepend"
                    id="input-name"
                    name="diploma_name"
                    onChange={this.changeBaseCursus}
                    value={this.state.data.diploma_name}
                    placeholder="ex. Licence professionelle"
                  />
                </div>
                {this.renderError("data.diploma_name")}
              </div>
            ) : null}
            <div
              ref={(node) => (this.componentRef["specialization"] = node)}
              className="form-group"
            >
              <label className="form-control-label">Spécialisation</label>
              <div className="input-group">
                <input
                  type="text"
                  className="form-control form-control-prepend"
                  id="input-name"
                  name="specialization"
                  onChange={this.changeBaseCursus}
                  value={this.state.data.specialization}
                  placeholder="Spécialisation"
                />
              </div>
              {this.renderError("data.specialization")}
            </div>
          </>
        ) : (
          <>
            <div
              ref={(node) => (this.componentRef["type"] = node)}
              class="form-group"
            >
              <label class="form-control-label">Système scolaire*</label>
              <div class="input-group">
                <select
                  class="form-control"
                  id="input-name"
                  onChange={this.changeType}
                  value={this.state.data.type}
                >
                  <option value="" disabled selected>
                    Veuillez choisir
                  </option>
                  <option value="1">Baccalauréat</option>
                  <option value="2">SAT</option>
                </select>
              </div>
              {this.renderError("data.type")}
            </div>
            {this.renderBaseDiploma()}
          </>
        )}
      </form>
    );
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }

    return (
      <>
        <div
          class="modal fade"
          id="examSessionDeadlinePassed"
          tabindex="-1"
          aria-labelledby="examSessionDeadlinePassed"
          aria-hidden="true"
        >
          <div class="modal-dialog">
            <div class="modal-content border border-danger">
              <div class="modal-header bg-danger">
                <h5 class="modal-title" id="examSessionDeadlinePassed">
                  <span class="text-white">Erreur</span>
                </h5>

                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                Vous ne pouvez plus sélectionner cette session car le délai de
                réception des documents de candidature a été dépassé
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-secondary btn-sm"
                  data-dismiss="modal"
                >
                  Fermer
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="register-right-pane container-fluid d-flex flex-column">
          <div class="row align-items-center justify-content-center justify-content-lg-end min-vh-100">
            <div class="scrollable-content col-sm-7 col-lg-6 col-xl-6 py-6 py-lg-0 position-absolute h-100 top-0 right-0">
              <div class="row justify-content-center">
                <div class="col-11 col-lg-10 col-xl-10">
                  {this.state.loadingFetch && <Loading />}
                  {this.renderEndStep()}
                  {!this.state.finished ? (
                    <div>
                      <div class="mt-5 mb-5">
                        <h6 class="h3 mb-1">Inscription Concours ISCAM</h6>
                        <p class="text-muted mb-0">Scolarité</p>
                      </div>
                      <div class="clearfix"></div>
                      {this.renderForm()}
                      {this.renderEditSchool()}
                      <div
                        class={"mt-4 mb-4".concat(
                          typeof this.state.schoolEdit == "number" ||
                            this.state.schoolEdit
                            ? " disabled"
                            : ""
                        )}
                      >
                        {this.state.loading ? (
                          <button
                            type="button"
                            class="btn btn-block btn-primary"
                            disabled
                          >
                            <span
                              class="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          </button>
                        ) : (
                          <button
                            type="button"
                            class="btn btn-block btn-primary"
                            disabled={this.state.examSessionDeadlinePassed}
                            onClick={this.onSubmit}
                          >
                            Valider
                          </button>
                        )}
                      </div>
                      <p>
                        Si vous souhaitez poursuivre votre inscription plus
                        tard, merci d’accéder à votre dossier à travers le mail
                        que vous avez reçu de{" "}
                        <a href="mailto:information@iscam.mg">
                          information@iscam.mg
                        </a>{" "}
                        en validant cette étape.
                        <br />
                        Merci de vérifier dans le Spam si vous ne voyez pas le
                        mail dans votre boite de réception.
                      </p>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default Step3;
