import React, { Component } from "react";
import { Redirect } from "react-router";
import * as Icon from "react-feather";
import API from "../../Api";
import _ from "lodash";

import { InputFile, EndStep, Loading } from "../../components/Components";
import pdfIcon from "../../assets/img/icons/pdf-icon.png";
import { getMomentLocaleData } from "fullcalendar";

class Step5 extends Component {
  constructor(props) {
    super(props);

    /*if (this.props.registerStep.state.step3 === null) {
      this.state = { redirect: "/register/3" };
      return;
    }*/

    if (this.props.token) {
      API.defaults.headers.common["X-AUTH-TOKEN"] = this.props.token;
      localStorage.setItem(
        "X-AUTH-TOKEN_old",
        JSON.stringify(this.props.token)
      );
    }

    this.state = {
      loading: false,
      loadingFetch: false,
      redirect: "",
      errors: {},
      success: false,
      data: {
        documents: [
          {
            type: 200,
            document: "",
            fileName: "",
            mimeType: "",
          },
          {
            type: 210,
            document: "",
            fileName: "",
            mimeType: "",
          },
          {
            type: 220,
            document: "",
            fileName: "",
            mimeType: "",
          },
          {
            type: 230,
            document: "",
            fileName: "",
            mimeType: "",
          },
        ],
      },
    };

    this.componentRef = {
      "documents[0]": null,
      "documents[1]": null,
      "documents[2]": null,
      "documents[3]": null,
    };

    this.findElementToScroll = this.findElementToScroll.bind(this);
    this.onChangeFile = this.onChangeFile.bind(this);
    this.hasError = this.hasError.bind(this);
    this.renderError = this.renderError.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.renderEndStep = this.renderEndStep.bind(this);
    this.getData = this.getData.bind(this);
  }

  componentDidMount() {
    if (_.has(localStorage, "X-AUTH-TOKEN_old")) {
      API.defaults.headers.common["X-AUTH-TOKEN"] = JSON.parse(
        localStorage.getItem("X-AUTH-TOKEN_old")
      );
      this.getData();
    }
  }

  getData() {
    this.setState({ loadingFetch: true });
    API.get("/old/register/step-5").then(
      function (response) {
        this.setState({ loadingFetch: false });
        var response = response.data["0"];
        if (Array.isArray(response) && response.length) {
          var docs = this.state.data.documents;

          for (var i = 0; i < 4; i++) {
            docs[i].type = response[i].type;
            docs[i].document = response[i].document;
            docs[i].fileName = response[i].fileName;
            docs[i].mimeType = response[i].mimeType;
          }
          var data = this.state.data;
          data.documents = [...docs];

          this.setState({ data: data });
        }
      }.bind(this)
    );
  }

  onChangeFile(event, file) {
    var documents = this.state.data.documents;

    if (event.nativeEvent.error) {
      documents.forEach((doc) => {
        if (doc.type == event.target.name) {
          doc.document = "";
          doc.fileName = "";
          doc.mimeType = "";
        }
      });
      return this.setState({ data: { documents } });
    }

    event.persist();
    var reader = new FileReader();
    let b64 = "";

    reader.onload = () => {
      b64 = window.btoa(reader.result);
      var docs = this.state.data.documents;
      docs.forEach((doc) => {
        if (doc.type == event.target.name) {
          doc.document = b64;
          doc.fileName = event.target.files[0].name;
          doc.mimeType = event.target.files[0].type.split("/")[1];
        }
      });
      var data = this.state.data;
      data.documents = [...docs];
      this.setState({ data: data });
    };
    reader.readAsBinaryString(event.target.files[0]);
  }

  renderError(e) {
    if (this.hasError(e)) {
      return (
        <div class="text-danger">
          <Icon.XOctagon size="1em" />
          &nbsp;{this.state.errors[e]}
        </div>
      );
    }
  }

  hasError(e) {
    if (!_.isEmpty(this.state.errors)) {
      var keys = Object.keys(this.state.errors);
      return keys.includes(e);
    }
  }

  onSubmit() {
    this.setState({ loading: true });
    API.post("/old/register/step-5", this.state.data)
      .then(
        function (response) {
          this.setState({ loading: false, finished: true });
        }.bind(this)
      )
      .catch(
        function (error) {
          this.setState({ errors: error.response.data["0"], loading: false });
          var errors = this.getNameErrors(error.response.data[0]);

          this.findElementToScroll(errors);
        }.bind(this)
      );
  }

  findElementToScroll(errors) {
    for (var element in this.componentRef) {
      if (errors.includes(element)) {
        this.scrollToNode(this.componentRef[`${String(element)}`]);
        break;
      }
    }
  }

  getNameErrors(errors) {
    var keyNames = [];
    var keys = Object.keys(errors);
    keys.forEach((key) => {
      var name = key.split(".");
      if (name[name.length - 1] === "document") {
        keyNames.push(name[name.length - 2]);
      } else {
        keyNames.push(name[name.length - 1]);
      }
    });
    return keyNames;
  }

  scrollToNode(node) {
    node.scrollIntoView({ block: "start", behavior: "smooth" });
  }

  renderEndStep() {
    if (this.state.finished) {
      return <EndStep />;
    } else {
      return null;
    }
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }

    return (
      <div class="register-right-pane container-fluid d-flex flex-column">
        <div class="row align-items-center justify-content-center justify-content-lg-end min-vh-100">
          <div class="scrollable-content col-sm-7 col-lg-6 col-xl-6 py-6 py-lg-0 position-absolute h-100 top-0 right-0">
            <div class="row justify-content-center">
              <div class="col-11 col-lg-10 col-xl-10">
                <div>
                  {this.state.loadingFetch && <Loading />}
                  {this.renderEndStep()}
                  {!this.state.finished ? (
                    <>
                      <div class="mt-5 mb-5">
                        <h6 class="h3 mb-1">Inscription Concours ISCAM</h6>
                        <p class="text-muted mb-0">
                          Documents justificatifs nécessaires
                        </p>
                      </div>
                      <span class="clearfix"></span>
                      <p>
                        Nous avons bien pris en compte les informations que vous
                        avez saisi lors des étapes précédentes.
                        <br />
                        Merci de préparer les documents suivants afin de
                        finaliser votre inscription :
                      </p>
                      <ul>
                        <li>Certificat de résidence de l’étudiant</li>
                        <li>Certificat de résidence du garant</li>
                        <li>Fiche d’engagement légalisée à la mairie</li>
                        <li>
                          Bordereau de versement à la banque de la première
                          tranche de la fiche d’engagement
                        </li>
                      </ul>

                      <form>
                        <div
                          ref={(node) =>
                            (this.componentRef["documents[0]"] = node)
                          }
                          class="form-group"
                        >
                          <label class="form-control-label">
                            Certificat de résidence de l’étudiant*
                          </label>
                          <div class="input-group">
                            <InputFile
                              name="200"
                              fileName={this.state.data.documents[0].fileName}
                              onChange={this.onChangeFile}
                              accept="image/*, .pdf"
                            />
                          </div>
                          {this.state.data.documents[0].document ? (
                            <img
                              class="img-fluid rounded"
                              style={{
                                width: 75,
                                height: 75,
                                objectFit: "cover",
                              }}
                              src={
                                this.state.data.documents[0].mimeType === "pdf"
                                  ? pdfIcon
                                  : "data:image/png;base64," +
                                    this.state.data.documents[0].document
                              }
                            />
                          ) : null}
                          {this.renderError("data.documents[0].document")}
                        </div>
                        <hr />
                        <div
                          ref={(node) =>
                            (this.componentRef["documents[1]"] = node)
                          }
                          class="form-group"
                        >
                          <label class="form-control-label">
                            Certificat de résidence du garant*
                          </label>
                          <div class="input-group">
                            <InputFile
                              name="210"
                              fileName={this.state.data.documents[1].fileName}
                              onChange={this.onChangeFile}
                              accept="image/*, .pdf"
                            />
                          </div>
                          {this.state.data.documents[1].document ? (
                            <img
                              class="img-fluid rounded"
                              style={{
                                width: 75,
                                height: 75,
                                objectFit: "cover",
                              }}
                              src={
                                this.state.data.documents[1].mimeType === "pdf"
                                  ? pdfIcon
                                  : "data:image/png;base64," +
                                    this.state.data.documents[1].document
                              }
                            />
                          ) : null}
                          {this.renderError("data.documents[1].document")}
                        </div>
                        <hr />
                        <div
                          ref={(node) =>
                            (this.componentRef["documents[2]"] = node)
                          }
                          class="form-group"
                        >
                          <label class="form-control-label">
                            Fiche d’engagement légalisée à la mairie*
                          </label>
                          <div class="input-group">
                            <InputFile
                              name="220"
                              fileName={this.state.data.documents[2].fileName}
                              onChange={this.onChangeFile}
                              accept="image/*, .pdf"
                            />
                          </div>
                          {this.state.data.documents[2].document ? (
                            <img
                              class="img-fluid rounded"
                              style={{
                                width: 75,
                                height: 75,
                                objectFit: "cover",
                              }}
                              src={
                                this.state.data.documents[2].mimeType === "pdf"
                                  ? pdfIcon
                                  : "data:image/png;base64," +
                                    this.state.data.documents[2].document
                              }
                            />
                          ) : null}
                          {this.renderError("data.documents[2].document")}
                        </div>
                        <hr />

                        <div
                          ref={(node) =>
                            (this.componentRef["documents[3]"] = node)
                          }
                          class="form-group"
                        >
                          <label class="form-control-label">
                            Bordereau de versement à la banque de la première
                            tranche de la fiche d’engagement*
                          </label>
                          <div class="input-group">
                            <InputFile
                              name="230"
                              fileName={this.state.data.documents[3].fileName}
                              onChange={this.onChangeFile}
                              accept="image/*, .pdf"
                            />
                          </div>
                          {this.state.data.documents[3].document ? (
                            <img
                              class="img-fluid rounded"
                              style={{
                                width: 75,
                                height: 75,
                                objectFit: "cover",
                              }}
                              src={
                                this.state.data.documents[3].mimeType === "pdf"
                                  ? pdfIcon
                                  : "data:image/png;base64," +
                                    this.state.data.documents[3].document
                              }
                            />
                          ) : null}
                          {this.renderError("data.documents[3].document")}
                        </div>
                        <hr />

                        <div class="mt-4 mb-4">
                          {this.state.loading ? (
                            <button
                              type="button"
                              class="btn btn-block btn-primary"
                              disabled
                            >
                              <span
                                class="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              ></span>
                            </button>
                          ) : (
                            <button
                              type="button"
                              onClick={this.onSubmit}
                              class="btn btn-block btn-primary"
                            >
                              Valider
                            </button>
                          )}
                        </div>
                      </form>
                    </>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Step5;
