import React, { useEffect, useState } from "react";

const useHowDidUKnowUs = () => {
  const [sources, setSources] = useState([]);

  useEffect(() => {
    const sources = require("./../../../assets/mocks/how-did-u-know-us.json");

    setSources(sources);
  }, []);

  const sourceList = () => {
    return [
      {
        value: -1,
        label: "Séléctionner",
      },
      ...sources.map((source) => ({
        label: source,
        value: source,
      })),
    ];
  };

  return { sources, sourceList };
};

export { useHowDidUKnowUs };
