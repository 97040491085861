import React, { Component } from "react";
import { Redirect } from "react-router-dom";

import RegisterStep1Background from "../../assets/img/backgrounds/register-step-1.jpg";
import RegisterStep2Background from "../../assets/img/backgrounds/register-step-2.jpg";
import RegisterStep3Background from "../../assets/img/backgrounds/register-step-3.jpg";
import RegisterStep4Background from "../../assets/img/backgrounds/register-step-4.jpg";

import {
  Step6_1_1,
  Step6_1_2,
  Step6_1_3,
  Step6_1_4,
  Step6_2,
  Step6_3,
  Step6_4,
  Step6_5,
  Step6_6,
  Step6_7,
} from "./RegisterSteps";
import * as Icon from "react-feather";
import API from "../../Api";
import _ from "lodash";

class ReviewStep extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step1: null,
      step2: null,
      step3: null,
      grade: null,
    };

    this.renderStep = this.renderStep.bind(this);
    this.entranceGrade = this.entranceGrade.bind(this);
  }

  componentDidMount() {
    if (this.props.token) {
      localStorage.setItem(
        "X-AUTH-TOKEN_new",
        JSON.stringify(this.props.token)
      );
      API.defaults.headers.common["X-AUTH-TOKEN"] = JSON.parse(
        localStorage.getItem("X-AUTH-TOKEN_new")
      );
    } else if (_.has(localStorage, "X-AUTH-TOKEN_new")) {
      API.defaults.headers.common["X-AUTH-TOKEN"] = JSON.parse(
        localStorage.getItem("X-AUTH-TOKEN_new")
      );
      if (this.props.step === "4") {
        this.entranceGrade();
      }
    }
  }

  entranceGrade() {
    API.get("/applicant/entrance-grade").then(
      function (response) {
        this.setState({ step3: true, grade: response.data["0"].id });
      }.bind(this)
    );
  }

  renderStep(step) {
    switch (step) {
      case "1/1":
        return <Step6_1_1 registerStep={this} token={this.props.token} />;

      case "1/2":
        return <Step6_1_2 registerStep={this} token={this.props.token} />;

      case "1/3":
        return <Step6_1_3 registerStep={this} token={this.props.token} />;

      case "1/4":
        return (
          <Step6_1_4
            registerStep={this}
            token={this.props.token}
            grade={this.state.grade}
          />
        );

      case "2":
        return <Step6_2 history={this.props.history} />;

      case "3":
        return <Step6_3 history={this.props.history} />;

      case "4":
        return <Step6_4 history={this.props.history} />;

      case "5":
        return <Step6_5 history={this.props.history} />;

      case "6":
        return <Step6_6 history={this.props.history} />;

      case "7":
        return <Step6_7 history={this.props.history} />;

      default:
        return <Redirect to="/" />;
    }
  }

  renderBackground(step) {
    switch (step) {
      case "1":
        this.leftPane = {
          background: RegisterStep1Background,
          title: "Étape 1 sur 4",
          description:
            "Veuillez remplir vos informations personnelles. Celles-ci sont la base de votre dossier académique.",
        };
        break;
      case "2":
        this.leftPane = {
          background: RegisterStep2Background,
          title: "Étape 2 sur 4",
          description:
            "Veuillez remplir vos informations de filiation. Celles-ci sont requises pour compléter votre dossier.",
        };
        break;
      case "3":
        this.leftPane = {
          background: RegisterStep3Background,
          title: "Étape 3 sur 4",
          description:
            "Veuillez remplir vos informations scolaires. Ces informations nous permettront d'évaluer votre dossier et vous orienter vers le bon cursus.",
        };
        break;
      case "4":
        this.leftPane = {
          background: RegisterStep4Background,
          title: "Étape 4 sur 4",
          description:
            "Veuillez nous envoyer tous vos documents justificatifs, scannés, afin de finaliser votre dossier.",
        };
        break;
      case "5":
        this.leftPane = {
          background: RegisterStep4Background,
          title: "Concours",
          description:
            "Veuillez nous envoyer tous vos documents justificatifs, scannés, afin de finaliser votre dossier.",
        };
        break;
      default:
        this.leftPane = {
          background: RegisterStep1Background,
          title: "Étape 1 sur 4",
          description:
            "Veuillez remplir vos informations personnelles. Celles-ci sont la base de votre dossier académique.",
        };
    }
  }

  renderLeftPane(step) {
    this.renderBackground(step);

    return (
      <div>
        <div
          class="register-left-pane bg-primary position-absolute h-100 top-0 left-0 zindex-100 col-lg-6 col-xl-6 zindex-100 d-none d-lg-flex flex-column justify-content-end"
          data-bg-size="cover"
          data-bg-position="center"
        >
          <img src={this.leftPane.background} alt="Image" class="img-as-bg" />
          {/* <div class="row position-relative zindex-110">
            <div class="step-title">
              <div class="col-md-8 text-center mx-auto">
                <h5 class="h5 text-white mt-3">{this.leftPane.title}</h5>
                <p class="text-white opacity-8">{this.leftPane.description}</p>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    );
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }
    return [
      this.renderLeftPane(this.props.step),
      this.renderStep(this.props.step),
    ];
  }
}

export default ReviewStep;
