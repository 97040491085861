import { unsecured as axios } from "../../../axios";

class EntranceGrade {
  static async findAll() {
    return await axios.get("/list/entrance-grades").then((res) => {
      return res.data.map((level) => ({
        name: level.name,
        id: level.id,
        isParentNeeded: level.parent_needed,
        documents: level.documents,
      }));
    });
  }
}

export { EntranceGrade };
