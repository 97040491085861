import React, { Component } from "react";
import * as Icon from "react-feather";

import RegisterStep1Background from "../assets/img/backgrounds/register-step-1.jpg";
import "./Register.scss";

import { RegisterStep } from "./register-steps/RegisterSteps";
import { CheckDevice, Loading } from "../components/Components";

import API from "../Api";
import { withRouter } from "react-router-dom";

class Register extends Component {
  constructor(props) {
    super(props);

    this.state = {
      profile: {
        firstname: "",
        lastname: "",
        email: "",
        status: "",
        step: "",
        assignment_id: "",
        exam_session_id: "",
      },
    };

    this.goBack = this.goBack.bind(this);
  }

  componentDidMount() {
    if (this.props.token) {
      API.defaults.headers.common["X-AUTH-TOKEN"] = this.props.token;
      this.applicantProfile();
    }
  }

  goBack() {
    if (this.props.step == "1") this.props.history.push("/");
    else this.props.history.push(`/register/${this.props.step - 1}`);
  }

  applicantProfile() {
    API.get("/applicant/profile").then((response) => {
      const profile = response.data["0"];
      this.setState({ profile });
      localStorage.setItem(
        "X-AUTH-TOKEN_new",
        JSON.stringify(this.props.token)
      );
      if (profile.reviewStep) {
        if (profile.reviewStep > 10) {
          this.props.history.push(
            `/register/review/${profile.reviewStep.toString()[0]}/${
              profile.reviewStep.toString()[1]
            }`
          );
        } else
          this.props.history.push(`/register/review/${profile.reviewStep}`);
      } else {
        this.props.history.push(`/register/${profile.step}`);
      }
    });
  }

  render() {
    const isMobile = CheckDevice();

    if (isMobile)
      return (
        <div
          style={{
            width: "100vw",
            height: "100vh",
            padding: 10,
            textAlign: "center",
            fontSize: "18px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <span>
            Afin de pouvoir procéder à l'inscription, il vous sera nécessaire
            d'envoyer des documents. Merci de vous connecter à partir d'un
            ordinateur pour poursuivre.
          </span>
        </div>
      );

    return (
      <div>
        {this.props.token ? (
          <Loading />
        ) : (
          <>
            {Number(this.props.step) !== 5 ? (
              <a
                class="btn btn-white btn-icon-only rounded-circle position-absolute zindex-101 left-4 top-4 d-none d-lg-inline-flex"
                data-toggle="tooltip"
                data-placement="right"
                title="Retourner à l'étape précédente"
                onClick={this.goBack}
              >
                <span class="btn-inner--icon">
                  <Icon.ArrowLeft size="1em" />
                </span>
              </a>
            ) : null}
            <section>
              <RegisterStep token={this.props.token} step={this.props.step} />
            </section>
          </>
        )}
      </div>
    );
  }
}

export default withRouter(Register);
