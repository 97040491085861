import React, { useContext } from "react";
import * as Icon from "react-feather";
import EditWarning from "../EditWarning";
import { StepProvider } from "../Inscription";

const StepContainer = ({
  image,
  title,
  desciption,
  children,
  enableGoBack = false,
}) => {
  const { canGoBack, goBack } = useContext(StepProvider);

  return (
    <div className="h-100">
      <div className="h-100 d-flex">
        <div className="col-6 h-100 d-flex position-relative p-0">
          <img
            src={image}
            className="position-absolute left-0 top-0 w-100 h-100"
            style={{ objectFit: "cover" }}
          />
          <div
            className="step-title align-self-end w-100"
            style={{ backgroundColor: "rgba(0,0,0,0.3)", zIndex: 1 }}
          >
            <div className="col-md-8 text-center mx-auto">
              <h5 className="h5 text-white mt-3">{title}</h5>
              <p className="text-white opacity-8">{desciption}</p>
            </div>
          </div>
        </div>
        <div className="col-6 pb-6 px-4" style={{ overflowY: "auto" }}>
          <div className="d-flex w-100 justify-content-center" >
            <EditWarning/>
          </div>
          {children}
        </div>
        {enableGoBack && canGoBack() && (
          <a
            class="btn btn-white btn-icon-only rounded-circle position-absolute zindex-2 left-4 top-4 d-none d-lg-inline-flex"
            href="javascript:void(0);"
            title="Retourner à l'étape précédente"
            onClick={goBack}
          >
            <span class="btn-inner--icon">
              <Icon.ArrowLeft size="1em" />
            </span>
          </a>
        )}
      </div>
    </div>
  );
};

export default StepContainer;
