import React, { Component } from "react";
import * as Icon from "react-feather";

class Person extends Component {
  constructor(props) {
    super(props);

    this.model = props.model;
    this.index = props.index;
    this.collection = props.collection;

    this.getReadableType = this.getReadableType.bind(this);
  }

  getReadableType() {
    switch (this.model.type) {
      case "20":
        return "Père";
      case "30":
        return "Mère";
      case "40":
        return "Tuteur";
    }
  }

  render() {
    return (
      <div class="poi-person mt-4">
        <strong>{this.getReadableType()}</strong>
        <button
          class="btn btn-xs btn-danger float-right"
          onClick={() => this.collection.deletePoi(this.index)}
          disabled={this.props.reviewStep && !this.props.modificationEnabled}
        >
          <Icon.Trash size="1em" />
          &nbsp;Supprimer
        </button>
        <br />
        {this.model.last_name}&nbsp;{this.model.first_name}
        <br />
        {this.model.phone}
        <br />
        {this.model.primary_email}
      </div>
    );
  }
}

export default Person;
