import { useState } from "react";

export const INSCRIPTION_STEP = 1;
export const SCHOOLING_STEP = 2;
export const SUCCESSFULL_STEP = 3;

const STORAGE_KEY = 'currentStep'

const queryParameters = new URLSearchParams(window.location.search)

const getCurrentStep = () => {

  const step = queryParameters.get('step')
  if( null !== step )
  {
    localStorage.setItem(STORAGE_KEY, step)

    return parseInt(step)
  }

  if(null !== localStorage.getItem(STORAGE_KEY)){
    return parseInt(localStorage.getItem(STORAGE_KEY))
  }

  localStorage.setItem(STORAGE_KEY, INSCRIPTION_STEP)

  return INSCRIPTION_STEP
}

const useFormStep = () => {
  
  const [currentStep, setCurrentStep] = useState(getCurrentStep());

  const canGoBack = () => currentStep > 1;
  
  const goBack = () => {
    localStorage.setItem(STORAGE_KEY, currentStep - 1)
    setCurrentStep((step) => step - 1)
  }

  const goNext = () => {
    localStorage.setItem(STORAGE_KEY, currentStep + 1)
    setCurrentStep((step) => step + 1)
  }

  const goTo = step => {
    localStorage.setItem(STORAGE_KEY, step)
    setCurrentStep(step)
  }

  const resetStep = () => {
    localStorage.clear()
    window.location.reload()
  }

  return { currentStep, canGoBack, goBack, goNext, resetStep, goTo };
};

export { useFormStep };

