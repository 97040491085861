import React, { Component } from "react";
import * as Icon from "react-feather";
import _ from "lodash";
import Api from "../../Api";
import Loading from "../../components/Loading";

class Step6_7 extends Component {
  state = {
    profile: {},
    loadingFetch: false,
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    if (_.has(localStorage, "X-AUTH-TOKEN_new")) {
      Api.defaults.headers.common["X-AUTH-TOKEN"] = JSON.parse(
        localStorage.getItem("X-AUTH-TOKEN_new")
      );
      this.getApplicantProfile();
    }
  }

  getApplicantProfile() {
    this.setState({ loadingFetch: true });
    return Api.get("/applicant/profile").then(
      async ({ data: { 0: profile } }) => {
        this.setState({
          loadingFetch: false,
          profile,
        });
      }
    );
  }

  render() {
    return (
      <div class="register-right-pane container-fluid d-flex flex-column">
        <div class="row align-items-center justify-content-center justify-content-lg-end min-vh-100">
          <div class="scrollable-content col-sm-7 col-lg-6 col-xl-6 py-6 py-lg-0 position-absolute h-100 top-0 right-0">
            <div class="row justify-content-center">
              <div class="col-11 col-lg-10 col-xl-10">
                {this.state.loadingFetch && <Loading />}

                <div className="mt-5 mb-5">
                  <h6 class="h3 mb-1">
                    VOTRE NUMÉRO MATRICULE et ADRESSE EMAIL
                  </h6>
                  <p>
                    Votre processus d’inscription à l’ISCAM a abouti.Nous sommes
                    heureux de vous accueillir dans la grande famille
                    iscamienne. <br /> <br />
                    Votre numéro matricule (identification unique en tant que
                    iscamien) est le: <b>
                      {this.state.profile.login}
                    </b> <br /> <br />
                    Votre adresse email en tant que iscamien est le suivant:{" "}
                    <b>{this.state.profile.email_iscam}</b>. <br /> <br />
                    Afin de nous permettre de mieux communiquer, nous vous
                    prions désormais d’utiliser votre adresse email d’iscamien.
                    L’institut ne reconnaitra aucune autre adresse que celle-ci
                    et nous vous prions de l’utiliser pour toutes vos
                    communications avec l’administration.
                  </p>
                </div>

                <button
                  className="btn btn-warning btn-block"
                  onClick={() =>
                    Api.get("/applicant/school_certificate", {
                      responseType: "blob",
                    }).then((response) => {
                      const url = window.URL.createObjectURL(
                        new Blob([response.data])
                      );
                      const link = document.createElement("a");
                      link.href = url;
                      link.setAttribute("download", "school_certificate.pdf");
                      document.body.appendChild(link);
                      link.click();
                    })
                  }
                >
                  <Icon.Download size="1em" />
                  Télécharger le certificat de scolarité
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Step6_7;
