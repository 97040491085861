import React, { Component } from "react";
import _ from "lodash";
import * as Icon from "react-feather";
import Api from "../../Api";
import Loading from "../../components/Loading";

class Step6_3 extends Component {
  state = {
    loadingFetch: false,
    payment_id: null,
  };

  constructor(props) {
    super(props);

    if (this.props.token) {
      Api.defaults.headers.common["X-AUTH-TOKEN"] = this.props.token;
      localStorage.setItem(
        "X-AUTH-TOKEN_new",
        JSON.stringify(this.props.token)
      );
    }

    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    if (_.has(localStorage, "X-AUTH-TOKEN_new")) {
      Api.defaults.headers.common["X-AUTH-TOKEN"] = JSON.parse(
        localStorage.getItem("X-AUTH-TOKEN_new")
      );
      this.getApplicantProfile();
    }
  }

  getApplicantProfile() {
    this.setState({ loadingFetch: true });
    return Api.get("/applicant/profile").then(
      async ({ data: { 0: profile } }) => {
        this.setState({
          loadingFetch: false,
          payment_id: Number(profile.payment_id),
        });
      }
    );
  }

  onSubmit() {
    this.props.history.push("/register/review/4");
  }

  render() {
    return (
      <div className="register-right-pane container-fluid d-flex flex-column">
        <div className="row align-items-center justify-content-center justify-content-lg-end min-vh-100">
          <div className="scrollable-content col-sm-7 col-lg-6 col-xl-6 py-6 py-lg-0 position-absolute h-100 top-0 right-0">
            <div className="row justify-content-center">
              <div className="col-11 col-lg-10 col-xl-10">
                {this.state.loadingFetch && <Loading />}
                <div className="mt-5 mb-5">
                  <h6 class="h3 mb-1">VOTRE FICHE D’ENGAGEMENT</h6>
                  <p>
                    Votre choix de modalité de paiement a été enregistré. Merci
                    de télécharger la fiche d’engagement correspondant à votre
                    choix. Cette fiche d’engagement est à signer par les parents
                    ou tuteurs / garants. Les signatures doivent être
                    légalisées.
                  </p>
                </div>
                <button
                  className={"btn btn-block btn-warning"}
                  disabled={this.state.loadingFetch}
                  onClick={() =>
                    Api.get(`/commitment/payment/${this.state.payment_id}`, {
                      responseType: "blob",
                    }).then((response) => {
                      const url = window.URL.createObjectURL(
                        new Blob([response.data])
                      );
                      const link = document.createElement("a");
                      link.href = url;
                      link.setAttribute("download", "commitment.pdf"); //or any other extension
                      document.body.appendChild(link);
                      link.click();
                    })
                  }
                >
                  <Icon.Download size="1em" />
                  &nbsp;Télécharger le fichier
                </button>
                <span className="clearfix"></span>
                <button
                  type="button"
                  class="btn btn-block btn-primary mt-4"
                  onClick={this.onSubmit}
                >
                  Suivant
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Step6_3;
