import React, { useContext } from "react";
import { StepProvider } from "../Inscription";

const Success = () => {

  const {resetStep} = useContext(StepProvider)

  return (
    <>
      <section className="slice slice-lg bg-primary">
        <div className="w-100 h-100 d-flex justify-content-center align-items-center">
          <div className="card p-5" style={{ width: "50rem" }}>
            <h3>Votre inscription a bien été prise en compte </h3>
            <p>
              Il est en cours de validation. Vous recevrez une notification dans
              les prochains jours sur l'avancement de votre situation
              d'inscription définitive.
            </p>
          </div>
        </div>
        <div className="shape-container shape-position-bottom shape-line">
          <svg
            width="2560px"
            height="100px"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            preserveAspectRatio="none"
            x="0px"
            y="0px"
            viewBox="0 0 2560 100"
            style={{ enableBackground: "new 0 0 2560 100" }}
            xmlSpace="preserve"
            class=""
          >
            <polygon points="2560 0 2560 100 0 100"></polygon>
          </svg>
        </div>
      </section>
      <div className="d-flex justify-content-center my-5" >
        <button className="btn btn-primary" type="button" onClick={resetStep}>Revenir à l'inscription</button>
      </div>
    </>
  );
};

export default Success;
