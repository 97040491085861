import React, { Component } from "react";
import { Redirect } from "react-router";
import * as Icon from "react-feather";
import Illustration from "../assets/img/svg/illustrations/undraw_Checklist__re_2w7v.svg";

class EndStep extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: "",
    };
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }

    return (
      <div class="poi-edit mb-3 mt-4">
        <h1>
          Félicitations ! <br></br> Inscription effectuée
        </h1>
        <h4>{this.props.message ? this.props.message : null}</h4>
        <img src={Illustration} className="img-fluid w-100 h-100 mt-5" />
        <button
          type="button"
          class="btn btn-block btn-primary"
          onClick={() => {
            this.setState({ redirect: "/" });
          }}
        >
          Terminer
        </button>
      </div>
    );
  }
}
export default EndStep;
