import React from "react";

const Input = (props) => {
  return (
    <div className="form-group">
      <label>
        {props.label} {props.required && <span>*</span>}
      </label>
      <input
        className="form-control"
        style={{
          ...(props.hasError
            ? { borderColor: "red", height: 42 }
            : { height: 42, borderColor: "#cccccc" }),
        }}
        onChange={(e) => props.onChange && props.onChange(e.target.value)}
        value={props.value}
        name={props.name}
        placeholder={props.placeholder}
        type={props.type}
      />
      {props.message && (
        <small
          style={{
            ...(props.hasError
              ? {
                  color: "red",
                  fontWeight: "bold",
                  fontSize: 12,
                  textAlign: "right",
                }
              : { fontSize: 12, textAlign: "right" }),
          }}
        >
          {props.message}
        </small>
      )}
    </div>
  );
};

export default Input;
