import React, { Component } from "react";
import * as Icon from "react-feather";

export default class InputFile extends Component {
  constructor(props) {
    super(props);
    this.inputFile = React.createRef();
    this.state = {
      fileTooLarge: false,
    };

    this.onClick = this.onClick.bind(this);
  }

  onClick(event) {
    this.inputFile.current.click();
  }

  /* onChange={(event) => {
            this.props.onChange(event, this.inputFile.current.files[0]);
          }}*/

  render() {
    return (
      <tr>
        <td>
          <button
            type="button"
            class="mr-2 mb-1 btn btn-warning btn-sm"
            onClick={this.onClick}
            disabled={this.props.disabled}
            hidden={this.props.hideButton}
          >
            Ajouter un fichier
          </button>
        </td>
        <td>
          <h6 class="text-muted">{this.props.fileName}</h6>
        </td>
        <input
          id={this.props.id}
          ref={this.inputFile}
          type="file"
          name={this.props.name}
          class="d-none form-control-file"
          onChange={(event) => {
            event.persist();
            const size = event.target.files[0].size / 2 ** 20;

            if (size > 5) {
              this.setState({ fileTooLarge: true });
              event.nativeEvent.error = true;
            } else if (this.state.fileTooLarge)
              this.setState({ fileTooLarge: false });

            this.props.onChange(event);
          }}
          disabled={this.props.disabled}
          accept={this.props.accept}
        />
        {this.state.fileTooLarge ? (
          <div class="text-danger">
            <Icon.XOctagon size="1em" />
            &nbsp; Le fichier dépasse 5 Mo
          </div>
        ) : null}
      </tr>
    );
  }
}
