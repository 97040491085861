import React, { useContext } from "react";
import * as Icon from "react-feather";
import { EditingContext } from "./Inscription";

const EditWarning = () => {

  const {currentEmail, isEditing} = useContext(EditingContext)

  return isEditing ? (
    <div class="alert alert-warning mt-4 w-75" role="alert">
      <div className="row align-items-center pr-2 pl-3 mb-2">
        <Icon.AlertTriangle size="3em" />
        <span className="col">
          Attention ! Vous êtes en train de modifier
          l’utilisateur <u>{currentEmail}</u>. Si
          vous souhaitez créer une nouvelle inscription cliquez
          sur <strong>Nouvelle Inscription</strong>
        </span>
      </div>
      <button
        type="button"
        class="btn btn-light btn-sm float-right"
        onClick={ () => window.location.href = '/inscription' }
      >
        Nouvelle Inscription
      </button>
    </div>
  ): <></>
}

export default EditWarning