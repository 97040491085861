import { unsecured as axios } from "../../../axios";

class ExamSession {
  static async findAll() {
    return await axios
      .get("list/entrance-exam-session")
      .then((res) => res.data);
  }
}

export { ExamSession };
