import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { InputFile } from "../../../components";
import Select from "../../../components/Select";
import { useEntranceCursusList } from "../hooks/useEntraceCursusList";
import { useEntraceGradeList } from "../hooks/useEntranceGradeList";
import { useExamSessionList } from "../hooks/useExamSessionList";
import stepBackground from "./../../../assets/img/backgrounds/register-step-3.jpg";
import Parent from "./Parent";
import StepContainer from "./stepContainer";

const Schooling = () => {
  const {
    register,
    control,
    formState: { errors },
    watch,
    setValue,
  } = useFormContext();

  const { levelSelect, isParentNeeded, getRequiredDocuments } =
    useEntraceGradeList();
  const { getListByGradeId } = useEntranceCursusList();
  const { sessionListSelect } = useExamSessionList();

  const gradeId = watch("entranceGrade", -1);
  const parentNeeded = isParentNeeded(gradeId);
  const documents = getRequiredDocuments(gradeId);

  return (
    <StepContainer
      image={stepBackground}
      title="Etape 2 sur 2"
      desciption="Veuillez remplir vos informations scolaires. Ces informations nous permettront d'évaluer votre dossier et vous orienter vers le bon cursus."
    >
      <div className="px-5">
        <div className="mb-5 mt-5">
          <h6 className="h3 mb-1">Inscription Concours ISCAM</h6>
          <p className="text-muted mb-0">Scolarité</p>
        </div>
        <div className="form-group">
          <label htmlFor="level">
            Entrée en <span>*</span>
          </label>
          <Controller
            control={control}
            name="entranceGrade"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Select
                options={levelSelect()}
                onChange={(value) => {
                  onChange(undefined);
                  setTimeout(() => {
                    onChange(value);
                    setValue("entranceCursus", "");
                  }, 2);
                }}
                value={value}
                placeholder=""
                hasError={!!errors.entranceGrade}
              />
            )}
          />
        </div>

        {-1 !== gradeId && (
          <div className="form-group">
            <label htmlFor="level">
              Parcours envisagé <span>*</span>
            </label>
            <Controller
              control={control}
              name="entranceCursus"
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <Select
                  options={getListByGradeId(gradeId)}
                  onChange={onChange}
                  value={value}
                  placeholder=""
                  hasError={!!errors.entranceCursus}
                />
              )}
            />
          </div>
        )}

        {parentNeeded && (
          <Controller
            name="parents"
            control={control}
            rules={{
              validate: (value) => {
                if (value && 0 !== value.length) return true;

                return "Veuillez ajouter au moin un parent";
              },
            }}
            render={({ field: { onChange, value } }) => (
              <Parent
                onChange={onChange}
                value={value}
                hasError={!!errors.parents}
                message={!!errors.parents && errors.parents.message}
              />
            )}
          />
        )}

        {documents && (
          <>
            <p>
              Liste de documents à fournir, scanner et à mettre dans un seul
              fichier suivant l’ordre dans la liste :
            </p>
            <ul>
              {documents.map((document) => (
                <li key={document.id}>{document.name}</li>
              ))}
            </ul>
            <p className="banktext">
              *RN : Relevé de notes ou Bulletin Versement d'espèces à la banque
              pour le droit d'inscription au concours :
              <ul style={{ listStyleType: "none" }} className="ml-1">
                <li>BFV N° 00008 00003 05003009300 78,</li>
                <li>BNI N° 00005 00001 01532130100 24,</li>
                <li>BOA N° 00009 05020 13734570009 69,</li>
                <li>Accès Banque N° 00011 00028 24100337861 51.</li>
              </ul>
            </p>
            <Controller
              name="documents"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Veuillez ajouter au moin un document",
                },
                validate: (value) =>
                  [
                    "image/jpeg",
                    "image/png",
                    "image/jpg",
                    "application/pdf",
                  ].includes(value.mimeType) ||
                  "Le fichier doit être une image ou un pdf",
              }}
              render={({ field: { onChange, value } }) => (
                <InputFile
                  label="Ajouter documents"
                  accept=".pdf, .png, .jpg, .jpeg"
                  onChange={onChange}
                  value={value}
                  hasError={!!errors.documents}
                  message={!!errors.documents && errors.documents.message}
                />
              )}
            />
          </>
        )}

        <div className="form-group">
          <label htmlFor="level">
            Période de concours souhaitée <span>*</span>
          </label>
          <Controller
            control={control}
            name="entranceExamSession"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Select
                options={sessionListSelect()}
                onChange={onChange}
                value={value}
                placeholder=""
                hasError={!!errors.entranceExamSession}
              />
            )}
          />
        </div>
      </div>
    </StepContainer>
  );
};

export default Schooling;
