import React, { createContext } from "react";
import { FormProvider } from "react-hook-form";
import {
  INSCRIPTION_STEP,
  SCHOOLING_STEP,
  SUCCESSFULL_STEP,
  useFormStep,
} from "./hooks/useFormStep";
import { useInscriptionFrom } from "./hooks/useInscriptionFrom";
import { useSubmitInscription } from "./hooks/useSubmitInscription";
import Identity from "./steps/Identity";
import Schooling from "./steps/Schooling";
import Success from "./steps/Success";

export const StepProvider = createContext(1);
export const EditingContext = createContext(false)

const Inscription = () => {
  
  const { goNext, goBack, canGoBack, currentStep, resetStep } = useFormStep();
  const { submiting, onSubmit, errors } = useSubmitInscription();
  const {methods, isEditing, currentEmail} = useInscriptionFrom()

  return (
    <FormProvider {...methods}>
      {errors && (
        <div className="alert alert-warning left-8 position-absolute top-1 zindex-100">
          <p>
            Une erreur est survenu! Merci de verifier la formulaire et re
            essayer
          </p>
        </div>
      )}
      <form
        onSubmit={methods.handleSubmit((data) =>
          onSubmit(data, currentStep, goNext)
        )}
        className="h-100"
      >
        <EditingContext.Provider value={{isEditing, currentEmail}} >
          <StepProvider.Provider value={{ currentStep, canGoBack, goBack, resetStep }}>
            {INSCRIPTION_STEP === currentStep && <Identity />}
            {SCHOOLING_STEP === currentStep && <Schooling />}
            {SUCCESSFULL_STEP === currentStep && <Success />}
          </StepProvider.Provider>
        </EditingContext.Provider>
        {SUCCESSFULL_STEP !== currentStep && (
          <div
            className="bottom-0 position-absolute px-6 right-0 w-50 pb-1 pt-4"
            style={{ backgroundColor: "white" }}
          >
            <button className="btn btn-block btn-primary" disabled={submiting}>
              {submiting && (
                <div
                  class="spinner-grow left-4 position-absolute spinner-grow top-2"
                  role="status"
                >
                  <span class="sr-only">Loading...</span>
                </div>
              )}
              <span>Valider</span>
            </button>
          </div>
        )}
      </form>
    </FormProvider>
  );
};

export default Inscription;
