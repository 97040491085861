import rindra from "../assets/img/interviews/rindra-square.jpg";
import ashley from "../assets/img/interviews/ashley-square.jpg";
import teddy from "../assets/img/interviews/teddy-square.jpg";
import lovatina from "../assets/img/interviews/lovatina-square.jpg";
import irina from "../assets/img/interviews/irina-square.jpg";
import dario from "../assets/img/interviews/dario-square.jpg";
import fanaja from "../assets/img/interviews/fanaja-square.jpg";
import nomasy from "../assets/img/interviews/nomasy-square.jpg";

import interviewee_rindra from "../assets/img/interviews/rindra-800x600.jpg";
import interviewee_ashley from "../assets/img/interviews/ashley-800x600.jpg";
import interviewee_teddy from "../assets/img/interviews/teddy-800x600.jpg";
import interviewee_lovatina from "../assets/img/interviews/lovatina-800x600.jpg";
import interviewee_irina from "../assets/img/interviews/irina-800x600.jpg";
import interviewee_dario from "../assets/img/interviews/dario-800x600.jpg";
import interviewee_fanaja from "../assets/img/interviews/fanaja-800x600.jpg";
import interviewee_nomasy from "../assets/img/interviews/nomasy-800x600.jpg";

export default {
  rindra,
  ashley,
  teddy,
  lovatina,
  irina,
  dario,
  fanaja,
  nomasy,
  interviewee_rindra,
  interviewee_ashley,
  interviewee_teddy,
  interviewee_lovatina,
  interviewee_irina,
  interviewee_dario,
  interviewee_fanaja,
  interviewee_nomasy,
};
