import React, { useEffect, useState } from "react";

const InputRadio = ({ name, options, value, onChange, hasError, label }) => {
  const [checkedValue, setCheckedValue] = useState(value);

  useEffect( () => { setCheckedValue(value) }, [value] )

  return (
    <div
      className="rounded-lg py-2 px-1"
      style={{ border: hasError ? "1px solid red" : "" }}
    >
      <div className="form-check-inline d-flex" style={{ gap: 40 }}>
        {label && <label className="mb-0">{label}</label>}
        {options.map((option, index) => (
          <div className="d-flex align-items-center">
            <input
              className="form-check-input"
              type="radio"
              name={name}
              id={`${name}-${index}`}
              value={option.value}
              checked={option.value === checkedValue}
              onChange={(e) => {
                onChange(parseInt(e.target.value));
                setCheckedValue(parseInt(e.target.value));
              }}
            />
            <label className="form-check-label" for={`${name}-${index}`}>
              {option.label}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};

export default InputRadio;
